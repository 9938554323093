<template>
  <div class="layout__wrap">
    <item-form
      class="account-detail"
      :item="true"
      :fields="fields"
      :save="save"
      :get="get"
      title="Your Profile"
      desc="You can update your profile here."
      :breadcrumb="false"
      :redirect="false"
      width="100"
      :gap="2"
      ref="item"
    >
      <template #default="{ form }">
        <Username v-model="form.username" />

        <s-field-validate rules="required" name="Name" label="Name">
          <TextBoxCapitalize placeholder="Enter Name" v-model="form.name" />
        </s-field-validate>

        <s-field-validate rules="required|email" name="Email" label="Email">
          <s-textbox v-model="form.email" placeholder="Enter Email" />
        </s-field-validate>
      </template>

      <template #footer="{ saving }">
        <div class="bi-shrink">
          <s-button label="Save Changes" color="secondary" type="submit" :loader="saving" />
        </div>
      </template>
    </item-form>

    <Separator />

    <UpdateMobileNumber />
    <Separator />

    <!-- Setting margin to solve issue of form getting hidden due to bottom panel which is fixed. -->
    <ChangePassword class="bi-mb-28" />
  </div>
</template>

<script>
import upsertUser from "@/graph/user/upsert.gql";
import getUser from "@/graph/user/get.gql";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      fields: ["username", "name", "email"],
    };
  },
  computed: {
    ...mapGetters({
      id: "user/id",
    }),
  },
  components: {
    TextBoxCapitalize: require("@/components/TextBoxCapitalize").default,
    ChangePassword: require("@/components/ChangePassword").default,
    Username: require("@/components/Username").default,
    UpdateMobileNumber: require("./UpdateMobileNumber.vue").default,
  },
  methods: {
    get() {
      return this.$graph(getUser).then((res) => {
        return res.me;
      });
    },
    save(id, data) {
      return this.$graph(upsertUser, { id: this.id, input: data });
    },
  },
};
</script>

<style lang="scss" scoped></style>
