<template>
  <div class="bi-mb-2">
    <div class="bi-flex bi-items-center bi-justify-between">
      <s-button v-if="backRoute" color="grey" theme="link" icon="IconIcOutlineArrowCircleLeft" class="bi-mr-2" :to="backRoute" />
      <component :is="tag" class="bi-text-primary-default">{{ title }}</component>
      <slot name="header-actions"></slot>
    </div>
    <div class="bi-text-grey-default">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    tag: {
      type: String,
      default: "h3",
    },
    backRoute: {
      type: Object,
    },
  },
};
</script>
