<template>
  <div class="bi-p-4">
    <s-button
      class="bi-mt-6 bi-font-semibold"
      size="lg"
      color="danger"
      theme="outline"
      label="Clear"
      icon="IconIcRoundClose"
      @click.native="$emit('resetFilters')"
    />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
