<template>
  <modal size="sm" title="Your KYC details are under review by BuilditIndia." id="kyc-details-saved-modal">
    <template #message>
      <p>You will be notified once it is approved. Meanwhile You can add/update project details.</p>
    </template>

    <template #footer-actions>
      <s-button label="Open Projects" color="secondary" @click.native="goToProjects" />
    </template>
  </modal>
</template>

<script>
export default {
  methods: {
    goToProjects() {
      this.$router.push({ name: "projects" });
      this.$root.$emit("shilp-modal-close", "kyc-details-saved-modal");
    },
  },
};
</script>

<style lang="scss" scoped></style>
