<template>
  <div>
    <ProjectDropDownWrapper />

    <div class="bg-vepaar-primary-lightest bi-p-4 bi-flex bi-justify-between bi-items-center bi-flex-col md:bi-flex-row">
      <p class="bi-text-grey-default bi-text-md md:bi-text-xl bi-mx-auto bi-mb-3 md:bi-mb-0">
        We will start online ordering soon for this category. Meanwhile, please click on <b>Request for price</b> and fill all necessary details. Our
        team member will contact you as soon possible to help you with offline order.
      </p>
      <s-button
        label="Request For Price"
        @click.native="
          $router.push({ name: 'inquiry-create', params: { category: categoryName, inquiryType: $options.inquiryType.requestForPrice } })
        "
        color="info"
        size="lg"
      />
    </div>

    <header class="content-header">
      <h3 class="content-title-blue">Brands</h3>
    </header>

    <!-- Logos -->
    <ul class="brands-wrap" v-if="brands && brands.length">
      <li
        class="brands"
        v-for="(item, index) in brands"
        :key="index"
        style="cursor: pointer"
        @click="
          $router.push({
            name: 'inquiry-create',
            params: { category: categoryName, brand: item.label, fieldsDisabled: false, inquiryType: $options.inquiryType.requestForPrice },
          })
        "
      >
        <div class="brands-container">
          <!-- <div class="bi-pb-2 bi-text-right" v-if="item.tag">
            <Tag :label="item.tag" />
          </div> -->
          <div class="logo-card">
            <img :src="item.url" alt="Brand" />
          </div>
        </div>

        <h4 class="bi-mt-3 bi-block bi-text-center bi-capitalize bi-font-bold bi-w-full">
          {{ item.label }}
        </h4>
      </li>
    </ul>
  </div>
</template>

<script>
import { inquiryType } from "@/data/index";

export default {
  inquiryType,
  brandOptions: {
    constructionChemicals: [
      {
        url: require("@/assets/img/fair-mate-chemicals.jpg"),
        name: "fair-mate-chemicals",
        label: "Fair Mate",
      },
      {
        url: require("@/assets/img/krifix-chemicals.jpg"),
        name: "krifix-chemicals",
        label: "KriFix",
      },
      {
        url: require("@/assets/img/mymix-chemicals.jpg"),
        name: "mymix-chemicals",
        label: "MyMix",
      },
      {
        url: require("@/assets/img/sumeru-chemicals.jpg"),
        name: "sumeru-chemicals",
        label: "Sumeru",
      },
      {
        url: require("@/assets/img/sika-chemicals.jpg"),
        name: "sika-chemicals",
        label: "Sika",
      },
      {
        url: require("@/assets/img/dfix-chemicals.jpg"),
        name: "dfix-chemicals",
        label: "D Fix",
      },
    ],
    rmc: [
      {
        url: require("@/assets/img/hindustan-rmc.jpg"),
        name: "hindustan-rmc",
        label: "Hindustan",
      },
      {
        url: require("@/assets/img/jk-lakshmi.jpg"),
        name: "jk-lakshmi-rmc",
        label: "JK Lakshmi",
      },
      {
        url: require("@/assets/img/nuvoco-rmc.png"),
        name: "nuvoco-rmc",
        label: "Nuvoco",
      },
      {
        url: require("@/assets/img/wagad-rmc.jpg"),
        name: "wagad-rmc",
        label: "Wagad",
      },
      {
        url: require("@/assets/img/robust-rmc.jpg"),
        name: "robust-rmc",
        label: "Robust",
      },
    ],
    kitchenSink: [
      {
        url: require("@/assets/img/franke-kitchen-sink.jpg"),
        name: "franke-kitchen-sink",
        label: "Franke",
      },

      {
        url: require("@/assets/img/carysil-kitchen-sink.jpg"),
        name: "carysil-kitchen-sink",
        label: "Carysil",
      },
    ],
    piping: [
      {
        url: require("@/assets/img/supreme-piping.jpg"),
        name: "supreme-piping",
        label: "Supreme",
      },
      {
        url: require("@/assets/img/wavin-piping.svg"),
        name: "wavin-piping",
        label: "Wavin",
      },
      {
        url: require("@/assets/img/astral-piping.png"),
        name: "astral-piping",
        label: "Astral",
      },
      {
        url: require("@/assets/img/ashirvad-piping.png"),
        name: "ashirvad-piping",
        label: "Ashirvad",
      },
    ],

    tiles: [
      {
        url: require("@/assets/img/agl-tiles.png"),
        name: "agl-tiles",
        label: "AGL",
      },
      {
        url: require("@/assets/img/simpolo-tiles.png"),
        name: "simpolo-tiles",
        label: "Simpolo",
      },
      {
        url: require("@/assets/img/varmora-tiles.png"),
        name: "varmora-tiles",
        label: "Varmora",
      },
      {
        url: require("@/assets/img/qutone-tiles.png"),
        name: "qutone-tiles",
        label: "Qutone",
      },
      {
        url: require("@/assets/img/nitco-tiles.png"),
        name: "nitco-tiles",
        label: "Nitco",
      },
      {
        url: require("@/assets/img/somany-tiles.png"),
        name: "somany-tiles",
        label: "Somany",
      },
    ],

    sanitary: [
      {
        url: require("@/assets/img/jaquar.png"),
        name: "jaquar-sanitary",
        label: "Jaquar",
      },
      {
        url: require("@/assets/img/roca-sanitary.png"),
        name: "roca-sanitary",
        label: "Roca",
      },
      {
        url: require("@/assets/img/cera-sanitary.png"),
        name: "cera-sanitary",
        label: "Cera",
      },
      {
        url: require("@/assets/img/tece-sanitary.png"),
        name: "tece-sanitary",
        label: "Tece",
      },
      {
        url: require("@/assets/img/aoqua-viva-sanitary.png"),
        name: "aoqua-viva-sanitary",
        label: "Aoquaviva",
      },
      {
        url: require("@/assets/img/astral-sanitary.png"),
        name: "astral-sanitary",
        label: "Astral",
      },
    ],
  },

  components: {
    ProjectDropDownWrapper: require("@/components/projectDropDown/Wrapper").default,
  },

  props: ["categoryName"],

  computed: {
    brands() {
      if (this.categoryName == "piping") {
        return this.$options.brandOptions.piping;
      } else if (this.categoryName == "construction-chemicals") {
        return this.$options.brandOptions.constructionChemicals;
      } else if (this.categoryName == "rmc") {
        return this.$options.brandOptions.rmc;
      } else if (this.categoryName == "kitchen-sink") {
        return this.$options.brandOptions.kitchenSink;
      } else if (this.categoryName == "piping") {
        return this.$options.brandOptions.kitchenSink;
      } else if (this.categoryName == "tiles") {
        return this.$options.brandOptions.tiles;
      } else if (this.categoryName == "sanitary") {
        return this.$options.brandOptions.sanitary;
      } else return [];
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
