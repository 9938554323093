<template>
  <div class="bi-hidden lg:bi-block bi-font-light bi-text-grey-lighter">Version: {{ $options.version }}</div>
</template>

<script>
import { version } from "../../package.json";

export default {
  version,
};
</script>

<style lang="scss" scoped></style>
