<template>
  <div class="bi-w-full bi-overflow-visible">
    <ProjectDropDownWrapper />
    <order-history v-if="isKycApproved()" />
    <brands :categoryId="categoryId" />
    <attributes :categoryId="categoryId" />
  </div>

  <!-- <div class="p-8">
      <s-button
        v-if="isKycApproved()"
        label="View All"
        color="secondary"
        size="lg"
        @click.native="
          $router.push({
            name: 'product-index',
            params: { categoryId: categoryId },
          })
        "
      />
    </div> -->
</template>

<script>
import kycMixin from "@/mixins/kycMixin";

export default {
  props: ["categoryId"],
  mixins: [kycMixin],

  components: {
    Brands: require("@/components/Brands").default,
    Attributes: require("@/components/Attributes").default,
    OrderHistory: require("@/components/OrderHistory").default,
    ProjectDropDownWrapper: require("@/components/projectDropDown/Wrapper")
      .default,
  },
};
</script>

<style lang="scss">
.layout--top .layout__content {
  grid-row-start: 2;
}
// <style lang="scss">
// // .layout--top .layout__content {
// //   grid-row-start: 1;
// // }
//
</style>
