<template>
  <div class="tag" :class="`tag--${name}`">
    <!-- <span class="tag__emoji">{{ emoji }}</span> -->
    <span class="tag__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    name: { type: String, default: null },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.tag {
  background: var(--light);
  color: var(--dark);
  @apply bi-h-5 bi-inline-flex bi-p-4 bi-items-center bi-rounded-full bi-align-top;
}
.tag__emoji {
  @apply bi-text-xs bi-leading-none bi-mr-1;
}

.tag__label {
  @apply bi-font-medium bi-text-sm bi-capitalize;
}

.tag--intransit {
  --light: #fef9c3;
  --dark: #713f12;
}

.tag--confirmed,
.tag--delivered,
.tag--shipped {
  --light: #effbda;
  --dark: #095004;
}

.tag--loaded {
  --light: #e8eaf6;
  --dark: #303f9f;
}

.tag--orange,
.tag--notconfirmed,
.tag--pending,
.tag--partial_shipped,
.tag--partial_delivered {
  --light: #fff3e0;
  --dark: #fb8c00;
}
.tag--amber {
  --light: #fff8e1;
  --dark: #ffa000;
}
.tag--lime {
  --light: #f9fbe7;
  --dark: #afb42b;
}
.tag--green {
  --light: #e8f5e9;
  --dark: #388e3c;
}
.tag--cyan {
  --light: #e0f7fa;
  --dark: #0097a7;
}
.tag--blue {
  --light: #e3f2fd;
  --dark: #1976d2;
}

.tag--indigo {
  --light: #e8eaf6;
  --dark: #303f9f;
}

.tag--purple {
  --light: #f3e5f5;
  --dark: #7b1fa2;
}

.tag--pink {
  --light: #fce4ec;
  --dark: #c2185b;
}

.tag--red,
.tag--onhold,
.tag--cancelled {
  --light: #ffebee;
  --dark: #d32f2f;
}

.tag--brown {
  --light: #efebe9;
  --dark: #6d4c41;
}
</style>
