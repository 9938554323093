<template>
  <modal size="md" title="Brand Description" id="brand-desc-modal">
    <template #default="{ data }">
      <div v-html="data.content"></div>
    </template>
  </modal>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
