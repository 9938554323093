<template>
  <div
    class="truck-wrapper bi-p-4 bi-border-t bi-border-grey-lightest-ultra bi-bg-grey-lightest-double-ultra md:bi-fixed md:bi-bottom-12 bi-left-0 bi-right-0 bi-h-25"
  >
    <div class="order-section bi-mt-0 bi-flex bi-flex-wrap md:bi-flex-nowrap bi-justify-end bi-gap-4 bi-items-center">
      <div class="bi-flex md:bi-justify-end md:bi-gap-32 bi-gap-2 bi-items-center">
        <!-- Total Quantity -->
        <div class="bi-text-right bi-border-r bi-border-grey-lighter bi-pr-2 sm:bi-pr-4">
          <span class="bi-block bi-pl-2.5 md:bi-text-2xl bi-text-xl bi-text-grey-darkest-ultra bi-font-display bi-font-bold bi-min-w-max">
            {{ totalQuantity }} {{ unitFromCart }}
          </span>
          <span class="bi-block bi-text-xs md:bi-text-sm bi-text-grey-darkest-ultra bi-font-display bi-font-normal"> Total Quantity</span>
        </div>
        <!-- Category -->
        <div class="bi-text-right bi-border-r bi-border-grey-lighter bi-pr-2 sm:bi-pr-4">
          <span class="bi-block bi-min-w-max md:bi-text-2xl bi-text-xl bi-text-grey-darkest-ultra bi-font-display bi-font-bold">
            {{ categoryNameInCart }}
          </span>
          <span class="bi-block bi-text-xs md:bi-text-sm bi-text-grey-darkest-ultra bi-font-display bi-font-normal"> {{ brandNameFromCart }}</span>
        </div>
        <!-- Total Price -->

        <CartTotalPrice />
      </div>
      <s-button label="Go To Truck" color="secondary" size="lg" class="bi-w-40" @click.native="goToTruck" />
    </div>
  </div>
</template>

<script>
import cartMixin from "@/mixins/cartMixin";
import { mapGetters } from "vuex";
export default {
  components: {
    CartTotalPrice: require("@/components/cart/CartTotalPrice").default,
  },
  mixins: [cartMixin],
  computed: {
    ...mapGetters(["categoryNameInCart"]),
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    goToTruck() {
      if (this.categoryNameInCart == "TMT Bar" || this.categoryNameInCart == "AAC Blocks") {
        const isGreater = this.isTotalQuantityGreaterThanMinimumOrderQuantity(this.categoryIdFromCart, this.totalQuantity);

        if (isGreater) {
          if (this.categoryNameInCart == "AAC Blocks") {
            if (this.isAacBlocksOrderQuantityInFixedSlot(this.totalQuantity)) {
              this.$router.push({ name: "truck" });
            } else {
              this.notifyAacBlocksOrderInMultipleSlotOnly();
              return;
            }
          } else this.$router.push({ name: "truck" });
        } else {
          if (this.categoryNameInCart == "TMT Bar") {
            this.notifyTmtBarMinimumOrderQuantity();
            return;
          }

          if (this.categoryNameInCart == "AAC Blocks") {
            this.notifyAacBlocksMinimumOrderQuantity();
            return;
          }
        }
      } else this.$router.push({ name: "truck" });
    },
  },
};
</script>

<style lang="scss">
.truck-wrapper {
  margin-left: $sub-layout-left-bar-width;
}
@media screen and (max-width: 768px) {
  .truck-wrapper {
    margin-left: 0;
    height: auto;
  }
}
</style>
