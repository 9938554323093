<template>
  <div class="bi-flex bi-justify-center lg:bi-mx-auto bi-mb-16">
    <ul class="progress bi-flex bi-grow bi-text-md bi-pb-4 bi-justify-around bi-relative">
      <li :class="{ active: isActive(orderStatusOptions.pending) }">
        <a>
          <span class="icon-box">
            <img src="@/assets/img/orderPlaced.svg" alt="order" />
          </span>
          <span style="flex: 0 0 100%; font-size: 16px"> Requested</span>
        </a>
      </li>

      <!-- Cancelled -->
      <li :class="[{ 'danger-active': isActive(orderStatusOptions.cancelled) }]" v-if="isActive(orderStatusOptions.cancelled)">
        <a>
          <span class="icon-box">
            <img src="@/assets/img/orderConfirmed.svg" />
          </span>
          <span style="flex: 0 0 100%; font-size: 16px"> Cancelled </span>
        </a>
      </li>

      <!-- Confirmed  -->
      <li :class="[{ active: isActive(orderStatusOptions.confirmed) }]" v-else>
        <a>
          <span class="icon-box">
            <img src="@/assets/img/orderConfirmed.svg" />
          </span>
          <span style="flex: 0 0 100%; font-size: 16px"> Confirmed </span>
        </a>
      </li>

      <!-- Partial Shipped -->
      <li
        v-if="isActive(orderStatusOptions.partial_shipped)"
        :class="{
          'warning-active': isActive(orderStatusOptions.partial_shipped),
        }"
      >
        <a>
          <span class="icon-box"> <img src="@/assets/img/orderInTransit.svg" /></span>
          <span style="flex: 0 0 100%; font-size: 16px">Partial Shipped</span>
        </a>
      </li>
      <li
        v-else
        :class="{
          active: isActive(orderStatusOptions.shipped),
        }"
      >
        <a>
          <span class="icon-box"> <img src="@/assets/img/orderInTransit.svg" /></span>
          <span style="flex: 0 0 100%; font-size: 16px">Shipped</span>
        </a>
      </li>

      <!-- partial Delivered -->
      <li :class="{ 'warning-active': isActive(orderStatusOptions.partial_delivered) }" v-if="isActive(orderStatusOptions.partial_delivered)">
        <a>
          <span class="icon-box"> <img src="@/assets/img/orderDelivered.svg" /></span>
          <span class="content-name" style="flex: 0 0 100%; font-size: 16px">Partial Delivered</span>
        </a>
      </li>

      <li :class="{ active: isActive(orderStatusOptions.delivered) }" v-else>
        <a>
          <span class="icon-box"> <img src="@/assets/img/orderDelivered.svg" /></span>
          <span class="content-name" style="flex: 0 0 100%; font-size: 16px">Delivered</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["status"],

  data() {
    return {
      orderStatusOptions: {
        pending: 1,
        confirmed: 4,
        cancelled: 6,
        onhold: 5,
        shipped: 2,
        delivered: 3,
        partial_shipped: 10,
        partial_delivered: 11,
      },
    };
  },
  computed: {
    activeOrderStatusProgress() {
      return {
        pending: [this.orderStatusOptions.pending],
        onhold: [this.orderStatusOptions.pending],
        confirmed: [this.orderStatusOptions.pending, this.orderStatusOptions.confirmed],
        cancelled: [this.orderStatusOptions.pending, this.orderStatusOptions.cancelled],
        shipped: [this.orderStatusOptions.pending, this.orderStatusOptions.confirmed, this.orderStatusOptions.shipped],
        delivered: [
          this.orderStatusOptions.pending,
          this.orderStatusOptions.confirmed,
          this.orderStatusOptions.shipped,
          this.orderStatusOptions.delivered,
        ],
        partial_delivered: [
          this.orderStatusOptions.pending,
          this.orderStatusOptions.confirmed,
          this.orderStatusOptions.shipped,
          this.orderStatusOptions.partial_delivered,
        ],
        partial_shipped: [this.orderStatusOptions.pending, this.orderStatusOptions.confirmed, this.orderStatusOptions.partial_shipped],
      };
    },

    activeOrderStatusProgressArray() {
      let result = null;
      switch (this.status.id) {
        case this.orderStatusOptions.pending:
          result = this.activeOrderStatusProgress.pending;
          break;
        case this.orderStatusOptions.onhold:
          result = this.activeOrderStatusProgress.onhold;
          break;
        case this.orderStatusOptions.confirmed:
          result = this.activeOrderStatusProgress.confirmed;
          break;
        case this.orderStatusOptions.cancelled:
          result = this.activeOrderStatusProgress.cancelled;
          break;
        case this.orderStatusOptions.shipped:
          result = this.activeOrderStatusProgress.shipped;
          break;
        case this.orderStatusOptions.delivered:
          result = this.activeOrderStatusProgress.delivered;
          break;
        case this.orderStatusOptions.partial_delivered:
          result = this.activeOrderStatusProgress.partial_delivered;
          break;
        case this.orderStatusOptions.partial_shipped:
          result = this.activeOrderStatusProgress.partial_shipped;
          break;
      }
      return result;
    },
  },
  methods: {
    isActive(status) {
      if (this.activeOrderStatusProgressArray.includes(status)) return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-box {
  content: "";
  border-radius: 50%;
  background: #b0bec5;
  width: 64px;
  height: 64px;
  display: block;
  margin: 6px auto;
  bottom: -30px;
  left: 0;
  right: 0;
}
.icon-box {
  img {
    @apply bi-mx-4 bi-my-2.5 bi-h-10 bi-w-8;
  }
}
.progress li.active {
  .icon-box {
    @apply bi-bg-success-default;
  }
}
.progress li.danger-active {
  .icon-box {
    @apply bi-bg-danger-default;
  }
}

.progress li.warning-active {
  .icon-box {
    @apply bi-bg-warning-default;
  }
}

.progress li::before {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  color: #fff;
  position: absolute;
  left: -200px;
  right: 0;
  top: 40px;
  bottom: auto;
  margin: 0 auto;
  z-index: 9;
}

.progress li.active::before {
  @apply bi-bg-success-default;
}

.progress li.danger-active::before {
  @apply bi-bg-danger-default;
}

.progress li.warning-active::before {
  @apply bi-bg-warning-default;
}

.progress {
  @apply bi-w-10/12 lg:bi-max-w-5xl;
  height: 95px;
}

.progress::after {
  content: "";
  display: block;
  width: 80%;
  height: 6px;
  color: #fff;
  background: #b0bec5;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: auto;
  margin: 0 auto;
}

.progress li.active:first-child::before {
  background: none;
  width: 0;
}

.progress li {
  flex: 1;
  position: relative;
  padding: 0px 10px;
  text-align: center;
}

.progress li a {
  display: flex;

  height: 100%;
  padding-bottom: 30px;
  margin-bottom: -35px;
  position: relative;
  z-index: 30;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0px;
  @apply sm:bi-text-xl;
}
</style>
