<template>
  <VueFileAgent
    ref="fileAgent"
    :key="fileAgentKey"
    :multiple="multiple"
    :deletable="isDeletable"
    :meta="true"
    :helpText="description"
    maxSize="5MB"
    :maxFiles="4"
    :value="modifiedValue"
    @beforedelete="onBeforeDelete($event)"
    @delete="onDelete($event)"
    :accept="accept"
    @select="filesSelected($event)"
    :linkable="linkable"
    :disabled="isDisabled"
    @upload="onUpload($event)"
    :thumbnailSize="thumbnailSize"
    :theme="theme"
    :readonly="readonly"
    v-shilp-loader.overlay="isUploading"
  ></VueFileAgent>
</template>

<script>
import $axios from "@/plugins/axios";
import { getToken } from "@/utils/adminOrder.js";
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "grid",
    },
    thumbnailSize: Number,
    fileAgentKey: {
      type: Number,
      default: 1,
    },
    isDeletable: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    linkable: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
    },
    value: {
      type: [Array, Object],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: ".png,.jpeg,.jpg,application/pdf",
    },
    description: {
      type: String,
      default: "Choose any image or pdf document.",
    },
    endpoint: {
      type: String,
    },
  },
  created() {
    if (this.value) {
      if (Array.isArray(this.value) && this.value.length) {
        // If there are multiple files, then array will be passed
        this.modifiedValue = this.value.map((item) => {
          return this.getMetaData(item);
        });
      } else if (typeof this.value == "object" && Object.keys(this.value).length) {
        // this else block is checking whether value contains object and is not empty , since empty object or array is getting processed further and causing errors.
        this.modifiedValue = this.getMetaData(this.value);
      }
    }
  },
  data() {
    return {
      modifiedValue: null,
      isUploading: false,
    };
  },
  computed: {
    token() {
      return getToken();
    },
    uploadHeaders() {
      return {
        Authorization: `Bearer ${this.token}`,
      };
    },
  },
  methods: {
    getMetaData(file) {
      if (file) {
        return {
          // GraphQL and REST both have different nameing conventions, therefore using logical OR
          // REFACTOR needed from backend to have same set of keys
          type: file.mimeType || file.mime_type,
          ext: file.extension,
          // Modified filename with extension to solve issue of File priview not displaying when file name has does not have any extension.
          name: this.getFileNameWithExtenstion(file.fileName || file.filename, file.extension),
          ...file,
        };
      }
    },
    getFileNameWithExtenstion(name, extension) {
      return name + "." + extension;
    },
    onBeforeDelete(fileRecord) {
      if (confirm("Are you sure?")) {
        this.$refs.fileAgent.deleteFileRecord(fileRecord);
      }
    },
    onDelete(fileRecord) {
      // fileRecord contains Object with Response in upload key and file data
      if (this.endpoint) {
        if (fileRecord.upload && fileRecord.upload.data && fileRecord.upload.data.length) var id = fileRecord.upload.data[0].id;
        else return;

        $axios.delete(`${process.env.VUE_APP_API}${this.endpoint}/${id}`);
      }

      // if (this.multiple) this.$emit("input", this.fileRecord);
      if (this.multiple) this.$emit("input", this.$refs.fileAgent.fileRecords);
      // Manually setting null for delete
      else this.$emit("input", null);
    },
    filesSelected(files) {
      // arg files contains array of file objects that are selected.
      // if (this.multiple) this.$emit("input", files);

      let updatedFiles = this.$refs.fileAgent.fileRecords;
      this.$emit("input", updatedFiles);
      // Checking whether file size validation is true

      if (this.endpoint) {
        this.isUploading = true;
        this.$emit("uploading", this.isUploading);
        this.$refs.fileAgent
          .upload(`${process.env.VUE_APP_API}${this.endpoint}`, this.uploadHeaders, files, this.createFormData)
          .then((res) => {})
          .finally(() => {
            this.isUploading = false;
            this.$emit("uploading", this.isUploading);
          });
      }
    },

    createFormData(data) {
      let formData = new FormData();
      // formData.append("used_for", "inquiry");
      if (this.params) {
        Object.entries(this.params).forEach((item) => {
          formData.append(item[0], item[1]);
        });
      }

      // Appending files array in formdata like this.
      formData.append("files[]", data.file);
      return formData;
    },
    onUpload(responses) {},
  },
};
</script>

<style lang="scss">
.is-disabled .vue-file-agent {
  pointer-events: all !important;
  opacity: 1 !important;
}
</style>
