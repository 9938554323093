import Vue from "vue";
import Vuex from "vuex";
import category from "./modules/category";
import hydrate from "./modules/hydrate";
import user from "./modules/user";
import cart from "./modules/cart";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["cart", "category"],
});

Vue.use(Vuex);

const storeInstance = new Vuex.Store({
  modules: {
    hydrate,
    user,
    cart,
    category,
  },
  plugins: [vuexLocal.plugin],
});

// Initial state
const initialState = JSON.parse(JSON.stringify(storeInstance.state));

//Resets the store when user logs out
const resetStore = () => {
  storeInstance.replaceState(JSON.parse(JSON.stringify(initialState)));
};

export default storeInstance;
export { resetStore };
