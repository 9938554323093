<template>
  <div>
    <ProjectDropDownWrapper />
    <!-- below margin bottom is used to solve issue of bottom template overlapping with content -->
    <sub-layout :contentClasslist="['bi-mb-24']">
      <template #menu>
        <CategorySidebarWrapper />
      </template>

      <template #mobile-menu>
        <CategorySidebarWrapper />
      </template>

      <template #default>
        <router-view />
      </template>

      <template #bottom v-if="totalNoOfProductsInCart">
        <GoToTruck v-if="cartProducts && cartProducts.length" />
      </template>
    </sub-layout>
  </div>
</template>

<script>
import cartMixin from "@/mixins/cartMixin";
export default {
  mixins: [cartMixin],
  props: ["categoryId"],
  components: {
    ProjectDropDownWrapper: require("@/components/projectDropDown/Wrapper").default,

    CategorySidebarWrapper: require("@/components/categorySidebar/Wrapper").default,

    GoToTruck: require("@/components/GoToTruck").default,
  },
};
</script>

<style></style>
