export const getAttributeValue = (attributeOptions, attributeToFind) => {
  if (!(attributeOptions && attributeOptions.length)) return "";

  for (let item of attributeOptions) {
    if (item.attribute.name == attributeToFind) return item.name;
  }

  // In case it does not find  which will rarely occur
  return "";
};

export const getGradeAttributeObject = (attributeOptions) => {
  for (let item of attributeOptions) {
    if (item.attribute.name == "Grade") return item;
  }
};

export const getAttributeId = (attributeOptions, attributeToFind) => {
  if (!(attributeOptions && attributeOptions.length)) return "";

  for (let item of attributeOptions) {
    if (item.attribute.name == attributeToFind) return item.id;
  }

  // In case it does not find  which will rarely occur
  return "";
};

export const isAttributePresent = (category, attributeToFind) => {
  // Initially category object will be empty ,therefore returning false,
  // after getting data from api, we can find.
  if (!category || !category.attributes) return false;
  for (let item of category.attributes) {
    if (item.name == attributeToFind) {
      return true;
    }
  }
  return false;
};

import { get } from "@/api/brands";

export const getBrands = (categoryId = null) => {
  return get({ limit: -1, filter: { category_id: categoryId } }).then((res) => {
    return res.data;
  });
};

export const getToken = () => {
  const token = sessionStorage.getItem("bi-token") || localStorage.getItem("bi-token");
  return token ? token : null;
};

export const setToken = (token) => {
  localStorage.setItem("bi-token", token);
};

export const alertApiError = (error, instance) => {
  const message = error.data?.message;
  instance.$shilp.notify({
    title: message ? message : "Something went wrong.",
    type: "danger",
  });
};

import moment from "moment";
export const formatDate = (value) => {
  if (value) {
    // Time is in seconds from Backend. so We have to convert in into milliseconds.
    const date = new Date(value * 1000);

    return moment(date).format("MMM D YYYY, h:mm A");
  }
};

export const formatDateForBackEnd = (value) => {
  // FIXME Update Date to DD-MM-YYYY
  return moment(value).format("DD-MM-Y");
};

// Formatting price
export const formatPrice = (price) => {
  return new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", currencyDisplay: "symbol", maximumFractionDigits: 2 }).format(price);
};

// Formatting price without decimal
export const formatPriceWithoutDecimal = (price) => {
  return new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", currencyDisplay: "symbol", maximumFractionDigits: 0 }).format(price);
};

export const allowedDocumentsToShowAndOptional = (companyType) => {
  if (companyType) {
    if (companyType == "Proprietorship") return ["pancard"];
    else if (["Partnership", "Limited Liability Partnership"].includes(companyType))
      return ["pancard", "partnershipDeed", "msmeCertificate", "authorityLetter"];
    else return ["partnershipMoa", "partnershipAoa", "msmeCertificate"];
  } else return [];
};

// REFACTOR Reuse below gst for accblocks calculatrion in other screen example AdditonalCharges info component
export const getAacBlocksGstPrice = (price, gstPercentage) => {
  return Number((price * (gstPercentage / 100)).toFixed(2));
};

export const getGstPrice = (price, gstPercentage) => {
  const result = Number(((price / (100 + gstPercentage)) * gstPercentage).toFixed(2));

  return result;
};

export const getBasePrice = (price, gstPercentage) => {
  const result = price - getGstPrice(price, gstPercentage);
  return result;
};

export const getAacBlocksGstForEachProduct = (price, gstPercentage) => {
  return Number((price + getAacBlocksGstPrice(price, gstPercentage)).toFixed(2));
};

export const getAacBlocksBasePrice = (price, gstPercentage) => {
  return Number((price - getAacBlocksGstPrice(price, gstPercentage)).toFixed(2));
};

export const getAacBlocksTotalPriceWithGst = (price, gstPercentage) => {
  return Number(price + getAacBlocksGstPrice(price, gstPercentage));
};

export const getGstPercentage = (attribute) => {
  let result = 0;
  if (attribute) {
    result = getGradeAttributeObject(attribute);
    if (result) return result.gst_percentage;
  } else return result;
};

export const isGstIncluded = (attribute) => {
  return getGradeAttributeObject(attribute)?.is_gst_included;
};

export const alertRouteChangeToBuyer = () => {
  const isBuyerReadyToChangeRoute = confirm("You are about to move from this Page. Please confirm.");

  return isBuyerReadyToChangeRoute;
};
