import $axios from "@/plugins/axios";

export const get = (params = null) => {
  return $axios.get("signInHistories", { params }).then((res) => {
    return res.data;
  });
};

export const remove = (id) => {
  return $axios.delete(`signInHistories/${id}`).then((res) => {
    return res.data;
  });
};
