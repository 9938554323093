import Vue from "vue";

/**
 * Vee Validate
 */
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min, max, is, max_value, min_value, length } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en";

const rules = { required, email, min, max, is, max_value, min_value, length };

for (let rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
    message: en.messages[rule], // add its message
  });
}

extend("required", {
  ...required,
  message: "* Required",
});
extend("gst", {
  validate: (value) => {
    const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/g;
    return value.match(new RegExp(regex));
  },
  message: "GSTIN Number Invalid",
});

extend("email", {
  validate: (value) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g;
    return value.match(new RegExp(regex));
  },
  message: "Invalid Email. Please enter appropriate email address.",
});

extend("url", {
  validate: (value) => {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
    return value.match(new RegExp(regex));
  },
  message: "Invalid Link. Please enter appropriate Link.",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

extend("positiveNonZeroInteger", {
  validate: (value) => {
    if (value <= 0) {
      return "Value must be positive";
    }
    if (value % 1 !== 0 || (value.includes && value.includes("."))) {
      return "Value shouldn't be decimal";
    }
    return true;
  },
});

extend("positiveInteger", {
  validate: (value) => {
    if (value < 0) {
      return "Value must be positive";
    }
    if (value % 1 !== 0 || (value.includes && value.includes("."))) {
      return "Value shouldn't be decimal";
    }
    return true;
  },
});

extend("uppercase", {
  params: ["target"],
  validate(value, { target }) {
    return value === value.toUpperCase();
  },
  message: "Only capital letters are allowed",
});

extend("latitude", {
  params: ["target"],
  validate(value) {
    return isFinite(value) && Math.abs(value) <= 90 && Math.abs(value) >= -90;
  },
  message: "Latitude must be between -90 to 90 only.",
});

extend("pinCode", {
  validate(value) {
    const regex = /^[1-9][0-9]{5}$/;
    return value.match(new RegExp(regex));
  },
  message: "Pin Code Invalid. Exactly 6 Digits",
});

extend("mobileNo", {
  validate(value) {
    const regex = /^[1-9][0-9]{9}$/;
    return value.match(new RegExp(regex));
  },
  message: "Mobile No Invalid.",
});

extend("longitude", {
  params: ["target"],
  validate(value) {
    return isFinite(value) && Math.abs(value) <= 180 && Math.abs(value) >= -180;
  },
  message: "Longitude must be between -180 to 180 only.",
});

extend("domain", {
  validate: (value) => {
    if (
      value.startsWith("http:") ||
      value.startsWith("https:") ||
      // value.startsWith("www.") ||
      value.startsWith(".")
    ) {
      return "Domain should not start with 'http', 'www' or '.'";
    }

    if (value.includes("/")) {
      return "Domain should not contain any '/'";
    }

    if (value.endsWith(".")) {
      return "Domain should not end with '.'";
    }

    if (value.indexOf(" ") >= 0) {
      return "Domain should not contain any space.";
    }

    if (value.indexOf(".") < 0) {
      return "Domain must contain a single '.'";
    }

    return true;
  },
});

extend("positiveNonZeroFloat", {
  validate: (value) => {
    if (parseFloat(value) <= 0) {
      return "Value must be positive";
    }
    return true;
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
