<template>
  <div class="grid-layout">
    <!-- Additional Instructions Card -->

    <div>
      <Card
        :dateTime="order.created_at"
        :expectedDeliveryDate="order.expected_delivery_date"
        :paymentTerms="order.paymentTerms.description"
        :category="order.products[0].categories[0].name"
        :brand="order.products[0].brand"
        :paymentStatus="order.paymentStatus"
        :id="order.id"
        :order_no="order.order_no"
        class="bi-mb-8 bi-bg-white"
      />

      <table
        class="order-detail-table bi-w-full bi-shadow-lg bi-rounded-lg bi-overflow-auto bi-bg-white bi-text-grey-darkest-ultra bi-text-base bi-font-normal"
      >
        <thead>
          <th>Grade</th>
          <th>Size/Packing</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Total</th>
          <th>Status</th>
          <th>Additional Details</th>
        </thead>
        <!-- TODO Ask champak bhai to give unit directly in object instead of getting unit inside products object -->
        <tr v-for="(product, index) in order.orderProducts" :key="`product-item-container-${index}`">
          <td>{{ product.products.attributeOptions[0].name }}</td>
          <td>{{ product.products.attributeOptions[1].name }}</td>
          <td>{{ product.dispatch_quantity + " " + product.products.unit }}</td>
          <td class="bi-flex bi-gap-2 bi-min-w-max">
            <product-price :price="product.price" />
            <v-popover trigger="hover" popoverWrapperClass="bi-w-100">
              <template #popover>
                <PriceBreakUp :gstPercentage="product.gst_percentage" :gstAmount="product.gst_amount" :basePrice="product.single_base_price" />
              </template>
              <!-- <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" /> -->
              <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
            </v-popover>
          </td>
          <td>
            <div class="bi-flex bi-gap-2">
              <TotalPrice :price="product.total" />
              <v-popover trigger="hover" popoverWrapperClass="bi-w-100">
                <template #popover>
                  <PriceBreakUp
                    :gstPercentage="product.gst_percentage"
                    :gstAmount="product.gst_total_amount"
                    :basePrice="product.total - product.gst_total_amount"
                  />
                </template>
                <!-- <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" /> -->
                <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
              </v-popover>
            </div>
          </td>

          <td class="bi-flex bi-items-center">
            <OrderStatusTag
              :name="product.orderProductStatus.name"
              :label="product.orderProductStatus.display_name"
              v-if="product.orderProductStatus"
            /><span v-if="order.is_auto_confirm" class="bi-text-grey-default bi-ml-1">(Auto)</span>
          </td>

          <td>
            <v-popover trigger="hover" v-if="product.additional_detail">
              <template #popover>
                <p>{{ product.additional_detail }}</p>
              </template>
              <s-button icon="IconMdiInformationOutline" color="secondary" shape="circle" theme="link" />
            </v-popover>
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <h4 class="bi-text-grey-default bi-font-normal">Base Price</h4>
          </td>
          <td>
            <h4 class="bi-font-bold">{{ formatPrice(order.total - order.gst_total_amount) }}</h4>
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <h4 class="bi-text-grey-default bi-font-normal">GST</h4>
          </td>
          <td>
            <h4 class="bi-font-bold">{{ formatPrice(order.gst_total_amount) }}</h4>
          </td>
        </tr>

        <tr v-if="order.loading">
          <td colspan="4">
            <h4 class="bi-text-grey-default bi-font-normal">Loading</h4>
          </td>
          <td>
            <h4 class="bi-font-bold">{{ formatPrice(order.loading) }}</h4>
          </td>
        </tr>

        <tr v-if="order.unloading">
          <td colspan="4">
            <h4 class="bi-text-grey-default bi-font-normal">Unloading</h4>
          </td>
          <td>
            <h4 class="bi-font-bold">{{ formatPrice(order.unloading) }}</h4>
          </td>
        </tr>

        <tr v-if="order.freight">
          <td colspan="4">
            <h4 class="bi-text-grey-default bi-font-normal">Freight</h4>
          </td>
          <td>
            <h4 class="bi-font-bold">{{ formatPrice(order.freight) }}</h4>
          </td>
        </tr>

        <tr>
          <td colspan="2">
            <h4 class="bi-text-grey-default bi-font-normal">Total</h4>
          </td>
          <td colspan="2">
            <span class="bi-font-bold">
              {{ order.total_quantity }}
            </span>
          </td>
          <td>
            <h4 class="bi-font-bold">{{ formatPrice(order.total) }}</h4>
          </td>
        </tr>

        <tr v-if="order.discount_amount">
          <td colspan="4">
            <h4 class="bi-text-grey-default bi-font-normal">Discount</h4>
          </td>

          <td>
            <h4 class="bi-font-bold bi-text-success-default">- {{ formatPrice(order.discount_amount) }}</h4>
          </td>

          <td></td>
        </tr>
      </table>

      <!-- <div class="bi-mb-16" > -->
      <!-- <CustomProductListHeading class="product-list-grid" /> -->
      <!-- <div v-for="(product, index) in order.products" :key="`product-item-container-${index}`"> -->
      <!-- TODO Improvment Needed from Backend.Ask backend to provide proper order and payment status in particular product array instead of outside products array -->
      <!-- <product-card :product="product" class="border-b border-grey-lightest-ultra bi-justify-center product-list-grid" /> -->
      <!-- </div> -->
      <!-- <div class="bi-flex bi-justify-end bi-bg-grey-lightest-double-ultra">
            <TotalCard :total="order.total" :gst="order.gst_total_amount" :basePrice="order.total - order.gst_total_amount" class="bi-p-5" />
          </div> -->

      <!-- </div> -->
    </div>

    <div>
      <DeliveryAddressCard
        class="detail-card"
        :deliveryIncharge="order.project.deliveryIncharge"
        :address="order.project ? order.project.deliveryAddress : []"
        :project-name="order.project ? order.project.name : ''"
        style="margin-top: 0"
      />
      <AdditionalInstructionsCard v-if="order.additional_instruction" :instructions="order.additional_instruction" class="detail-card" />

      <InvoiceItems :value="order.invoices" v-if="order.invoices && order.invoices.length" class="bi-p-5 bi-bg-white" />
    </div>
  </div>
</template>

<script>
import { formatDate, formatPrice } from "@/utils/common";
import moment from "moment";
export default {
  components: {
    AdditionalInstructionsCard: require("@/components/order/details/AdditionalInstructionsCard").default,
    DeliveryAddressCard: require("@/components/order/details/DeliveryAddressCard").default,
    // TotalCard: require("@/components/order/details/TotalCard").default,
    Card: require("@/components/order/details/Card").default,
    // ProductCard: require("@/components/ProductCard").default,
    // CustomProductListHeading: require("@/components/order/CustomProductListHeading").default,
    ProductPrice: require("@/components/ProductPrice").default,
    TotalPrice: require("@/components/TotalPrice").default,
    // AacBlocksPrice: require("@/components/AacBlocksPrice").default,
    PriceBreakUp: require("@/components/products/PriceBreakUp").default,
    OrderStatusTag: require("@/components/order/OrderStatusTag").default,
    InvoiceItems: require("@/components/order/details/InvoiceItems").default,
  },
  props: ["order"],
  methods: {
    formatDate,
    moment,
    formatPrice,
  },
};
</script>

<style lang="scss" scoped>
.order-detail-table {
  thead {
    th {
      text-align: left;
    }
  }
  tr,
  thead {
    td,
    th {
      padding: 12px;
    }
  }
}
.detail-card {
  @apply bi-my-8 bi-p-5 bi-bg-white;
}

.grid-layout {
  @apply bi-grid bi-gap-4 lg:bi-gap-8;
  grid-template-columns: 1fr 400px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
</style>
