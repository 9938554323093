export default [
  {
    path: "products",
    name: "product-index",
    redirect: { name: "product-list-by-attribute" },
    component: require("@/views/product/Index").default,
    props: true,
    meta: {
      label: "Products",
      breadcrumbs: ["product-index"],
    },
    children: [
      {
        path: ":categoryId/:attributeId?",
        name: "product-list-by-attribute",
        component: require("@/views/product/AttributeWiseList").default,
        props: true,
        meta: {
          label: "Products",
          icon: "IconMdiPackageVariantClosed",
          breadcrumbs: ["home", "product-list-by-attribute"],
        },
      },
      {
        path: ":categoryId/brands/:id/:attributeId?",
        name: "product-list-by-brand",
        component: require("@/views/product/brandWise/BrandWiseList").default,
        props: true,
        meta: {
          label: "Products",
          icon: "IconMdiPackageVariantClosed",
          breadcrumbs: ["home", "product-list-by-brand"],
        },
      },
    ],
  },
];
