<template>
  <div class="md:bi-hidden bi-flex">
    <s-button icon="IconMdiMenu" size="xl" color="light" theme="link" @click.native="toggle = !toggle" />
    <div
      :class="['md:bi-hidden', { 'bi-hidden': toggle }]"
      class="bi-fixed bi-top-20 bi-left-0 bi-bottom-0 bi-bg-primary-default bi-z-10 bi-p-6"
      style="width: 270px"
    >
      <s-button-group :key="$route.path" align="left" class="header-items">
        <s-button fluid label="Home" icon="IconMdiHomeOutline" :to="{ name: 'home' }" @click.native="toggle = !toggle" />
        <s-button fluid label="Project List" icon="IconMdiFormatListBulleted" :to="{ name: 'projects' }" @click.native="toggle = !toggle" />

        <s-button fluid label="Orders" icon="IconMdiBasketCheckOutline" :to="{ name: 'orders' }" @click.native="toggle = !toggle" />

        <!-- Invoices Section  -->
        <s-button fluid label="Invoices" icon="IconIcRoundReceiptLong" :to="{ name: 'invoice' }" @click.native="toggle = !toggle" />

        <!-- Ledger Section  -->
        <!-- <s-button fluid label="Ledgers" icon="IconIcRoundMenuBook" /> -->

        <!-- S Button  -->
        <s-button
          fluid
          label="Inquiry"
          icon="IconMaterialSymbolsContactPhoneOutline"
          :to="{ name: 'inquiry-section' }"
          @click.native="toggle = !toggle"
        />
        <s-button fluid label="Account" icon="IconMdiAccountCircleOutline" :to="{ name: 'company-detail' }" @click.native="toggle = !toggle" />
        <s-button
          fluid
          label="Logout"
          icon="IconIcSharpLogOut"
          @click.native="
            {
              toggle = !toggle;
              $shilp.modal.open('log-out-account-modal');
            }
          "
        />
      </s-button-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: true,
    };
  },
};
</script>

<style scoped>
.mobile-menu {
  color: #fff;
  font-size: 28px;
}
</style>
