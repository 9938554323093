<template>
  <div class="bi-rounded-md bi-grid bi-text-grey-default bi-items-center bi-gap-2" style="grid-template-columns: auto 30px auto">
    <!-- Base Price  -->
    <h4 class="bi-text-grey-default bi-font-normal">Base Price</h4>
    <span>:</span>
    <h4 class="bi-font-bold">{{ formatPrice(basePrice) }}</h4>

    <!-- GST Section -->

    <h4 class="bi-text-grey-default bi-font-normal">
      GST <span v-if="gstPercentage">({{ gstPercentage }}%)</span>
    </h4>
    <span>:</span>
    <h4 class="bi-font-bold">{{ formatPrice(gstAmount) }}</h4>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/common";
export default {
  props: ["gstAmount", "basePrice", "gstPercentage"],
  methods: {
    formatPrice,
  },
};
</script>

<style lang="scss" scoped></style>
