<template>
  <!-- Hard coded style is given to solve UI issue of showing component at center of screen instead of sticking to top -->
  <div class="bi-p-4 bi-flex bi-flex-col bi-flex--center bi-justify-center bi-items-center bi-flex--fit bi-flex--middle bi-w-full bi-h-full bi-flex--nowrap">
    <s-icon name="IconNoDataFound" class="bi-text-white" />

    <div class="bi-block bi-text-lg bi-mt-3 bi-font-semibold">{{ title }}</div>
    <p class="bi-text-grey-light">{{ content }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "No Data Found",
    },
    content: {
      type: String,
      default: "Try changing filters",
    },
  },
};
</script>

<style lang="scss"></style>
