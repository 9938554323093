import axios from "axios";
import qs from "qs";
import router from "@/router";
// import { cacheAdapterEnhancer } from "axios-extensions";
import store from "../store";
import { getToken } from "@/utils/adminOrder";
import { resetAuthStorage } from "@/utils/resetStorage.js";
// DEFAULT CONFIG

let config = {
  // Axios Caching Network requests
  baseURL: process.env.VUE_APP_API,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
  paramsSerializer: (params) => qs.stringify(params),
};

// Enabling Axios caching service in Development only because It speeds up the development time. No need to enable it in Production.
// if (process.env.NODE_ENV == "development") {
//   config.headers["Cache-Control"] = "no-cache";
//   config.adapter = cacheAdapterEnhancer(axios.defaults.adapter);
// }

// INSTANCE
const $axios = axios.create(config);

// SET TOKEN
// Allows to set the token of axios instance after login
const setToken = (token) => {
  $axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const setParams = (params) => {
  $axios.defaults.params = params;
};

// TOKEN
// Add token to header if found in localStorage

const token = getToken();
if (token) setToken(token);

// INTERCEPTORS
$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      resetAuthStorage();
      store.commit("user/resetState");
      router.replace({ name: "login" });
    }

    if (error.response && error.response.status === 429) {
      window.alert("Network is Slow. Please try again in some time.");
    }
    //If status is not provided, axios is not sure what happend!
    if (!error.response && !error.status) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default $axios;
export { setToken, setParams };
