<template>
  <div class="layout__wrap">
    <item-form title="Company Details" desc="You can see your company details here." :footer="false" class="account-detail">
      <template #header-actions>
        <s-button v-if="isKycApproved() && !getAdminId()" label="Edit Details" color="secondary" @click.native="showOtpModal('complete-kyc')" />
      </template>
      <template>
        <!-- GST NO  -->
        <div class="bi-space-y-6 lg:bi-space-y-0 lg:bi-grid lg:bi-grid-cols-4 lg:bi-gap-4 md:bi-space-y-0 md:bi-grid md:bi-grid-cols-2 md:bi-gap-4">
          <s-field label="GSTIN Number" name="GSTIN Number">
            <!-- <s-textbox :disabled="true" :value="company.gstNo" /> -->
            <div class="bi-text-grey-default">{{ company.gstNo }}</div>
          </s-field>

          <!-- Pan No -->

          <s-field-validate label="PAN NO" name="PAN NO">
            <!-- <s-textbox :disabled="true" :value="company.panNo" /> -->
            <div class="bi-text-grey-default">{{ company.panNo }}</div>
          </s-field-validate>

          <!-- Company Name -->

          <s-field-validate label="COMPANY NAME" name="COMPANY NAME">
            <!-- <s-textbox :disabled="true" :value="company.name" /> -->
            <div class="bi-text-grey-default">{{ company.name }}</div>
          </s-field-validate>

          <!-- Company TYPE  -->
          <s-field-validate label="COMPANY TYPE" name="COMPANY TYPE">
            <!-- <s-textbox :disabled="true" :value="companyTypeLabel" /> -->
            <div class="bi-text-grey-default">{{ companyTypeLabel }}</div>
          </s-field-validate>

          <!-- Mobile No -->

          <s-field-validate label="MOBILE NO" name="MOBILE NO">
            <!-- <s-textbox :disabled="true" :value="company.mobileNo" /> -->
            <div class="bi-text-grey-default">{{ company.mobileNo }}</div>
          </s-field-validate>
        </div>

        <div class="bi-flex bi-flex-wrap bi-gap-12">
          <!-- GST  -->
          <s-field-validate label="GST Certificate" name="GST Certificate" v-if="company.documents.gstCertificate">
            <VueFileAgentWrapper :linkable="true" :value="company.documents.gstCertificate" :readonly="true" :isDeletable="false" />
          </s-field-validate>

          <!-- PAN Card -->
          <s-field-validate
            label="PAN Card"
            name="PAN Card"
            v-if="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('pancard') && company.documents.pancard"
          >
            <VueFileAgentWrapper :linkable="true" :value="company.documents.pancard" :readonly="true" :isDeletable="false" />
          </s-field-validate>

          <!-- MSME Certificate -->
          <s-field-validate
            label="MSME CERTIFICATE"
            name="MSME CERTIFICATE"
            v-if="
              allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('msmeCertificate') &&
              company.documents.msmeCertificate
            "
          >
            <VueFileAgentWrapper :linkable="true" :value="company.documents.msmeCertificate" :readonly="true" :isDeletable="false" />
          </s-field-validate>

          <!-- Cancelled Cheque -->
          <s-field-validate
            label="CANCELLED CHEQUE"
            name="CANCELLED CHEQUE"
            v-if="
              allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('cancelledCheque') &&
              company.documents.cancelledCheque
            "
          >
            <VueFileAgentWrapper :linkable="true" :value="company.documents.cancelledCheque" :readonly="true" :isDeletable="false" />
          </s-field-validate>

          <!-- Authority letter -->
          <s-field-validate
            label="AUTHORITY LETTER"
            name="AUTHORITY LETTER"
            v-if="
              allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('authorityLetter') &&
              company.documents.authorityLetter
            "
          >
            <VueFileAgentWrapper :linkable="true" :value="company.documents.authorityLetter" :readonly="true" :isDeletable="false" />
          </s-field-validate>

          <!-- AOA  -->
          <s-field-validate
            label="Company AOA"
            name="Company AOA"
            v-if="
              allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipAoa') &&
              company.documents.partnershipMoa.partnershipAoa
            "
          >
            <VueFileAgentWrapper :linkable="true" :value="company.documents.partnershipMoa.partnershipAoa" :readonly="true" :isDeletable="false" />
          </s-field-validate>

          <!-- Partnership DEED  -->
          <s-field-validate
            label="PARTNERSHIP DEED"
            name="PARTNERSHIP DEED"
            v-if="
              allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipDeed') &&
              company.documents.partnershipMoa.partnershipDeed
            "
          >
            <VueFileAgentWrapper :linkable="true" :value="company.documents.partnershipMoa.partnershipDeed" :readonly="true" :isDeletable="false" />
          </s-field-validate>

          <!-- MOA -->
          <s-field-validate
            label="Company MOA"
            name="Company MOA"
            v-if="
              allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipMoa') &&
              company.documents.partnershipMoa.partnershipMoa
            "
          >
            <VueFileAgentWrapper :linkable="true" :value="company.documents.partnershipMoa.partnershipMoa" :readonly="true" :isDeletable="false" />
          </s-field-validate>
        </div>
      </template>
    </item-form>

    <!-- Partner List -->
    <template v-if="company.partners && company.partners.length">
      <hr class="bi-mt-10 bi-mb-6 bi-border-grey-default bi-border-1 bi-border-dotted" />
      <div class="bi-flex bi-justify-between">
        <div class="bi-text-primary-default bi-text-lg bi-font-semibold">{{ partnerOrOwnerLabel }} Details</div>
        <s-button
          v-if="isKycApproved() && !isCompanyProprietorship && company && company.id && !getAdminId()"
          :label="`Edit ${partnerOrOwnerLabel}`"
          color="secondary"
          @click.native="showOtpModal('upsert-partner')"
        />
      </div>
      <sp-list
        endpoint="partners"
        :params="params"
        :pagination="false"
        ref="partnersList"
        pagination-mode="infinite"
        :attrs="columns"
        :actions="['']"
      >
        <template #empty>
          <NoPartnerFound />
        </template>
        <div class="bi-overflow-x-scroll">
          <sp-list-table style="min-width: 450px">
            <template #name="{ item }">
              <span class="bi-text-md">{{ item.name }}</span>
            </template>
            <!-- <template #email="{ item }">
          <span class="bi-text-md" v-if="item.email">{{ item.email }}</span>
          <data-not-found />
        </template> -->
            <!-- <template #front="{ item }">
          <a
            :href="item.documents.aadhaarCardFront && item.documents.aadhaarCardFront.url"
            target="_blank"
            v-if="item.documents.aadhaarCardFront && item.documents.aadhaarCardFront.url"
          >
            <s-icon name="IconIcRoundPictureAsPdf" class="bi-w-8 bi-h-8 bi-text-grey-default" />
          </a>
        </template> -->
            <!-- <template #back="{ item }">
          <a
            :href="item.documents.aadhaarCardBack && item.documents.aadhaarCardBack.url"
            target="_blank"
            v-if="item.documents.aadhaarCardBack && item.documents.aadhaarCardBack.url"
          >
            <s-icon name="IconIcRoundPictureAsPdf" class="bi-w-8 bi-h-8 bi-text-grey-default" />
          </a>
        </template> -->
            <!-- <template #pancard="{ item }">
          <a :href="item.documents.panCard.url" target="_blank" v-if="item.documents.panCard">
            <s-icon name="IconIcRoundPictureAsPdf" class="bi-w-8 bi-h-8 bi-text-grey-default" />
          </a>
          <data-not-found v-else />
        </template> -->
            <template #mobileNo="{ item }">
              <span class="bi-text-md">{{ item.mobileNo }}</span>
            </template>

            <template #isDefault="{ item }">
              <span>{{ item.isDefault ? "Yes" : "No" }}</span>
            </template>

            <!-- v-model="form.isDefault" -->
          </sp-list-table>
        </div>
      </sp-list>
    </template>
    <CancelledChequeViewModal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import kycMixin from "@/mixins/kycMixin";
import { allowedDocumentsToShowAndOptional } from "@/utils/common";
import { getAdminId } from "@/utils/adminOrder";
export default {
  mixins: [kycMixin],
  data() {
    return {
      currentDocument: null,
    };
  },

  components: {
    CancelledChequeViewModal: require("@/components/CancelledChequeViewModal").default,
    NoPartnerFound: require("@/components/kyc/NoPartnerFound").default,
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
    // DataNotFound: require("@/components/DataNotFound").default,
  },

  computed: {
    ...mapGetters({
      company: "user/company",
      companyType: "user/companyType",
    }),

    columns() {
      return [
        // { name: "_index", label: "#", fix: true },
        {
          name: "name",
          label: "Name",
          fix: true,
        },
        // { name: "email", label: "Partner Email", fix: true },
        // { name: "front", label: "AadharCard Front", fix: true },
        // { name: "back", label: "AadharCard Back", fix: true },
        // {
        //   name: "pancard",
        //   label: "PanCard",
        //   fix: true,
        // },
        { name: "mobileNo", label: "MobileNo", fix: true },
        { name: "isDefault", label: "Main Partners", fix: true, visible: this.isCompanyProprietorship ? false : true },
      ];
    },

    companyTypeLabel() {
      return this.companyType.displayName;
    },

    partnerOrOwnerLabel() {
      if (this.companyTypeLabel == "Proprietorship") return "Owner";
      else return "Partner";
    },

    isCompanyProprietorship() {
      if (this.companyType.name == "Proprietorship") return true;
      else return false;
    },
    params() {
      let id = this.company.id;
      return {
        companyId: id,
      };
    },
  },

  methods: {
    getAdminId,
    allowedDocumentsToShowAndOptional,
    viewDocument() {
      this.$root.$emit("shilp-modal-open", "document-view-modal");
    },

    showOtpModal(route) {
      this.$root.$emit("shilp-modal-open", {
        id: "otp-modal",
        scope: {
          mobileNo: this.company.mobileNo,
          codeFor: "change_kyc",
          showSendToPartnerField: true,
          redirect: route,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.image-card {
  cursor: pointer;
  width: 50%;
  height: 80px;
  overflow: hidden;
  border-radius: 5px;
  // border: 1px dotted gray;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
    border-radius: 5px;
  }
}
.field-block:disabled {
  color: var(--color--grey);
  background-color: #fff;
}
</style>
