import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      cartProducts: "getCart",
      totalQuantity: "getTotalQuantity",
      totalNoOfProductsInCart: "totalNoOfProductsInCart",
      categoryIdFromCart: "getCategoryId",
      categoryIdFromHomeScreen: "categoryId",
      categoryNameFromHomeScreen: "categoryName",
      categoryNameInCart: "categoryNameInCart",
      brandIdFromCart: "brandIdFromCart",
      brandNameFromCart: "brandNameFromCart",
      unitFromCart: "getUnit",
      categoriesFromStore: "categories",
      brandLoadingOption: "getLoadingOption",
      brandUnloadingOption: "getUnLoadingOption",
      brandFreightOption: "getFreightOption",
      getGstPercentageFromCart: "getGstPercentage",
      getIsGstIncludedFromCart: "getIsGstIncluded",
      getExtraTimeCharges: "getExtraTimeCharges",
      getTotalCartAmount: "getTotalCartAmount",
    }),
    totalCartPrice() {
      // let total = 0;
      // if (this.cartProducts && this.cartProducts.length) {
      //   this.cartProducts.forEach((item) => {
      //     total = total + item.total;
      //   });

      //   total = total + this.getExtraTimeCharges * this.totalQuantity;

      //   return total;
      // } else return 0;

      let total = 0;
      if (this.cartProducts && this.cartProducts.length) {
        this.cartProducts.forEach((item) => {
          total = total + item.total;

          // Adding extra time charges if selected.
          // total = total + this.getExtraTimeCharges * item.quantity;
        });
      }

      return total;
    },

    totalGstPrice() {
      // const result = this.cartProducts.reduce((total, item) => {
      //   return total + item.gstAmount;
      // });

      let result = 0;
      if (this.cartProducts && this.cartProducts.length) {
        this.cartProducts.forEach((item) => {
          result = result + item.gstAmount;
        });
      }

      return result;
    },

    totalBasePrice() {
      // const result = this.cartProducts.reduce((total, item) => {
      //   return total + item.basePrice;
      // });

      let result = 0;

      if (this.cartProducts && this.cartProducts.length) {
        this.cartProducts.forEach((item) => {
          result = result + item.basePrice;
        });
      }

      return result;
    },
  },
  methods: {
    ...mapActions([
      "updateCart",
      "setTotalNoOfProductsInCart",
      "setCategoryIdInCart",
      "setCategoryNameInCart",
      "setTotalQuantity",
      "addToCart",
      "setBrandIdInCart",
      "setBrandNameInCart",
      "setUnitInCart",
      "setLoadingOption",
      "setUnloadingOption",
      "setFreightOption",
      "resetCart",
      "setGstPercentage",
      "setGstIncluded",
      "setExtraTimeCharges",
      "setTotalCartAmount",
    ]),

    checkIfCategoryIsPresentInCart() {
      if (!this.categoryIdFromCart) {
        this.setCategoryIdFromHomeScreen();
        return false;
      }
      return true;
    },

    comparePrice(currentProductListFromCart, updatedProductListFromApi) {
      let isPriceChanged = false;

      if (this.categoryNameInCart == "AAC Blocks") {
        currentProductListFromCart.forEach((current) => {
          updatedProductListFromApi.forEach((updated) => {
            if (
              current.product_id == updated.id &&
              current.price != updated.price + Number((updated.price * (current.gstPercentage / 100)).toFixed(2))
            )
              isPriceChanged = true;
          });
        });
      } else {
        currentProductListFromCart.forEach((current) => {
          updatedProductListFromApi.forEach((updated) => {
            if (current.product_id == updated.id && current.price != updated.price) isPriceChanged = true;
          });
        });
      }

      return isPriceChanged;
    },
    getUnit(product) {
      if (product.unit == "Bag") return "MT";
      else return product.unit;
    },

    getCategoryWiseProductUnit(categoryId) {
      switch (categoryId) {
        case 1:
          return "MT";
        case 2:
          return "MT";
        case 3:
          return "CBM";
      }
    },
    getTotalQuantity(products) {
      let totalQuantity = 0;
      products.forEach((item) => {
        if (products[0].categories[0].name == "Cement" && item.unit == "Bag") totalQuantity = totalQuantity + Math.floor((item.quantity * 50) / 1000);
        else totalQuantity = totalQuantity + item.quantity;
      });

      return Number(totalQuantity.toFixed(2));
    },

    setCategoryIdFromHomeScreen() {
      this.setCategoryIdInCart(this.categoryIdFromHomeScreen);
      this.setCategoryNameInCart(this.categoryNameFromHomeScreen);
    },
    clearTruckItems() {
      this.$shilp.confirm({
        title: "Clear Truck Items?",
        okLabel: "Yes",
        cancelLabel: "No",
        ok: ({ close }) => {
          this.emptyCart();
          this.$root.$emit("truck-got-empty");
          close();
        },
      });
    },

    alertDifferentOrderCategoryNotAllowed(id) {
      this.$root.$emit("shilp-modal-open", {
        id: "order-category-modal",
        scope: { id: id },
      });
    },

    checkIfBrandIsSameInCart(id) {
      const isBrandPresentInCart = this.checkIfBrandIsPresentInCart();

      // if (isBrandPresentInCart && id == this.brandIdFromCart) return true;
      // else return false;

      return isBrandPresentInCart && id == this.brandIdFromCart;
    },

    checkIfBrandIsPresentInCart() {
      if (!this.brandIdFromCart) {
        return false;
      }
      return true;
    },

    // checkIfGstIncludedInCategory(id) {
    //   const category = this.categoriesFromStore.find((item) => {
    //     return item.id == id;
    //   });

    //   if (category && category.is_gst_included) return true;
    //   else return false;
    // },

    checkIfCategoryIsSameInCart(id) {
      const isCategoryPresentInCart = this.checkIfCategoryIsPresentInCart();

      if (isCategoryPresentInCart) {
        if (id == this.categoryIdFromCart) return true;
        else return false;
      } else {
        return true;
      }
    },
    removeProductFromCart() {
      let product = this.findProductInCart(this.id);
      if (!product) return;
      let oldQuantity = product.quantity;

      this.removeProductCountFromTotalQuantityOfCart();

      let arr = this.cartProducts.filter((item) => {
        return item.product_id != product.product_id;
      });

      this.updateCart(arr);

      this.quantity = 0;

      this.setTotalQuantity(this.totalQuantity - oldQuantity);
    },

    convertBagsToMT(bags) {
      return (bags * 50) / 1000;
    },

    getTotalGstAmountFromCart() {},

    // getTotalGstAmountFromCart() {
    //   // return this.cartProducts.reduce(getSum, 0);

    //   // function getSum(total, item) {
    //   //   return total + item.totalGstAmount;
    //   // }

    //   const gstPercentage = this.getGstPercentageFromCart;
    //   if (this.categoryIdFromCart == 3) {
    //     return this.totalCartPrice * (gstPercentage / (100 + gstPercentage));
    //   }
    //   return (this.totalCartPrice / (100 + gstPercentage)) * gstPercentage;
    // },

    // getTotalBasePriceFromCart() {
    //   // result = this.cartProducts.reduce(getSum, 0);

    //   // function getSum(total, item) {
    //   //   return total + item.totalBasePrice;
    //   // }

    //   // if (this.getExtraTimeCharges) {
    //   //   result = result + this.getExtraTimeCharges;
    //   // }

    //   if (this.categoryIdFromCart == 3) {
    //     return (this.totalCartPrice * 100) / (100 + this.getGstPercentageFromCart);
    //   } else return this.totalCartPrice - this.getTotalGstAmountFromCart();
    // },

    removeProductCountFromTotalQuantityOfCart() {
      this.setTotalNoOfProductsInCart(this.totalNoOfProductsInCart - 1);
    },

    addProductCountInTotalQuantityOfCart() {
      this.setTotalNoOfProductsInCart(this.totalNoOfProductsInCart + 1);
    },

    isTotalQuantityGreaterThanMinimumOrderQuantity(categoryId, totalQuantity) {
      if (categoryId == 2) return totalQuantity < this.getMinimumOrderQuantity(categoryId) ? false : true;
      if (categoryId == 3) return totalQuantity < this.getMinimumOrderQuantity(categoryId) ? false : true;
    },

    getMinimumOrderQuantity(categoryId) {
      if (categoryId == 2) return 10;
      if (categoryId == 3) return 30;
    },

    isAacBlocksOrderQuantityInFixedSlot(quantity) {
      return quantity % 30 == 0 ? true : false;
    },

    notifyTmtBarMinimumOrderQuantity() {
      this.$shilp.notify({
        title: "Min. Order Quantity for TMT bars is 10 MT.",
        message: "If you have less than 10 MT order, Please contact us.",
        type: "danger",
      });
    },

    notifyAacBlocksOrderInMultipleSlotOnly() {
      this.$shilp.notify({
        title: "Total AAC Blocks Order Quantity must be in multiple of 30 CBM",
        type: "danger",
      });
    },

    notifyAacBlocksMinimumOrderQuantity() {
      this.$shilp.notify({
        title: "Min. Order Quantity for AAC Blocks is 30 CBM.",
        message: "If you have less than 30 CBM order, Please contact us.",
        type: "danger",
      });
    },

    emptyCart() {
      this.resetCart();
    },
    getProductQuantity(id) {
      let product = this.findProductInCart(id);
      if (!product) return 0;
      return product.quantity;
    },
    getTotalPriceOfCart() {
      return this.totalCartPrice;
    },
    findProductInCart(id) {
      const product = this.cartProducts.find((item) => {
        return item.product_id == id;
      });

      return product;
    },
    checkIfProductIsPresentInCart() {
      for (let item of this.cartProducts) {
        if (item.product_id == this.id) return true;
      }

      return false;
    },
  },
};
