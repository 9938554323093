<template>
  <div class="bi-text-left bi-text-xs bb-lightest">
    <h6 class="bi-text-grey-default bi-font-normal bi-my-2" v-for="heading in orderListHeading" :key="heading.id">{{ heading }}</h6>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderListHeading: [
        "Order ID",
        "Date & Time",
        "Brands",
        "Categories",
        "Order Status",
        "Payment Status",
        "Total Quantity",
        "Total Price",
        "Action",
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
