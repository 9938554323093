<template>
  <s-button-group align="center" :key="$route.path" class="header-items bi-hidden md:bi-flex bi-gap-3.5">
    <s-button label="Home" icon="IconMdiHomeOutline" :to="{ name: 'home' }" tabindex="1" />
    <s-button label="Project List" icon="IconMdiFormatListBulleted" :to="{ name: 'projects' }" tabindex="2" />

    <s-button label="Orders" icon="IconMdiBasketCheckOutline" :to="{ name: 'orders' }" tabindex="3" />
    <!-- Invoices Section  -->
    <s-button label="Invoices" icon="IconIcRoundReceiptLong" tabindex="4" :to="{ name: 'invoice' }" />

    <!-- Ledger Section  -->
    <!-- <s-button label="Ledgers" icon="IconIcRoundMenuBook" tabindex="5" /> -->
  </s-button-group>
</template>

<script>
// REFACTOR Make wrapper component and pass menu items from parent to thid child
export default {};
</script>

<style lang="scss" scoped></style>
