<template>
  <vue-countdown-timer
    :interval="1000"
    :day-txt="dayText"
    :end-time="endTime"
    :end-text="endText"
    :hour-txt="hourText"
    :end-label="endlabel"
    :start-time="startTime"
    :start-label="startLabel"
    :minutes-txt="minutesText"
    :seconds-txt="secondsText"
    :label-position="labelPosition"
    @end_callback="endCallBack('event ended')"
    @start_callback="startCallBack('event started')"
  >
  </vue-countdown-timer>
</template>

<script>
export default {
  name: "Timer",
  props: {
    startTime: {
      type: [Number, String],
      default: "",
    },
    endTime: {
      type: [Number, String],
      default: "",
    },
    startLabel: {
      type: String,
      default: "Until start:",
    },
    endlabel: {
      type: String,
      default: "Until end:",
    },
    labelPosition: {
      type: String,
      default: "begin", //end
    },
    endText: {
      type: String,
      default: "Timer ended!",
    },
    dayText: {
      type: String,
      default: "days",
    },
    hourText: {
      type: String,
      default: "hours",
    },
    minutesText: {
      type: String,
      default: "minutes",
    },
    secondsText: {
      type: String,
      default: "seconds",
    },
  },
  methods: {
    startCallBack: function (x) {
      this.$emit("start-timer");
    },
    endCallBack: function (x) {
      this.$emit("endTimer");
    },
  },
};
</script>
