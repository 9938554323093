import { SET_CATEGORY_ID, SET_CATEGORY_NAME, SET_CATEGORIES, RESET_STATE } from "../mutation-types";
const state = {
  // TODO Temporary setting default category to load on load to aac blocks. Hard coded id.
  categoryId: 3,
  categoryName: "AAC Blocks",
  categories: [],
};

const getters = {
  categoryId: (state) => {
    return state.categoryId;
  },
  categoryName: (state) => {
    return state.categoryName;
  },
  categories: (state) => {
    return state.categories;
  },
  getCategoryDetail: (state) => (id) => {
    const result = state.categories.find((item) => {
      return item.id == id;
    });
    if (result) return result;
    else return null;
  },

  getPaymentOptionsDetail: (state, getters) => (id) => {
    const result = getters.getCategoryDetail(id);

    return result ? result.paymentTerms : null;
  },
};

const mutations = {
  [SET_CATEGORY_ID](state, payload) {
    state.categoryId = payload;
  },
  [SET_CATEGORY_NAME](state, payload) {
    state.categoryName = payload;
  },
  [SET_CATEGORIES](state, payload) {
    state.categories = payload;
  },

  [RESET_STATE](state) {
    state = {
      categoryId: 1,
      categoryName: "Cement",
      categories: [],
    };
  },
};

const actions = {
  setCategoryId({ commit }, payload) {
    commit(SET_CATEGORY_ID, payload);
  },
  setCategoryName({ commit }, payload) {
    commit(SET_CATEGORY_NAME, payload);
  },
  setCategories({ commit }, payload) {
    commit(SET_CATEGORIES, payload);
  },
  resetState({ commit }, payload) {
    commit(RESET_STATE);
  },
};
export default {
  // namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
