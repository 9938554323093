<template>
  <div class="bi-h-full bi-flex bi-flex-col bi-justify-between">
    <!-- Desktop Sidebar -->
    <template>
      <DesktopSideBar
        class="bi-hidden md:bi-block"
        :categories="finalCategories"
        :selectedCategoryIdFromStore="categoryIdFromStore"
        @category-selected="setCategory"
      />
    </template>

    <!-- Mobile Sidebar -->
    <template>
      <MobileSideBar
        class="md:bi-hidden bi-block"
        :categories="finalCategories"
        :selectedCategoryIdFromStore="categoryIdFromStore"
        @category-selected="setCategory"
      />
    </template>
    <Version class="bi-pl-4 bi-left-0" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import { version } from "../../../package.json";
export default {
  extraCategories: [
    {
      name: "construction-chemicals",
      label: "Construction Chemicals",
      id: 4,
      static: true,
    },
    {
      name: "piping",
      label: "Piping",
      id: 5,
      static: true,
    },
    {
      name: "sanitary",
      label: "CP & Sanitary",
      id: 6,
      static: true,
    },
    {
      name: "tiles",
      label: "Tiles",
      id: 7,
      static: true,
    },
    {
      name: "rmc",
      label: "RMC",
      id: 8,
      static: true,
    },
    {
      name: "kitchen-sink",
      label: "Kitchen Sink",
      id: 9,
      static: true,
    },
  ],
  // version,
  components: {
    DesktopSideBar: require("./CategorySidebar.vue").default,
    MobileSideBar: require("./MobileSideBar.vue").default,
    Version: require("@/components/Version").default,
  },
  computed: {
    ...mapGetters({
      categoryIdFromStore: "categoryId",
      categoriesFromStore: "categories",
      buyerReadyToChangeRoute: "user/getBuyerReadyToChangeRoute",
      cartProducts: "getCart",
    }),

    finalCategories() {
      return [...this.categoriesFromStore, ...this.$options.extraCategories];
    },
  },
  methods: {
    ...mapActions(["setCategoryId", "setCategoryName"]),
    setCategory(id, name) {
      this.$router.push({ name: "home" }).catch(() => {});

      // Temporary fixed issue of category getting set after buyer selecting cancel option in other screen move prompt
      if (this.cartProducts && this.cartProducts.length && !this.buyerReadyToChangeRoute) return;
      else {
        this.$emit("category-selected", id);
        this.setCategoryId(id);
        this.setCategoryName(name);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
