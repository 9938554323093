<template>
  <div class="bi-rounded-lg bi-shadow-lg">
    <label class="bi-text-grey-default bi-text-base bi-font-normal">Delivery Address</label>

    <div class="bi-text-grey-darkest-ultra bi-font-medium">
      <b>{{ projectName }}</b>
      <p>{{ address }}</p>
    </div>

    <!-- Contact person name and number -->
    <template v-if="deliveryIncharge.length">
      <label class="bi-text-grey-default bi-text-base bi-font-normal bi-mt-4 bi-block">Contact Person</label>

      <div v-for="(inCharge, index) in deliveryIncharge" :key="index">
        <p class="contact-label">
          Name: <span class="contact-number">{{ inCharge.name }}</span>
        </p>
        <p class="contact-label">
          Mobile Number: <span class="contact-number">{{ inCharge.mobile_no }}</span>
        </p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["address", "deliveryIncharge", "projectName"],
};
</script>

<style lang="scss" scoped>
.contact-label {
  @apply bi-text-grey-default bi-font-normal;
}

.contact-number {
  @apply bi-font-bold bi-text-black;
}
</style>
