var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"layout__wrap"},[_c('sp-list',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreProducts),expression:"loadMoreProducts"}],ref:"list",staticClass:"clickable",attrs:{"perPage":10,"attrs":_vm.columns,"filters":_vm.filters,"infinite-scroll-distance":"10","pagination-mode":"infinite","endpoint":"rest:products","sortBy":"price","sortOrder":"asc","params":{
        sort_attribute_option_visible: true,
        include: 'brand,categories.attribute.attributeOption,attributeOptions.attribute',
      },"actions":[]},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('list-empty')]},proxy:true},{key:"filters",fn:function(){return [_c('div',{staticClass:"bi-h-full"},[_c('div',{staticClass:"bi-flex bi-justify-between bi-items-center bb-lightest bi-h-13 bi-pl-4"},[_c('span',{staticClass:"bi-text-base bi-text-grey-default bi-block"},[_vm._v("Filters")]),_c('s-button',{attrs:{"icon":"IconIcRoundClose","size":"lg","color":"gray","theme":"link"},nativeOn:{"click":function($event){return _vm.$refs.list.toggleSidebar('filters')}}})],1),_c('div',{staticClass:"bi-p-4"},[_c('h5',{staticClass:"bi-text-grey-default bi-mt-4 bi-mb-2"},[_vm._v("Brands")]),_c('div',{staticClass:"bi-flex bi-flex-wrap bi-gap-2"},_vm._l((_vm.brands),function(brand,index){return _c('s-button',{key:'brand' + index,staticClass:"bi-py-3 filter bi-font-semibold",attrs:{"active":_vm.brandFilters.includes(brand.id),"label":brand.name,"theme":"muted","color":"grey"},nativeOn:{"click":function($event){return _vm.applyBrandFilter(brand.id)}}})}),1),(_vm.category)?_vm._l((_vm.category.attributes),function(item,index){return _c('div',{key:'attributeHeading' + index},[_c('h5',{staticClass:"bi-text-grey-default bi-mt-4 bi-mb-2 bi-capitalize"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"bi-flex bi-gap-2 filter-tags"},_vm._l((item.attributeOption),function(option,index){return _c('s-button',{key:'attributeOption' + index,staticClass:"bi-py-4 filter bi-font-semibold",class:{
                      'aac-blocks-size-tag': item.name == 'Size' && _vm.categoryId == 3,
                    },attrs:{"label":option.name,"theme":"muted","active":_vm.attributeFilterId.includes(option.id),"color":"grey"},nativeOn:{"click":function($event){return _vm.applyAttributeFilter(option.id)}}})}),1)])}):_vm._e(),_c('s-button',{staticClass:"bi-mt-6 bi-font-semibold",attrs:{"size":"lg","color":"danger","theme":"outline","label":"Clear","icon":"IconIcRoundClose"},nativeOn:{"click":function($event){return _vm.resetFilters.apply(null, arguments)}}})],2)])]},proxy:true}])},[_c('sp-list-table',{on:{"rowClick":function (item) { return _vm.showProductsByBrand(item); }},scopedSlots:_vm._u([{key:"brand",fn:function(ref){
                    var item = ref.item;
return [(item.brand && item.brand.logo)?_c('BrandLogo',{attrs:{"brand":item.brand}}):_vm._e()]}},{key:"product",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"price",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"bi-flex bi-items-start bi-gap-2"},[(_vm.categoryNameFromHomeScreen == 'AAC Blocks')?_c('AacBlocksPrice',{key:("product-price-" + (item.id)),attrs:{"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"price":item.price,"unit":item.unit}}):_c('product-price',{attrs:{"price":item.price,"unit":item.unit}}),(_vm.categoryNameFromHomeScreen == 'AAC Blocks' && item.price)?_c('v-popover',{attrs:{"trigger":"hover"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('AacBlocksPriceBreakUp',{attrs:{"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"gstAmount":_vm.getAacBlocksGstForEachProduct(item),"basePrice":item.price}})]},proxy:true}],null,true)},[_c('s-button',{staticClass:"flex",attrs:{"icon":"IconMdiInformationOutline","size":"md","color":"secondary","theme":"link"}})],1):_vm._e()],1)]}},{key:"actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"bi-text-center"},[_c('s-button',{attrs:{"size":"md","color":"success","theme":"primary","label":"Select"},nativeOn:{"click":function($event){return _vm.showProductsByBrand(item)}}})],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }