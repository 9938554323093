<template>
  <div class="bi-flex bi-gap-2 bi-items-center">
    <h4 class="bi-text-md sm:bi-text-lg bi-font-normal bi-text-grey-default">Unloading</h4>
    <!-- <h4 class="bi-font-bold">Extra</h4> -->
    <!-- <Tag :label="label" :color="color" width="50px" /> -->
    <h4 :class="`bi-text-md sm:bi-text-lg bi-text-${color}-500`">{{ label }}</h4>
  </div>
</template>

<script>
export default {
  components: {
    // Tag: require("@/components/Tag").default,
  },
  props: {
    unloading: Number,
  },
  computed: {
    label() {
      if (this.unloading) return "Included";
      else return "Extra";
    },
    color() {
      if (this.unloading) return "green";
      else return "red";
    },
  },
};
</script>

<style lang="scss" scoped></style>
