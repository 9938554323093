<template>
  <div class="bi-flex sm:bi-gap-2 bi-items-center">
    <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" />
    <span class="bi-font-bold bi-text-grey-default">Show Total Breakup</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
