export default [
  {
    path: "orders",
    component: require("@/views/order/Index").default,
    children: [
      {
        path: "",
        name: "orders",
        component: require("@/views/order/List").default,
        meta: {
          label: "Orders",
          breadcrumbs: ["home", "orders"],
          icon: "IconMdiBasketCheckOutline",
        },
      },
      {
        path: "details/:id",
        props: true,
        name: "order-details",
        component: require("@/views/order/Details").default,
        meta: {
          label: "Order Details",
          breadcrumbs: ["orders", "order-details"],
        },
      },
    ],
  },
];
