import $axios from "@/plugins/axios";

export const get = (params = null) => {
  return $axios
    .get("categories", {
      params: params,
    })
    .then((res) => {
      return res.data;
    });
};

export const getAttributes = (id, params = null) => {
  return $axios.get(`categories/${id}`, { params }).then((res) => {
    return res.data;
  });
};

export const getDetails = (id, params = null) => {
  return $axios.get(`categories/${id}`, { params }).then((res) => {
    return res.data;
  });
};
