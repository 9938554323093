export const getAdminId = () => {
  let adminId = sessionStorage.getItem("by_admin_id");
  if (adminId) adminId = Number(adminId);

  return adminId;
};

export const getCompanyId = () => {
  let companyId = sessionStorage.getItem("company_id");
  if (companyId) companyId = Number(companyId);

  return companyId;
};

export const getToken = () => {
  const token = sessionStorage.getItem("bi-token") || localStorage.getItem("bi-token");
  return token ? token : null;
};
