<template>
  <div>
    <item-form
      :get="get"
      :item="id"
      :save="save"
      :footer="true"
      :redirect="false"
      class="bi-p-6"
      width="200"
      :fields="fields"
      :gap="2"
      ref="item"
      :form-state.sync="formState"
      :notify="false"
    >
      <template #default="{ form }">
        <div class="bi-grid md:bi-grid-cols-2 bi-gap-6 bi-w-full">
          <!--- Project Name --->
          <s-field-validate rules="required" name="Project Name">
            <TextBoxCapitalize placeholder="Name of Project" v-model="form.name" />
          </s-field-validate>

          <!--- Pincode --->
          <s-field-validate rules="required" name="PinCode" type="number">
            <!-- <s-textbox v-model="form.pinCode" type="number" placeholder="PinCode" /> -->
            <div>
              <v-select
                :filter-by="filterByPinCodeHandler"
                :options="areaOptions"
                label="display_name"
                class="area-dropdown"
                @search="handlePinCodeInput"
                v-model="form.areaId"
                placeholder="Enter Pin Code to search Area"
                :reduce="(item) => item.id"
              />
            </div>
          </s-field-validate>

          <div
            v-if="pinCodeMetaData.length"
            class="bi-col-start-1 bi-col-end-3 bi-flex bi-justify-between bi-items-center bi-bg-info-lightest bi-p-4 bi-rounded"
          >
            <!-- PinCOde  -->
            <s-field label="Pin Code">
              <span v-if="pinCode">{{ pinCode }}</span>
            </s-field>

            <s-field label="State">
              <span v-if="state">{{ state }}</span>
            </s-field>

            <s-field label="City">
              <span v-if="city">{{ city }}</span>
            </s-field>
          </div>
          <!-- Address -->
          <s-field-validate rules="required" name="address">
            <TextBoxCapitalize placeholder="Address" v-model="form.addressLineOne" />
          </s-field-validate>

          <!--- LandMark --->
          <s-field-validate rules="required" name="Landmark">
            <TextBoxCapitalize placeholder="Landmark" v-model="form.landmark" />
          </s-field-validate>
          <!--- City --->
          <!-- <s-field-validate rules="required" name="City">
           

            <v-select
              :options="cityOptions"
              label="name"
              :reduce="(item) => item.name"
              v-model="form.city"
              class="city-dropdown"
              placeholder="Select City"
              v-if="cityOptions.length"
            />
          </s-field-validate> -->

          <!--- Area --->
          <!-- <s-field-validate name="Area" rules="required"> -->
          <!-- <CustomDropDown
            v-if="areaOptions.length"
            :options="areaOptions"
            v-model="form.areaId"
            placeholder="Select Area"
          /> -->

          <!-- <v-select
              taggable
              :options="areaOptions"
              label="displayName"
              :reduce="(item) => item.id || item.name"
              class="area-dropdown"
              v-model="form.areaId"
              placeholder="Select Area"
              :create-option="(label) => ({ name: label, displayName: label, id: null })"
              v-if="areaOptions.length"
            /> -->

          <!-- <TextBoxCapitalize placeholder="Enter Area" v-model="form.area" /> -->
          <!-- </s-field-validate> -->

          <!--- Latitude/Longitude --->
          <div class="bi-flex bi-items-center bi-space-x-3 lat-lng">
            <s-field-validate rules="latitude" name="Latitude">
              <s-textbox v-model="form.latLong.lat" type="number" step="0.000001" placeholder="Latitude (Optional)" />
            </s-field-validate>
            <s-field-validate rules="longitude" name="Longitude">
              <s-textbox v-model="form.latLong.long" step="0.000001" type="number" placeholder="Longitude (Optional)" />
            </s-field-validate>
            <s-button label="Generate Map Link" @click.native="generateMapLink()" color="success" />
          </div>
          <!--- Google Map Link --->
          <!-- <div> -->
          <s-field-validate name="Google Map Link" rules="url">
            <s-textbox v-model="form.mapLink" type="text" placeholder="Google Map (Optional)" />
          </s-field-validate>
          <!-- </div> -->

          <s-field label="Mark as Primary Project" inline class="bi-items-center">
            <s-switch
              id="primary-project-switch"
              key="primary-project-switch"
              color="primary"
              size="xs"
              :on-value="true"
              :off-value="false"
              shape="pill"
              v-model="form.isDefault"
            />
          </s-field>

          <!-- No Entry Zone -->
          <s-field label="Mark as No Entry Zone" inline class="bi-items-center">
            <s-switch
              id="no-entry-zone-switch"
              key="no-entry-zone-switch"
              color="primary"
              size="xs"
              :on-value="true"
              :off-value="false"
              shape="pill"
              v-model="form.noEntryZone"
            />
          </s-field>

          <!-- Billing address same as Project Address -->
          <!-- <s-field label="Mark as Billing address" inline class="bi-items-center">
            <s-switch
              id="billing-address-switch"
              key="billing-address-switch"
              color="primary"
              size="xs"
              :on-value="true"
              :off-value="false"
              shape="pill"
              v-model="form.billingAddress"
            />
          </s-field> -->
        </div>
        <InfoTip class="bi-mt-4" width="100" title="Please Note" message="To add / update project incharge, click Next." />
      </template>

      <template #footer="{ saving }">
        <div class="bi-flex bi-items-center bi-gap-4">
          <s-button
            v-if="id !== '+'"
            size="lg"
            color="grey"
            shape="square"
            icon="IconIcOutlineArchive"
            theme="outline"
            @click.native="$shilp.modal.open('archive-modal')"
          />
          <s-button label="Next" :loader="saving" size="lg" class="bi-px-6" color="secondary" align="center" type="submit" />
        </div>
      </template>
    </item-form>

    <archive-modal :id="id" />
  </div>
</template>

<script>
import upsertProject from "@/graph/projects/upsert.gql";
import get from "@/graph/projects/get.gql";
import { mapGetters } from "vuex";
// import getAllAreas from "@/graph/area/collection.gql";
import commonData from "@/data/index.js";
// import { getAll as getAllCities } from "@/api/city.js";
import { cloneDeep } from "lodash-es";
import { get as getArea } from "@/api/project.js";
export default {
  commonData,
  computed: {
    ...mapGetters({
      user: "user/get",
    }),
    pinCodeMetaData() {
      if (this.areaOptions.length && this.formState.areaId) {
        return this.areaOptions.filter((area) => {
          return area.id == this.formState.areaId;
        });
      } else return [];
    },
    pinCode() {
      return this.pinCodeMetaData.length ? this.pinCodeMetaData[0].pincode : null;
    },
    state() {
      return this.pinCodeMetaData.length ? this.pinCodeMetaData[0].city.state.name : null;
    },
    city() {
      return this.pinCodeMetaData.length ? this.pinCodeMetaData[0].city.name : null;
    },
  },
  props: ["id"],
  components: {
    ArchiveModal: require("./ArchiveModal.vue").default,
    TextBoxCapitalize: require("@/components/TextBoxCapitalize").default,
    // CustomDropDown: require("@/components/CustomDropDown").default,
    InfoTip: require("@/components/InfoTip").default,
  },
  data() {
    // REFACTOR Ask backend to update isDefault to true and false instead of 0 and 1.
    return {
      fields: [
        "companyId",
        "name",
        "addressLineOne",
        "landmark",
        "city",
        "areaId",
        // "areaName",
        "pinCode",
        {
          name: "isDefault",
          value: false,
        },
        {
          name: "noEntryZone",
          value: false,
        },
        // {
        //   name: "billingAddress",
        //   value: false,
        // },
        {
          name: "latLong",
          value: {
            lat: "",
            long: "",
          },
        },
        "mapLink",
      ],
      areaOptions: [],
      cityOptions: [],
      pinCodeOptions: [],
      formState: {},
    };
  },
  created() {
    this.getOptions();
  },
  methods: {
    filterByPinCodeHandler(option, label, search) {
      return option.pincode.startsWith(search);
    },
    handlePinCodeInput(pincode) {
      if (pincode.length >= 4) {
        getArea({
          pincode: pincode,
          include: "city.state",
        }).then((res) => {
          this.areaOptions = res.data;
        });
      }
    },
    generateMapLink() {
      if (this.formState.latLong.lat && this.formState.latLong.long) {
        this.formState.mapLink = `https://maps.google.com/?q=${this.formState.latLong.lat},${this.formState.latLong.long}`;
        this.$shilp.notify({
          title: "Map link generated successfully",
          type: "success",
        });
      } else {
        this.$shilp.notify({
          title: "Please enter Latitude and Longitude of location",
          type: "danger",
        });
      }
    },
    async getOptions() {
      // this.getAreaOptions();
      // this.getCityOptions();
    },

    // async getCityOptions() {
    //   let res = await getAllCities({ limit: -1 });
    //   if (res.data && res.data.length) {
    //     this.cityOptions = res.data.filter((item) => {
    //       return item.name == "Ahmedabad" || item.name == "Gandhinagar";
    //     });
    //   }
    // },

    // async getAreaOptions() {
    //   let res = await this.$graph(getAllAreas, { perPage: -1 });
    //   if (!res.areas.data.length) return;
    //   // this.areaOptions = res.areas.data.map((item) => {
    //   //   return {
    //   //     name: item.displayName,
    //   //     value: item.id,
    //   //   };
    //   // });

    //   this.areaOptions = res.areas.data;
    // },
    get() {
      return this.$graph(get, {
        id: parseInt(this.id),
      }).then((res) => {
        res.project["areaId"] = res.project.area?.id;
        this.handlePinCodeInput(res.project.pinCode);
        return res.project;
      });
    },

    save(id) {
      let data = {};
      data = cloneDeep(this.formState);
      data["companyId"] = this.user.companies[0].id;

      // if (typeof data.areaId == "string") {
      //   data.areaName = data.areaId;
      //   data.areaId = null;
      // }

      return this.$graph(upsertProject, {
        id: parseInt(id),
        input: {
          ...data,
          city: data.city ? data.city : this.city,
          pinCode: data.pinCode ? data.pinCode : this.pinCode,
        },
      }).then((res) => {
        this.$router.push({
          name: "project-incharge-item",
          params: {
            projectId: res.upsertProject.id,
            id: id ? res.upsertProject.incharge[0]?.id : "+",
          },
        });
        return res;
      });
    },
  },
};
</script>

<style lang="scss">
.city-dropdown,
.area-dropdown {
  .vs__selected-options {
    align-items: center;
    .vs__selected {
      @apply bi-text-md;
    }
  }

  .vs__dropdown-toggle {
    height: 48px;
  }
}
</style>
