<template>
  <v-select
    placeholder="Select"
    :options="$options.options.invoiceStatus"
    :value="value"
    :searchable="false"
    :reduce="(item) => item.value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import options from "@/data/index";
export default {
  options,
  props: ["value"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
