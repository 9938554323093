<template>
  <modal size="md" title="Submit Confirmation" id="alert-upsert-partner-modal">
    <template #message>
      <div class="bi-flex bi-flex-col bi-gap-4">
        <div>
          <p>Please click "Add" button to save the unsaved partner details to table in order to save it.</p>
          <p class="bi-text-red-500">Note: Data will be lost if you don't click on Add button.</p>
        </div>

        <div>
          <p class="bi-font-light">Click <b>Submit Anyway</b> to continue without saving the unsaved partner detail.</p>
          <p class="bi-font-light">Click <b>OK</b> to stay on page.</p>
        </div>
      </div>
    </template>

    <template #default>
      <div class="bi-mt-8 bi-flex bi-flex-nowrap bi-gap-2">
        <s-button label="Submit Anyway" theme="outline" color="secondary" class="bi-flex-auto" size="lg" @click.native="submitAnyway" />
        <s-button
          label="OK"
          color="secondary"
          theme="muted"
          class="bi-flex-auto"
          size="lg"
          @click.native="$root.$emit('shilp-modal-close', 'alert-upsert-partner-modal')"
        />
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  methods: {
    submitAnyway() {
      this.$root.$emit("continue-submitting-kyc");
      this.$root.$emit("shilp-modal-close", "alert-upsert-partner-modal");
    },
  },
};
</script>

<style lang="scss"></style>
