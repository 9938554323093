<template>
  <div>
    <div class="bi-p-4 bi-h-full">
      <portal to="header-actions">
        <div>
          <s-button color="secondary" label="Add New Project" icon="IconIcRoundPlus" @click.native="details('+')" />
        </div>
      </portal>

      <sp-list
        endpoint="project"
        :params="params"
        ref="projectList"
        :perPage="25"
        sortBy="is_default"
        sortOrder="desc"
        pagination-mode="infinite"
        class="clickable"
        :attrs="columns"
        :actions="[]"
        v-infinite-scroll="loadMoreProjects"
        infinite-scroll-distance="10"
        :class="['bi-overflow-hidden']"
      >
        <template #header>
          <ProjectCategoryTabs />
        </template>
        <template #empty>
          <list-empty />
        </template>
        <sp-list-table @rowClick="details($event.id)" :class="['bi-overflow-x-scroll']">
          <template #name="{ item }">
            <span class="bi-text-primary-default bi-text-md bi-mr-2">{{ item.name }}</span>

            <s-button v-if="item.isDefault" label="Primary" color="secondary" theme="muted" size="xs" badge shape="pill" />
          </template>

          <template #address="{ item }">
            <span class="address">{{ item.address }}</span>
          </template>

          <template #actions="{ item }">
            <div class="bi-text-center">
              <s-button icon="IconIcSharpEdit" theme="muted" shape="circle" color="secondary" @click.native="details(item.id)" />
            </div>
          </template>
        </sp-list-table>
      </sp-list>
    </div>
    <!-- <div class="bi-flex bi-gap-4 bi-justify-center bi-items-center bi-flex-col">
      <p
      v-if="isKycInReview()"
      class="bi-flex bi-justify-center bi-h-full bi-items-center bi-text-grey-default"
    >
      Your Kyc Application is in Review. Please wait for Kyc Approval.
    </p>
      <p
        v-if="isKycPending()"
        class="bi-flex bi-justify-center bi-h-full bi-items-center bi-text-grey-default"
      >
        You do not have any Projects. To add new project, view price,and
        purchase our products. Complete your KYC first.
      </p>

      <s-button
        v-if="isKycPending()"
        color="secondary"
        label="Complete Your KYC"
        :to="{ name: 'upsert-company', params: { id: '+' } }"
      ></s-button>

      <s-button
        v-if="isKycRejected()"
        color="secondary"
        label="Update Your KYC"
        :to="{ name: 'complete-kyc', params: { id: companyId.toString() } }"
      ></s-button>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import kycMixin from "@/mixins/kycMixin";

export default {
  mixins: [kycMixin],
  components: {
    ProjectCategoryTabs: require("@/components/ProjectCategoryTabs").default,
  },
  data() {
    return {
      projectCategory: "active",
    };
  },
  computed: {
    ...mapGetters({
      companyId: "user/companyId",
      kycStatus: "user/kycStatus",
    }),

    tableHasMorePages() {
      return this.$refs.projectList.$data.response?.res?.projects.pagination?.hasMorePages;
    },

    params() {
      return {
        companyId: this.companyId,
      };
    },
    columns() {
      return [
        {
          name: "name",
          rowClick: true,
          label: "Project Name",
          width: "400px",
        },
        { name: "address", label: "Project Address", width: "400px" },
        {
          name: "projectIncharge",
          value: (item) => item.incharge[0]?.name,
          label: "Project Incharge",
          width: "200px",
        },
        {
          name: "inchargeDesignation",
          label: "Incharge Designation",
          value: (item) => item.incharge[0]?.designation[0]?.displayName,
          width: "200px",
        },
        {
          name: "inchargeMobileNo",
          label: "Incharge Mobile No",
          value: (item) => item.incharge[0]?.mobileNo,
          width: "200px",
        },
        {
          name: "actions",
          label: "Actions",
          rowClick: false,
          width: "200px",
        },
      ];
    },
  },

  methods: {
    loadMoreProjects() {
      // Code need to REFACTOR
      if (this.tableHasMorePages) return this.$refs.projectList.loadMore();
    },
    details(id) {
      if (id) {
        this.$router.push({ name: "project-item", params: { id } });
      }
    },
  },
};
</script>

<style lang="scss">
.address {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
