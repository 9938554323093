<template>
  <item-form
    class="bi-p-6"
    :item="id"
    :form-state.sync="formState"
    :save="save"
    :get="get"
    :fields="fields"
    :breadcrumb="false"
    :redirect="false"
    width="auto"
    ref="item"
    :notify="false"
    :gap="2"
  >
    <template #default="{ form }">
      <!---MSME Certificate--->
      <p>* Max file size: <span class="bi-font-bold">5MB</span></p>
      <div class="bi-flex bi-flex-wrap bi-gap-20">
        <!-- GST Certificate -->
        <s-field-validate rules="required" class="file-container-field" v-model="form.gstCertificate" name="GST Certificate" label="GST Certificate">
          <div>
            <!-- <file-upload :readonly="false" accept=".pdf" v-model="form.gstCertificate" width="100px" ratio="1x1" /> -->
            <VueFileAgentWrapper
              :description="$options.fileDescription"
              accept=".jpg,.png,.jpeg,application/pdf"
              v-model="form.gstCertificate"
              :linkable="true"
            />
          </div>
        </s-field-validate>

        <s-field-validate
          v-if="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('msmeCertificate')"
          :optional="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('msmeCertificate') ? true : false"
          :rules="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('msmeCertificate') ? '' : 'required'"
          class="file-container-field"
          v-model="form.msmeCertificate"
          name=""
          label="MSME Certificate"
        >
          <div>
            <!-- <file-upload :readonly="false" accept=".pdf" v-model="form.msmeCertificate" width="100px" ratio="1x1" /> -->
            <VueFileAgentWrapper
              :linkable="true"
              :description="$options.fileDescription"
              accept=".jpg,.png,.jpeg,application/pdf"
              v-model="form.msmeCertificate"
            />
          </div>
        </s-field-validate>

        <!-- PAN CARD -->
        <s-field-validate
          v-if="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('pancard')"
          :optional="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('pancard') ? true : false"
          class="file-container-field"
          v-model="form.pancard"
          name="Pan Card"
          label="Pan Card"
        >
          <div>
            <!-- <file-upload :readonly="false" accept=".pdf" v-model="form.pancard" width="100px" ratio="1x1" /> -->
            <VueFileAgentWrapper
              :linkable="true"
              :description="$options.fileDescription"
              accept=".jpg,.png,.jpeg,application/pdf"
              v-model="form.pancard"
            />
          </div>
        </s-field-validate>

        <!---Cancelled Cheque--->

        <s-field-validate
          v-if="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('cancelledCheque')"
          class="file-container-field"
          :optional="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('cancelledCheque') ? true : false"
          :rules="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('cancelledCheque') ? '' : 'required'"
          v-model="form.cancelledCheque"
          name=""
          label="Cancelled Cheque"
        >
          <div>
            <!-- <file-upload :readonly="false" accept=".jpg,.png,.jpeg" target="_blank" v-model="form.cancelledCheque" width="100px" ratio="1x1" /> -->
            <VueFileAgentWrapper
              :linkable="true"
              description="Upload Image only."
              accept=".jpg,.png,.jpeg,application/pdf"
              v-model="form.cancelledCheque"
            />
          </div>
        </s-field-validate>

        <!---Authority Letter--->
        <s-field-validate
          v-if="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('authorityLetter')"
          :optional="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('authorityLetter') ? true : false"
          :rules="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('authorityLetter') ? '' : 'required'"
          class="file-container-field"
          v-model="form.authorityLetter"
          name="Authority Letter"
          label="Authority Letter"
        >
          <div>
            <!-- <file-upload :readonly="false" accept=".pdf" v-model="form.authorityLetter" width="100px" ratio="1x1" /> -->
            <VueFileAgentWrapper
              :linkable="true"
              :description="$options.fileDescription"
              accept=".jpg,.png,.jpeg,application/pdf"
              v-model="form.authorityLetter"
            />
          </div>
        </s-field-validate>

        <!---Partnership--->

        <!-- <div class="bi-font-medium field__label">Partnership deed/AOA/MOA</div> -->
        <!-- <div class="bi-flex bi-gap-2 bi-flex-nowrap"> -->
        <!-- Partnership  -->
        <!-- <div>
                <s-field-validate
                  :optional="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnership') ? true : false"
                  :rules="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnership') ? '' : 'required'"
                  :desc="`Partnership ${allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnership') ? '' : '(optional)'}`"
                  v-model="form.partnershipMoa.partnership"
                  name="Partnership"
                  class="file-container-field"
                >
                  <div>
                    <file-upload
                      :readonly="false"
                      accept=".pdf"
                      v-model="form.partnershipMoa.partnership"
                      @input="partnershipMoaUpload($event, 'partnership')"
                      width="100px"
                      ratio="1x1"
                    />
                  </div>
                </s-field-validate>
              </div> -->
        <!-- DEED  -->
        <s-field-validate
          v-if="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipDeed')"
          :optional="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipDeed') ? true : false"
          :rules="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipDeed') ? '' : 'required'"
          v-model="form.partnershipMoa.partnershipDeed"
          label="Partnership Deed"
          name="Partnership Deed"
          class="file-container-field"
        >
          <div>
            <!-- <file-upload
                      :readonly="false"
                      accept=".pdf"
                      v-model="form.partnershipMoa.partnershipDeed"
                      @input="partnershipMoaUpload($event, 'partnershipDeed')"
                      width="100px"
                      ratio="1x1"
                    /> -->
            <VueFileAgentWrapper
              :linkable="true"
              :description="$options.fileDescription"
              accept=".jpg,.png,.jpeg,application/pdf"
              v-model="form.partnershipMoa.partnershipDeed"
            />
          </div>
        </s-field-validate>
        <!-- AOA  -->
        <s-field-validate
          v-if="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipAoa')"
          :optional="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipAoa') ? true : false"
          :rules="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipAoa') ? '' : 'required'"
          v-model="form.partnershipMoa.partnershipAoa"
          label="Company AOA"
          name="Company AOA"
          class="file-container-field"
        >
          <div>
            <!-- <file-upload
                      :readonly="false"
                      accept=".pdf"
                      v-model="form.partnershipMoa.partnershipAoa"
                      @input="partnershipMoaUpload($event, 'partnershipAoa')"
                      width="100px"
                      ratio="1x1"
                    /> -->
            <VueFileAgentWrapper
              :linkable="true"
              :description="$options.fileDescription"
              accept=".jpg,.png,.jpeg,application/pdf"
              v-model="form.partnershipMoa.partnershipAoa"
            />
          </div>
        </s-field-validate>
        <!-- MOA  -->
        <s-field-validate
          v-if="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipMoa')"
          :optional="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipMoa') ? true : false"
          :rules="allowedDocumentsToShowAndOptional(companyType ? companyType.name : null).includes('partnershipMoa') ? '' : 'required'"
          v-model="form.partnershipMoa.partnershipMoa"
          name="Company MOA"
          label="Company MOA"
          class="file-container-field"
        >
          <div>
            <!-- <file-upload
                      :readonly="false"
                      accept=".pdf"
                      v-model="form.partnershipMoa.partnershipMoa"
                      @input="partnershipMoaUpload($event, 'partnershipMoa')"
                      width="100px"
                      ratio="1x1"
                    /> -->
            <VueFileAgentWrapper
              :linkable="true"
              :description="$options.fileDescription"
              accept=".jpg,.png,.jpeg,application/pdf"
              v-model="form.partnershipMoa.partnershipMoa"
            />
          </div>
        </s-field-validate>
        <!-- </div> -->
      </div>
    </template>

    <template #footer="{ saving }">
      <div class="bi-flex bi-items-center">
        <s-button size="lg" theme="outline" label="Previous" @click.native="goToUpsertCompanyPage" />
        <s-button label="Next" color="secondary" align="center" size="lg" class="bi-ml-6" :loader="saving" type="submit" />
      </div>
    </template>
  </item-form>
</template>

<script>
import upsert from "@/graph/kyc/document/upsert.gql";
import get from "@/graph/kyc/get.gql";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash-es";
import { allowedDocumentsToShowAndOptional } from "@/utils/common";
import kycMixin from "@/mixins/kycMixin";
import { alertRouteChangeToBuyer } from "@/utils/common";
import completeKyc from "@/graph/kyc/complete.gql";
import fileUploadMixin from "@/mixins/fileUpload";

export default {
  mixins: [kycMixin, fileUploadMixin],
  components: {
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
  },
  fileDescription: "Upload Image or PDF Document",
  data() {
    return {
      formState: null,
      showAuthorityLetterFileUpload: false,
      msmeCertificate: null,
      cancelledCheque: null,
      authorityLetter: null,
      partnership: null,
      isDataUpdated: false,
      iframeWidth: "300px",
      iframeHeight: "300px",
      cancelledChequeImage: null,
      partnershipDeed: null,
      partnershipAoa: null,
      partnershipMoa: null,
      fields: [
        "msmeCertificate",
        "cancelledCheque",
        "authorityLetter",
        "pancard",
        "gstCertificate",
        {
          name: "partnershipMoa",
          value: {
            partnership: null,
            partnershipDeed: null,
            partnershipAoa: null,
            partnershipMoa: null,
          },
        },
      ],
    };
  },
  created() {},
  props: ["id"],
  computed: {
    ...mapGetters({
      companyId: "user/companyId",
      companyType: "user/companyType",
    }),
  },
  methods: {
    alertRouteChangeToBuyer,
    allowedDocumentsToShowAndOptional,
    goToUpsertCompanyPage() {
      if (this.alertRouteChangeToBuyer()) this.$router.push({ name: "upsert-company", id: this.companyId.toString() });
    },
    get() {
      return this.$graph(get, {
        id: this.id,
      }).then((res) => {
        // console.log("response", res);
        return res.company.documents;
      });
    },
    async save(id) {
      let form = cloneDeep(this.formState);

      if (id) {
        // if (form.partnershipMoa.partnership && form.partnershipMoa.partnership.url) {
        //   form.partnershipMoa.partnership = null;
        // } else this.isDataUpdated = true;

        if (this.allowedDocumentsToShowAndOptional(this.companyType.name).includes("pancard")) {
          if (form.pancard && form.pancard.url) {
            form.pancard = null;
          } else {
            if (this.formState.pancard) {
              const isValid = this.isMediaValid(this.formState.pancard);

              form.pancard = form.pancard[0].file;

              if (isValid) this.isDataUpdated = true;
              else return Promise.reject("");
            }
          }
        }
        if (form.gstCertificate && form.gstCertificate.url) {
          form.gstCertificate = null;
        } else {
          const isValid = this.isMediaValid(this.formState.gstCertificate);

          form.gstCertificate = form.gstCertificate[0].file;

          if (isValid) this.isDataUpdated = true;
          else return Promise.reject("");
        }
        if (this.allowedDocumentsToShowAndOptional(this.companyType.name).includes("partnershipMoa")) {
          if (form.partnershipMoa.partnershipMoa && form.partnershipMoa.partnershipMoa.url) {
            form.partnershipMoa.partnershipMoa = null;
          } else {
            if (this.formState.partnershipMoa.partnershipMoa) {
              const isValid = this.isMediaValid(this.formState.partnershipMoa.partnershipMoa);
              form.partnershipMoa.partnershipMoa = form.partnershipMoa.partnershipMoa[0].file;

              if (isValid) this.isDataUpdated = true;
              else return Promise.reject("");
            }
          }
        }

        if (this.allowedDocumentsToShowAndOptional(this.companyType.name).includes("partnershipAoa")) {
          if (form.partnershipMoa.partnershipAoa && form.partnershipMoa.partnershipAoa.url) {
            form.partnershipMoa.partnershipAoa = null;
          } else {
            if (this.formState.partnershipMoa.partnershipAoa) {
              const isValid = this.isMediaValid(this.formState.partnershipMoa.partnershipAoa);
              form.partnershipMoa.partnershipAoa = form.partnershipMoa.partnershipAoa[0].file;
              if (isValid) this.isDataUpdated = true;
              else return Promise.reject("");
            }
          }
        }

        if (this.allowedDocumentsToShowAndOptional(this.companyType.name).includes("partnershipDeed")) {
          if (form.partnershipMoa.partnershipDeed && form.partnershipMoa.partnershipDeed.url) {
            form.partnershipMoa.partnershipDeed = null;
          } else {
            if (this.formState.partnershipMoa.partnershipDeed) {
              const isValid = this.isMediaValid(this.formState.partnershipMoa.partnershipDeed);
              form.partnershipMoa.partnershipDeed = form.partnershipMoa.partnershipDeed[0].file;
              if (isValid) this.isDataUpdated = true;
              else return Promise.reject("");
            }
          }
        }

        if (this.allowedDocumentsToShowAndOptional(this.companyType.name).includes("authorityLetter")) {
          if (form.authorityLetter && form.authorityLetter.url) {
            form.authorityLetter = null;
          } else {
            if (this.formState.authorityLetter) {
              const isValid = this.isMediaValid(this.formState.authorityLetter);

              if (isValid) this.isDataUpdated = true;
              else return Promise.reject("");
            }
          }
        }
        if (this.allowedDocumentsToShowAndOptional(this.companyType.name).includes("cancelledCheque")) {
          if (form.cancelledCheque && form.cancelledCheque.url) {
            form.cancelledCheque = null;
          } else {
            if (form.cancelledCheque) {
              const isValid = this.isMediaValid(this.formState.cancelledCheque);

              // Getting file object from array
              form.cancelledCheque = form.cancelledCheque[0].file;

              if (isValid) this.isDataUpdated = true;
              else return Promise.reject("");
            }
          }
        }

        if (this.allowedDocumentsToShowAndOptional(this.companyType.name).includes("msmeCertificate")) {
          if (form.msmeCertificate && form.msmeCertificate.url) {
            form.msmeCertificate = null;
          } else {
            if (this.formState.msmeCertificate) {
              const isValid = this.isMediaValid(this.formState.msmeCertificate);
              form.msmeCertificate = form.msmeCertificate[0].file;
              if (isValid) this.isDataUpdated = true;
              else return Promise.reject("");
            }
          }
        }

        if (this.isDataUpdated) {
          await this.uploadDocuments(id, form);
        } else {
          if (this.companyType.name == "Proprietorship") {
            this.submitKycApplication();
          } else this.goToUpsertPartnerPage({ id: this.companyId.toString() });
        }
      } else {
        await this.uploadDocuments(id, form);
      }
    },

    async submitKycApplication() {
      this.$router.push({ name: "home" });
      await this.$graph(completeKyc);

      await this.hydrateUserWithCompanyType();

      this.$root.$emit("shilp-modal-open", "kyc-details-saved-modal");
    },
    goToUpsertPartnerPage(params) {
      this.$router.push({
        name: "upsert-partner",
        params: params,
      });
    },
    uploadDocuments(id, documents) {
      return new Promise((resolve, reject) => {
        this.$apolloUpload()
          .mutate({
            mutation: this.$gql(upsert),
            variables: {
              input: documents,
            },
          })
          .then(() => {
            if (this.companyType.name == "Proprietorship") {
              this.submitKycApplication();
            } else {
              if (id) {
                this.goToUpsertPartnerPage({ id: this.companyId.toString() });
              } else {
                this.goToUpsertPartnerPage({ id: "+" });
              }
              this.hydrateUserWithCompanyType();
            }

            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async partnershipMoaUpload(file, use_for) {
      // const file = event.target.files[0];
      this.formState.partnershipMoa[use_for] = file;
      // this.url[use_for] = URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss">
.file-container-field {
  @apply bi-font-medium;
  .field__content {
    @apply bi-w-40;
    flex: 0 0 0%;
  }
}
</style>
