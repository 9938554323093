<template>
  <div class="bi-h-full">
    <s-nav :fluid="false" :stack="false" class="category-mobile-bar bi-h-full bi-items-center">
      <template v-for="(category, index) in categories">
        <s-nav-item
          class="category-mobile-item bi-h-full"
          :key="'sidebar' + index"
          :label="category.label"
          :class="{
            'nav__item--exact-active': selectedCategoryIdFromStore == category.id,
          }"
          v-if="category && category.label"
          @click.native="onCategorySelect(category)"
        />
      </template>
    </s-nav>
  </div>
</template>

<script>
export default {
  props: {
    categories: Array,
    selectedCategoryIdFromStore: Number,
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {
    if (this.selectedCategoryIdFromStore) this.selected = this.selectedCategoryIdFromStore;
  },
  watch: {
    selectedCategoryIdFromStore(newValue) {
      if (newValue) this.selected = newValue;
    },
  },
  methods: {
    onCategorySelect(category) {
      this.selected = category.id;
      if (category.static) {
        this.$router.push({ name: "static-content", params: { categoryName: category.name } });
      } else {
        this.$emit("category-selected", category.id, category.name);
      }
    },

    isActive(category) {
      return this.selected == category.id;
    },
  },
};
</script>

<style lang="scss">
.category-mobile-bar {
  @apply bi-overflow-x-scroll bi-py-4;
  .category-mobile-item {
    // @apply bi-my-2;
    &.nav__item--exact-active {
      @apply bi-border-b-2 bi-border-secondary-default;
    }
    .button {
      @apply bi-pl-6 bi-text-base bi-capitalize bi-text-primary-default  bi-font-semibold bi-h-full;
    }
  }
}
</style>
