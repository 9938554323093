import Vue from "vue";
import "./vee-validate";
import "./shilp-vue";
import "./axios";

import VueSplide from "@splidejs/vue-splide";
Vue.use(VueSplide);
import "@splidejs/splide/dist/css/splide.min.css";

import "./v-tooltip";

// Vue infinite Scroll
// register globally
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

// V Mask

// Prefered: as a plugin (directive + filter) + custom placeholders support
import VueMask from "v-mask";
Vue.use(VueMask);
//  calender
import VCalendar from "v-calendar";
Vue.use(VCalendar, {
  componentPrefix: "vc",
  popoverVisibility: "focus", // Use <vc-calendar /> instead of <v-calendar />
});

// timer
import VueCountdownTimer from "vuejs-countdown-timer";
Vue.use(VueCountdownTimer);

/**
 * Portal
 */
import PortalVue from "portal-vue";
Vue.use(PortalVue);

/*
 * Vue Select
 */

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

// Vue File Agent
import "./vue-file-agent.js";
