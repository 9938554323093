<template>
  <sub-layout>
    <template #menu>
      <CategorySidebarWrapper @category-selected="selectedCategory = $event" />
    </template>

    <template #mobile-menu>
      <CategorySidebarWrapper @category-selected="selectedCategory = $event" />
    </template>

    <!-- Main Content  -->

    <template #default>
      <router-view :categoryId="selectedCategory" />
    </template>

    <template #bottom v-if="!isKycApproved()">
      <kyc-status-bar />
    </template>
  </sub-layout>
</template>

<script>
import kycMixin from "@/mixins/kycMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [kycMixin],
  computed: {
    ...mapGetters({
      categoryId: "categoryId",
    }),
  },
  components: {
    CategorySidebarWrapper: require("@/components/categorySidebar/Wrapper").default,
    KycStatusBar: require("@/components/KycStatusBar").default,
  },
  data() {
    return {
      selectedCategory: 1,
    };
  },
  created() {
    if (this.categoryId) this.selectedCategory = this.categoryId;
  },
  methods: {},
};
</script>
<style lang="scss"></style>
