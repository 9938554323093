<template>
  <span class="bi-font-bold bi-min-w-max" :class="classList">
    {{ formatPrice(price) }}
  </span>
</template>

<script>
import { formatPrice } from "@/utils/common.js";
export default {
  props: {
    price: {
      type: [String, Number],
      required: true,
    },
    classList: String,
  },
  methods: {
    formatPrice,
  },
};
</script>

<style></style>
