<template>
  <modal size="sm" title="Confirmation" id="archive-modal">
    <!-- Title comes here  -->

    <template #message>
      <p class="bi-text-md bi-text-grey-default bi-font-display bi-font-normal">
        Project will be removed from your Project List.<br />Are you sure you
        want to Archive?
      </p>
    </template>
    <div class="bi-mt-8 bi-flex bi-flex-nowrap">
      <s-button
        label="Keep it"
        size="lg"
        class="bi-flex-auto"
        align="center"
        theme="outline"
        color="transparent"
        @click.native="$shilp.modal.close('archive-modal')"
      />
      <s-button
        align="center"
        color="danger"
        theme="muted"
        size="lg"
        class="bi-ml-2 bi-flex-auto bi-font-bold"
        label="Archive"
        :loader="isArchiving"
        @click.native="archive"
      />
    </div>
  </modal>
</template>

<script>
import archive from "@/graph/projects/archive.gql";
export default {
  props: ["id"],
  data() {
    return {
      isArchiving: false,
    };
  },
  methods: {
    async archive() {
      try {
        this.isArchiving = true;
        await this.$graph(archive, {
          id: this.id,
        });
        this.$router.push({ name: "projects" });
      } catch (err) {
        this.$shilp.notify({
          title: "Failed to delete.",
          type: "danger",
          text: this.err,
        });
      } finally {
        this.isArchiving = false;
      }
    },
  },
};
</script>

<style></style>
