<template>
  <s-button label="Request For Price" @click.native="goToInquiry" color="secondary" theme="muted" size="sm" />
</template>

<script>
import { inquiryType } from "@/data/index";

export default {
  props: ["product"],
  methods: {
    goToInquiry() {
      this.$router.push({
        name: "inquiry-create",
        params: { product: this.product, fieldsDisabled: true, inquiryType: inquiryType.requestForPrice },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
