<template>
  <div class="bi-h-full">
    <s-nav :fluid="false" :stack="false" class="account-mobile-bar bi-h-full bi-items-center">
      <s-nav-item class="account-mobile-item" label="Company Details" :to="{ name: 'company-detail' }" />
      <s-nav-item label="Account & Security" class="account-mobile-item" :to="{ name: 'my-profile' }" v-if="!getAdminId()" />
      <!-- <s-nav-item label="Change Password" :to="{ name: 'change-password' }" class="account-mobile-item" /> -->
      <s-nav-item label="Sign In History" :to="{ name: 'sign-in-history' }" class="account-mobile-item" />
      <s-nav-item label="Inquiry" :to="{ name: 'inquiry-section' }" class="account-mobile-item" />
      <s-nav-item
        label="Credits"
        @click.native="goToCredits"
        :class="{ 'nav__item--active nav__item--exact-active': $route.name == 'credit-section' }"
        class="account-mobile-item"
      />
      <s-nav-item
        class="account-mobile-item"
        v-if="status == 'active'"
        label="Dectivate Account"
        @click.native="$shilp.modal.open('deactivate-account-modal')"
      />
      <s-nav-item class="account-mobile-item" label="Logout" @click.native="$shilp.modal.open('log-out-account-modal')" />
    </s-nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import kycMixin from "@/mixins/kycMixin";
import { getAdminId } from "@/utils/adminOrder";

export default {
  mixins: [kycMixin],

  computed: {
    ...mapGetters({
      status: "user/status",
    }),
  },
  methods: {
    getAdminId,
    goToCredits() {
      if (this.isKycApproved()) this.$router.push({ name: "credit-section" });
      else this.showKycStatusNotification();
    },
  },
};
</script>

<style lang="scss">
.account-mobile-bar {
  @apply bi-overflow-scroll bi-py-4;
  .account-mobile-item {
    @apply bi-h-full;
    // @apply bi-my-2;
    &.nav__item--exact-active {
      @apply bi-border-b-2 bi-border-secondary-default;
    }
    .button {
      @apply bi-pl-6 bi-text-base bi-capitalize bi-text-primary-default  bi-font-semibold bi-h-full;
    }
  }
}
</style>
