import $axios from "@/plugins/axios";

export const getPaymentStatusOptions = () => {
  return $axios.get("paymentStatuses").then((res) => {
    return res.data.data;
  });
};

export const getOrderStatusOptions = () => {
  return $axios.get("orderStatuses").then((res) => {
    return res.data.data;
  });
};
