<template>
  <s-field-validate name="Username" :rules="rules" :desc="desc">
    <s-textbox :value="value" type="text" placeholder="Username" @input="checkInput" :disabled="disabled" />
  </s-field-validate>
</template>

<script>
export default {
  props: {
    desc: String,
    value: String,
    rules: {
      type: String,
      default: "required",
    },
    disabled: Boolean,
  },
  methods: {
    checkInput($event) {
      this.$emit("input", $event.replace(" ", "_"));
    },
  },
};
</script>

<style lang="scss" scoped></style>
