<template>
  <portal to="project-dropdown">
    <!-- <div class="bi-flex bi-gap-4 bi-items-center"> -->
    <project-drop-down v-if="isKycApproved()" />
    <!-- </div> -->
  </portal>
</template>
<script>
import kycMixin from "@/mixins/kycMixin";
export default {
  mixins: [kycMixin],

  components: {
    ProjectDropDown: require("./DropDown").default,
  },
};
</script>
