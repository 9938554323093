<template>
  <div class="bi-max-w-sm">
    <ItemForm
      :notify="false"
      :fields="fields"
      :item="false"
      :form-state.sync="formState"
      ref="form"
      :gap="2"
      :save="submit"
      title="Update Mobile Number"
    >
      <template #default="{ form }">
        <CustomMobileInput v-model="form.mobile_no" placeholder="Enter New Mobile Number">
          <template #end="{ invalid }">
            <s-button label="Send OTP" :loader="isOtpLoading" color="secondary" :disabled="invalid" @click.native="sendOtp" />
          </template>
        </CustomMobileInput>

        <s-field-validate rules="required" name="Old Mobile Number Code">
          <s-textbox v-model="form.old_mobile_no_otp" placeholder="Enter Old Mobile Number Code" />
        </s-field-validate>

        <s-field-validate rules="required" name="New Mobile Number Code">
          <s-textbox v-model="form.new_mobile_no_otp" placeholder="Enter New Mobile Number Code" />
        </s-field-validate>
      </template>
      <template #footer="{ saving }">
        <s-button label="Submit" :loader="saving" type="submit" color="success" />
      </template>
    </ItemForm>
  </div>
</template>

<script>
import send from "@/graph/kyc/partner/otp.gql";
import { updateMobileNumber } from "@/api/updateMobileNumber";
import { mapGetters } from "vuex";

import kycMixin from "@/mixins/kycMixin";
export default {
  components: {
    CustomMobileInput: require("@/components/CustomMobileInput").default,
  },
  data() {
    return {
      formState: null,
      isOtpLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      mobileNumberFromStore: "user/mobileNumber",
    }),

    fields() {
      return ["mobile_no", "old_mobile_no_otp", "new_mobile_no_otp"];
    },
  },

  mixins: [kycMixin],
  methods: {
    submit(id, form) {
      return updateMobileNumber({
        old_mobile_no_otp: form.old_mobile_no_otp,
        new_mobile_no_otp: form.new_mobile_no_otp,
        code_for: "update_mobileno",
        mobile_no: form.mobile_no,
        old_mobile_no: this.mobileNumberFromStore,
      }).then((res) => {
        this.$shilp.notify({
          title: "Mobile Number Updated successfully.",
          type: "success",
        });

        this.hydrateUserWithCompanyType();
        this.$refs.form.reset();

        return res.data;
      });
    },
    sendOtp() {
      this.isOtpLoading = true;
      this.$graph(send, {
        input: {
          mobileNo: this.formState.mobile_no,
          codeFor: "update_mobileno",
        },
      }).then(() => {
        this.isOtpLoading = false;
        this.$shilp.notify({
          title: "Otp Sent on old and new mobile number. Please check.",
          type: "success",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
