<template>
  <s-button label="Out Of Stock" @click.native="goToInquiry" color="danger" theme="muted" size="lg" class="bi-flex bi-flex-col bi-gap-1">
    <template #after> <div class="bi-text-xs ml-2">Click To Request</div> </template>
  </s-button>
</template>

<script>
import { inquiryType } from "@/data/index";

export default {
  props: ["product"],
  methods: {
    goToInquiry() {
      this.$shilp.notify({
        title: "This product is out of stock. Please contact BuilditIndia support for further assistance. Thankyou.",
        type: "info",
        duration: 6000,
      });
      this.$router.push({
        name: "inquiry-create",
        params: { product: this.product, fieldsDisabled: true, inquiryType: inquiryType.outOfStock },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
