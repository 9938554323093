<template>
  <div class="bi-p-4 bi-h-full bi-overflow-hidden">
    <portal to="header-actions">
      <div>
        <s-button v-if="isKycApproved()" color="secondary" label="Add New Project" icon="IconIcRoundPlus" @click.native="details('+')" />
      </div>
    </portal>
    <sp-list
      class="bi-overflow-hidden"
      endpoint="rest:deletedProjects"
      :params="params"
      :perPage="100"
      pagination-mode="infinite"
      ref="projectList"
      :attrs="columns"
      :actions="[]"
    >
      <template #header>
        <ProjectCategoryTabs />
      </template>
      <template #empty>
        <list-empty />
      </template>
      <div class="bi-overflow-x-scroll">
        <sp-list-table class="project-list" style="width: 800px">
          <template #name="{ item }">
            <span class="bi-text-primary-default bi-text-md">{{ item.name }}</span>
          </template>

          <template #address="{ item }">
            <span>{{ item.name + ", " + item.landmark + ", " + item.city }}</span>
          </template>
        </sp-list-table>
      </div>
    </sp-list>

    <!-- <p
      v-if="isKycInReview()"
      class="bi-flex bi-justify-center bi-h-full bi-items-center bi-text-grey-default"
    >
      Your Kyc Application is in Review. Please wait for Kyc Approval.
    </p> -->
    <p v-if="isKycPending()" class="bi-flex bi-justify-center bi-h-full bi-items-center bi-text-grey-default">
      You do not have any Projects. To add new project, view price,and purchase our products. Complete your KYC first.
    </p>

    <s-button v-if="isKycPending()" color="secondary" label="Complete Your KYC" :to="{ name: 'upsert-company', params: { id: '+' } }"></s-button>

    <s-button
      v-if="isKycRejected()"
      color="secondary"
      label="Update Your KYC"
      :to="{ name: 'complete-kyc', params: { id: companyId.toString() } }"
    ></s-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import kycMixin from "@/mixins/kycMixin";

export default {
  mixins: [kycMixin],
  components: {
    ProjectCategoryTabs: require("@/components/ProjectCategoryTabs").default,
  },
  data() {
    return {
      projectCategory: "archive",
    };
  },
  computed: {
    ...mapGetters({
      companyId: "user/companyId",
      kycStatus: "user/kycStatus",
    }),

    params() {
      return {
        companyId: this.companyId,
      };
    },
    columns() {
      return [
        {
          name: "name",
          rowClick: true,
          label: "Project Name",
          width: "400px",
        },
        { name: "address", label: "Project Address", width: "400px" },
      ];
    },
  },

  methods: {
    details(id) {
      if (id) {
        this.$router.push({ name: "project-item", params: { id } });
      }
    },
  },
};
</script>

<style lang="scss">
.v-list.clickable .project-list .sp-table__table tbody tr:hover {
  cursor: pointer;
  background: #eceff1;
  box-shadow: 0 5px 10px 0px rgb(0 0 0 / 25%);
  transition: all 0.3s;
}
</style>
