<template>
  <div class="nav-count" v-if="totalNoOfProductsInCart">
    {{ totalNoOfProductsInCart }}
  </div>
</template>

<script>
import cartMixin from "@/mixins/cartMixin";

export default {
  mixins: [cartMixin],
};
</script>

<style>
.nav-count {
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 20;
  display: flex;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  --tw-text-opacity: 1;
  color: white;
  background: var(--color--success);
  border-radius: 99999px;
  font-weight: 600;
  margin-left: 12px;
}

.nav-count:after {
  content: "";
  z-index: -1;
  top: 0;
  border-radius: 9999px;
  right: 0;
  background: var(--color--danger--lighter);
  border-color: var(--color--danger--lighter);
  left: 0;

  @apply bi-animate-ping;
  bottom: 0;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .nav-count {
    margin-left: 0;
  }
}
</style>
