<template>
  <s-modal id="document-view-modal" overlay="dark">
    <div class="bi-flex bi-bg-white bi-flex-col bi-flex-nowrap bi-h-full bi-rounded-xl">
      <div class="bi-shrink bi-bg-white bb-lightest bi-px-3 bi-py-2">
        <div class="bi-flex bi-items-center">
          <h4 class="bi-flex-auto">
            {{ "View Document" }}
          </h4>
          <div class="bi-shrink">
            <s-button v-shilp-modal-close size="sm" shape="circle" theme="muted" color="grey" icon="vmdi-close"></s-button>
          </div>
        </div>
      </div>
      <div class="content bi-flex-auto bi-bg-white" v-if="company.documents">
        <div v-if="(company && company.documents.mimetype == 'image/jpeg') || (company && company.documents.mimetype == 'image/png')">
          <img :src="company.documents.cancelledCheque && company.documents.cancelledCheque.url" alt="" class="bi-w-full bi-p-4" />
        </div>
        <div v-else>
          <object
            :data="company.documents.cancelledCheque && company.documents.cancelledCheque.url"
            width="100%"
            style="object-fit: contain"
            height="500"
          ></object>
        </div>
      </div>
    </div>
  </s-modal>
</template>

<script>
// REFACTOR Rename this file to general name instead of hard coding the name and make this modal generic.
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      company: "user/company",
    }),
    params() {
      let id = this.company.id;
      return {
        companyId: id,
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  overflow: auto;
  padding: 15px;
}
</style>
