<template>
  <div class="header bi-h-full d-bi-flex bi-flex-col bi-overflow-hidden">
    <portal to="header-actions">
      <div>
        <div class="bi-hidden md:bi-block">
          <s-button
            v-if="cartProducts && cartProducts.length"
            label="Clear Truck"
            color="danger"
            icon="IconMdiTrashCanOutline"
            theme="outline"
            @click.native="clearTruckItems"
          />
        </div>
        <div class="bi-block md:bi-hidden">
          <s-button
            v-if="cartProducts && cartProducts.length"
            label=""
            color="danger"
            icon="IconMdiTrashCanOutline"
            theme="outline"
            @click.native="clearTruckItems"
          />
        </div>
      </div>
    </portal>
    <ProjectDropDownWrapper />
    <template v-if="cartProducts && cartProducts.length">
      <div class="table bi-mb-24">
        <sp-list
          ref="list"
          :filters="filters"
          :perPage="25"
          pagination-mode="infinite"
          v-infinite-scroll="loadMoreTruckItems"
          infinite-scroll-distance="10"
          :actions="[]"
          :attrs="columns"
          sortBy="price"
          sortOrder="asc"
          endpoint="rest:products"
          :params="params"
        >
          <template #empty>
            <list-empty />
          </template>
          <div class="bi-overflow-x-scroll">
            <sp-list-table class="truck-table">
              <template #brand="{ item }">
                <BrandLogo v-if="item && item.brand && item.brand.logo" :brand="item.brand" />
              </template>

              <template #price="{ item }">
                <div
                  class="bi-flex bi-items-center"
                  :class="[
                    {
                      'bi-animate-pulse': isPriceChangedForParticularProduct(item),
                    },
                    { 'bi-text-danger-default ': isPriceIncreased(item) },
                    { 'bi-text-success-default': isPriceDecreased(item) },
                  ]"
                >
                  <AacBlocksPrice
                    v-if="categoryNameInCart == 'AAC Blocks'"
                    :gstPercentage="getGstPercentage(item.attributeOptions)"
                    :price="item.price"
                    :unit="item.unit"
                    :key="`product-price-${item.id}`"
                  />
                  <product-price v-else :price="item.price" :unit="item.unit" />
                  <s-button
                    v-if="isPriceChangedForParticularProduct(item) && isPriceIncreased(item)"
                    style="cursor: default"
                    theme="trn"
                    color="danger"
                    shape="circle"
                    icon="IconMdiArrowUp"
                  />
                  <s-button
                    v-if="isPriceChangedForParticularProduct(item) && isPriceDecreased(item)"
                    style="cursor: default"
                    theme="trn"
                    color="success"
                    shape="circle"
                    icon="IconMdiArrowDown"
                  />

                  <span v-if="isPriceChangedForParticularProduct(item)"> ₹ {{ getPriceDifference(item) }} </span>
                </div>
              </template>

              <template #total="{ item }">
                <div class="bi-flex bi-gap-2">
                  <AacBlocksPrice
                    v-if="categoryNameInCart == 'AAC Blocks'"
                    :gstPercentage="getGstPercentage(item.attributeOptions)"
                    :price="getTotalPriceOfOrderedProduct(item)"
                    :key="`product-total-price-${item.id}`"
                  />
                  <product-price v-else :price="getTotalPriceOfOrderedProduct(item)" />
                  <v-popover trigger="click">
                    <template #popover>
                      <AacBlocksPriceBreakUp
                        v-if="item.categories[0].name == 'AAC Blocks'"
                        :gstPercentage="getGstPercentage(item.attributeOptions)"
                        :gstAmount="getAacBlocksGstPrice(getTotalPriceOfOrderedProduct(item), getGstPercentage(item.attributeOptions))"
                        :basePrice="getTotalPriceOfOrderedProduct(item)"
                      />
                      <!--  :gstAmount="
                        getAacBlocksGstPrice(
                          getTotalPriceOfOrderedProduct(item) +
                            getAacBlocksGstPrice(getTotalPriceOfOrderedProduct(item), getGstPercentage(item.attributeOptions)),
                          getGstPercentage(item.attributeOptions)
                        )
                      "
                      :basePrice="
                        getAacBlocksBasePrice(
                          getTotalPriceOfOrderedProduct(item) +
                            getAacBlocksGstPrice(getTotalPriceOfOrderedProduct(item), getGstPercentage(item.attributeOptions)),
                          getGstPercentage(item.attributeOptions)
                        )
                      " -->
                      <PriceBreakUp
                        v-else
                        :gstPercentage="getGstPercentage(item.attributeOptions)"
                        :gstAmount="getGstPrice(getTotalPriceOfOrderedProduct(item), getGstPercentage(item.attributeOptions))"
                        :basePrice="getBasePrice(getTotalPriceOfOrderedProduct(item), getGstPercentage(item.attributeOptions))"
                      />
                    </template>
                    <!-- <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" /> -->
                    <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
                  </v-popover>
                </div>
              </template>

              <template #actions="{ item }">
                <!-- <product-counter
                :id="item.id"
                :key="'productCounter-' + item.id"
                :price="item.price"
                :quantityOfOrder="getProductQuantity(item.id)"
              /> -->
                <CementCounter
                  v-if="getCategoryId(item) == 1"
                  :key="`product-counter-${item.id}`"
                  :id="item.id"
                  :categoryId="item.categories[0].id"
                  :price="item.price"
                  :quantityOfOrder="getProductQuantity(item.id)"
                  :unit="item.unit"
                  :brandId="item.brand.id"
                  :gstPercentage="getGstPercentage(item.attributeOptions)"
                  :isGstIncluded="isGstIncluded(item.attributeOptions)"
                />

                <TmtBarCounter
                  v-if="getCategoryId(item) == 2"
                  :key="`product-counter-${item.id}`"
                  :id="item.id"
                  :categoryId="item.categories[0].id"
                  :unit="item.unit"
                  :price="item.price"
                  :quantityOfOrder="getProductQuantity(item.id)"
                  :brandId="item.brand.id"
                  :gstPercentage="getGstPercentage(item.attributeOptions)"
                  :isGstIncluded="isGstIncluded(item.attributeOptions)"
                />

                <AacBlocksCounter
                  v-if="getCategoryId(item) == 3"
                  :key="`product-counter-${item.id}`"
                  :id="item.id"
                  :unit="item.unit"
                  :categoryId="item.categories[0].id"
                  :price="item.price + getAacBlocksGstPrice(item.price, getGradeAttributeObject(item.attributeOptions).gst_percentage)"
                  :quantityOfOrder="getProductQuantity(item.id)"
                  :brandId="item.brand.id"
                  :gstPercentage="getGstPercentage(item.attributeOptions)"
                  :isGstIncluded="isGstIncluded(item.attributeOptions)"
                />
              </template>
            </sp-list-table>
          </div>
        </sp-list>
      </div>

      <checkout :params="params" :filters="filters" @price-updated-from-api="refreshTableData" />
    </template>
    <template v-else>
      <truck-empty />
    </template>
  </div>
</template>

<script>
import {
  getAttributeValue,
  isAttributePresent,
  getGradeAttributeObject,
  getGstPercentage,
  getGstPrice,
  getBasePrice,
  getAacBlocksGstPrice,
  isGstIncluded,
  getAacBlocksBasePrice,
  getAacBlocksTotalPriceWithGst,
} from "@/utils/common";
import cartMixin from "@/mixins/cartMixin";
import kycMixin from "@/mixins/kycMixin";
import { mapActions, mapGetters } from "vuex";
import { get } from "@/api/product";
import { formatPrice } from "@/utils/common.js";
export default {
  mixins: [cartMixin, kycMixin],
  components: {
    BrandLogo: require("@/components/brandLogo/Component.vue").default,
    // ProductCounter: require("@/components/ProductCounter").default,
    CementCounter: require("@/components/productCounter/CementCounter").default,
    TmtBarCounter: require("@/components/productCounter/TmtBarCounter").default,
    AacBlocksCounter: require("@/components/productCounter/AacBlocksCounter").default,
    ProductPrice: require("@/components/ProductPrice").default,
    TruckEmpty: require("@/components/cart/TruckEmpty").default,
    Checkout: require("@/components/Checkout").default,
    ProjectDropDownWrapper: require("@/components/projectDropDown/Wrapper").default,
    // PageInDevelopment: require("@/components/PageInDevelopment").default,
    AacBlocksPrice: require("@/components/AacBlocksPrice").default,
    PriceBreakUp: require("@/components/products/PriceBreakUp").default,
    AacBlocksPriceBreakUp: require("@/components/products/AacBlocksPriceBreakUp").default,
  },
  data() {
    return {
      params: {
        include: "categories,brand,attributeOptions,attributeOptions.attribute",
      },
    };
  },
  created() {
    this.getProductList();
  },
  computed: {
    ...mapGetters({
      getCategoryDetail: "getCategoryDetail",
    }),
    category() {
      return this.getCategoryDetail(this.categoryIdFromCart);
    },
    tableHasMorePages() {
      return this.$refs.list.$data.response?.res?.projects.pagination?.hasMorePages;
    },
    columns() {
      return [
        {
          name: "categories",
          label: "Category",
          value: (item) => item.categories[0].name,
          width: "12%",
        },
        { name: "brand", label: "Brand", width: "12%" },
        {
          name: "grade",
          visible: isAttributePresent(this.category, "Grade"),
          value: (item) => getAttributeValue(item.attributeOptions, "Grade"),
          width: "12%",
        },
        {
          name: "size",
          visible: isAttributePresent(this.category, "Size"),
          value: (item) => getAttributeValue(item.attributeOptions, "Size"),
          width: "12%",
        },
        {
          name: "packing",
          visible: isAttributePresent(this.category, "Packing"),
          value: (item) => getAttributeValue(item.attributeOptions, "Packing"),
          width: "12%",
        },
        {
          name: "price",
          label: "Price/Unit",
          sortable: true,
          fix: true,
          width: "200px",
        },
        { name: "total", label: "Total", width: "12%" },
        {
          name: "actions",
          rowClick: false,
          fix: true,
          width: "15%",
        },
      ];
    },

    filters() {
      return {
        id: this.productIdOfOrders,
      };
    },
    productIdOfOrders() {
      let temp = [];
      for (let item of this.cartProducts) {
        temp.push(item.product_id);
      }
      return temp;
    },
  },
  methods: {
    ...mapActions(["setTotalPrice"]),
    getGstPercentage,
    isGstIncluded,
    getGstPrice,
    getBasePrice,
    getGradeAttributeObject,
    getAacBlocksGstPrice,
    getAacBlocksBasePrice,
    refreshTableData() {
      // below params has been set with page 1 to solve issue of sp list local page was going beyond limit causing no data to return
      this.params = {
        include: "categories,brand,attributeOptions,attributeOptions.attribute",
        page: 1,
      };
      this.$refs.list.refresh();
    },
    getCategoryId(item) {
      return item.categories[0].id;
    },
    loadMoreTruckItems() {
      // Code need to REFACTOR
      if (this.tableHasMorePages) return this.$refs.list.loadMore();
    },
    getTotalPriceOfOrderedProduct(item) {
      let product = this.findProductInCart(item.id);
      if (!product) return 0;
      let productTotal = product.quantity * item.price;

      // Adding GST
      // productTotal = productTotal + this.getPriceWithGst(item.price, this.getGradeAttributeObject(item.attributeOptions).gst_percentage);

      return productTotal;
    },
    getProductList() {
      get({ filter: this.filters, ...this.params })
        .then((res) => {
          this.checkPriceChangeFromProductList(res.data);
        })
        .catch((error) => console.log(error));
    },

    checkPriceChangeFromProductList(products) {
      const isPriceChanged = this.comparePrice(this.cartProducts, products);

      if (isPriceChanged) this.showPriceChangeAlert();
    },

    isPriceChangedForParticularProduct(productItem) {
      if (this.categoryNameInCart == "AAC Blocks") {
        for (let product of this.cartProducts) {
          if (productItem.id == product.product_id && getAacBlocksTotalPriceWithGst(productItem.price, product.gstPercentage) != product.price) {
            return true;
          }
        }
      } else {
        for (let product of this.cartProducts) {
          if (productItem.id == product.product_id && productItem.price != product.price) {
            return true;
          }
        }
      }
      return false;
    },

    getPriceDifference(productItem) {
      if (this.categoryNameInCart == "AAC Blocks") {
        for (let product of this.cartProducts) {
          if (productItem.id == product.product_id) {
            return formatPrice(getAacBlocksTotalPriceWithGst(productItem.price, product.gstPercentage) - product.price);
          }
        }
      } else {
        for (let product of this.cartProducts) {
          if (productItem.id == product.product_id) {
            return formatPrice(productItem.price - product.price);
          }
        }
      }
    },

    getOldPrice(productItem) {
      for (let product of this.cartProducts) {
        if (productItem.id == product.product_id) {
          return product.price;
        }
      }

      return 0;
    },

    isPriceIncreased(product) {
      const oldPrice = this.getOldPrice(product);

      let newPrice = 0;
      if (this.categoryNameInCart == "AAC Blocks") {
        newPrice = getAacBlocksTotalPriceWithGst(product.price, this.getGstPercentage(product.attributeOptions));
      } else {
        newPrice = product.price;
      }

      if (newPrice > oldPrice) {
        return true;
      }
      return false;
    },

    isPriceDecreased(product) {
      const oldPrice = this.getOldPrice(product);

      let newPrice = 0;
      if (this.categoryNameInCart == "AAC Blocks") {
        newPrice = getAacBlocksTotalPriceWithGst(product.price, this.getGstPercentage(product.attributeOptions));
      } else {
        newPrice = product.price;
      }

      if (newPrice < oldPrice) return true;
      return false;
    },

    showPriceChangeAlert() {
      this.$shilp.confirm({
        title: "Price Change Alert",
        message: `Product price has been updated. Please review before booking.`,
        okLabel: "Yes",
        ok: ({ close }) => {
          close();
        },
        cancel: false,
      });
    },
  },
};
</script>
<style lang="scss">
.truck-table {
  min-width: 1200px;
  .sp-table {
    &__table {
      tr {
        th {
          @apply bi-bg-grey-lightest-double-ultra bi-font-semibold;
        }
      }
    }
  }
}
</style>
