<template>
  <div class="product-counter-container">
    <s-button v-if="showAddBtn" size="sm" color="accent" theme="muted" rounded class="pl-4 pr-4" label="Add" @click.native="addInitialQuantity" />
    <template v-else>
      <div class="bi-flex bi-items-center bi-gap-2 bi-flex-nowrap expanded-counter bi-justify-center">
        <!-- <s-button
          size="sm"
          shape="circle"
          color="success"
          icon="IconIcRoundMinus"
          v-if="quantity >= 1"
          @click.native="removeProductFromCart"
        /> -->

        <s-field after="MT" class="tmt-input bi-w-36">
          <s-textbox
            type="number"
            step="0.01"
            v-mask="numberMask"
            v-model.number="quantity"
            @input="setQuantity"
            @focus.native="$event.target.select()"
          />
        </s-field>

        <!-- <s-button
          size="sm"
          shape="circle"
          color="success"
          icon="IconIcRoundPlus"
          v-if="quantity >= 1"
          @click.native="setQuantity(quantity)"
        /> -->
        <s-button size="sm" theme="muted" color="danger" label="Remove" rounded v-if="!showAddBtn" @click.native="remove" />
      </div>
    </template>
  </div>
</template>

<script>
import cartMixin from "@/mixins/cartMixin";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default {
  // REFACTOR Make single brand object instead of separate for brandId , brandName , loading , unloading , freight
  props: [
    "id",
    "price",
    "quantityOfOrder",
    "unit",
    "categoryId",
    "brandId",
    "brandName",
    "loading",
    "unloading",
    "freight",
    "gstPercentage",
    "isGstIncluded",
    "item",
  ],
  mixins: [cartMixin],
  data() {
    return {
      quantity: 0,
      showAddBtn: true,
      // First, you need to create the `numberMask` with your desired configurations
      numberMask: createNumberMask({
        allowDecimal: true,
        includeThousandsSeparator: false,
        prefix: "",
      }),
    };
  },
  computed: {
    // gstAmount() {
    //   return Number(((this.price / (100 + this.gstPercentage)) * this.gstPercentage).toFixed(2));
    // },
    // basePrice() {
    //   return Number((this.price - this.gstAmount).toFixed(2));
    // },
    // totalGstAmount() {
    //   return this.gstAmount * this.quantity;
    // },
    // totalBasePrice() {
    //   return this.basePrice * this.quantity;
    // },

    gstAmount() {
      // GST EXcluded , therefore adding up
      // REFACTOR below conversion of 2 decimal places and casting to Number.
      // return Number((this.totalAmount * (this.gstPercentage / 100)).toFixed(2));

      return (this.totalAmount / (100 + this.gstPercentage)) * this.gstPercentage;
    },
    basePrice() {
      // return Number((this.price - this.gstAmount).toFixed(2));
      return this.totalAmount - this.gstAmount;
    },
    totalAmount() {
      if (this.getExtraTimeCharges) {
        return (this.price + this.getExtraTimeCharges) * this.quantity;
      } else return this.price * this.quantity;
    },
  },
  watch: {
    basePrice() {
      let product = this.findProductInCart(this.id);
      product.basePrice = this.basePrice;
      product.gstAmount = this.gstAmount;
      product.gstPercentage = this.gstPercentage;
      product.total = this.totalAmount;
      this.updateCart(this.cartProducts);
    },
  },
  created() {
    // below event registration is to solve issue of counter state not updating after truck is cleared from button. therfore manually resetting.
    this.$root.$on("truck-got-empty", () => {
      this.quantity = 0;
      this.showAddBtn = true;
    });

    this.$root.$on("truck-got-empty-and-items-replaced", this.replaceProduct);
  },

  beforeDestroy() {
    this.$root.$off("truck-got-empty-and-items-replaced", this.replaceProduct);
  },

  mounted() {
    if (this.quantityOfOrder) {
      this.quantity = this.quantityOfOrder;
      this.showAddBtn = false;
    }
  },
  methods: {
    replaceProduct({ id }) {
      if (this.categoryId == 2 && this.id == id) {
        this.addInitialQuantity();
      }
    },
    remove() {
      this.showAddBtn = true;
      this.removeProductFromCart();

      if (this.cartProducts && !this.cartProducts.length) {
        this.emptyCart();
      }
    },
    addInitialQuantity() {
      let isBrandSameInCart;
      const isBrandPresentInCart = this.checkIfBrandIsPresentInCart();
      if (isBrandPresentInCart) {
        isBrandSameInCart = this.checkIfBrandIsSameInCart(this.brandId);
      } else {
        this.setBrandIdInCart(this.brandId);
        this.setBrandNameInCart(this.brandName);
        this.setUnitInCart(this.unit);
        isBrandSameInCart = true;
      }

      const isCategorySameInCart = this.checkIfCategoryIsSameInCart(this.categoryId);

      if (isCategorySameInCart && isBrandSameInCart) {
        this.setCategoryNameInCart(this.categoryNameFromHomeScreen);
        this.setLoadingOption(this.loading);
        this.setUnloadingOption(this.unloading);
        this.setFreightOption(this.freight);
        this.quantity = 1;
        this.addToCart({
          // totalGstAmount: this.totalGstAmount,
          // totalBasePrice: this.totalBasePrice,
          price: this.price,
          product_id: this.id,
          quantity: 1,
          // total: this.price,
          total: this.totalAmount,
          basePrice: this.basePrice,
          gstAmount: this.gstAmount,
        });

        this.setGstPercentage(this.gstPercentage);
        this.setGstIncluded(this.isGstIncluded);

        this.addProductCountInTotalQuantityOfCart();

        this.setTotalQuantity(this.totalQuantity + 1);
        this.showAddBtn = false;
      } else {
        this.alertDifferentOrderCategoryNotAllowed(this.id);
      }
    },

    setQuantity() {
      if (!this.quantity && this.quantity <= 0) {
        this.removeProductFromCart();
        return;
      } else {
        const isCategorySameInCart = this.checkIfCategoryIsSameInCart(this.categoryId);

        if (isCategorySameInCart) {
          const isProductPresent = this.checkIfProductIsPresentInCart();

          if (isProductPresent) {
            let product = this.findProductInCart(this.id);

            let oldQuantity = product.quantity;
            product.quantity = this.quantity;
            // product.totalGstAmount = this.totalGstAmount;
            // product.totalBasePrice = this.totalBasePrice;
            let total = product.quantity * this.price;
            // product.total = total;
            product.total = this.totalAmount;
            product.basePrice = this.basePrice;
            product.gstAmount = this.gstAmount;
            this.updateCart(this.cartProducts);
            this.setTotalQuantity(Number((this.totalQuantity - oldQuantity + this.quantity).toFixed(2)));
          } else {
            // If product is not present in cart then add product count in cart If buyer typed 0 - 1 decimal 0.55 range input
            this.addInitialQuantity();
          }
        } else {
          this.alertDifferentOrderCategoryNotAllowed(this.id);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.tmt-input {
  .field__content {
    input,
    .select {
      @apply bi-h-8;
    }
  }
}
</style>
