<template>
  <div class="bi-h-full" id="app">
    <!-- below inline style to container is given to position notification at center of screen -->
    <s-notification-container :width="width" theme="muted" />
    <s-confirm />
    <router-view />
    <s-modal-container />

    <!-- REFACTOR Update Name of components to PascalCase -->
    <deactivate-account-modal />
    <log-out-modal />
    <otp-modal />
    <different-order-category-not-allowed-modal />
    <AdditionalInstructionsOrderModal />
    <AlertUpsertPartnerModal />
    <DetailsSavedModal />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    DeactivateAccountModal: require("@/views/account/DeactivateAccountModal").default,
    LogOutModal: require("@/views/account/LogOutModal").default,
    OtpModal: require("@/components/OtpModal").default,
    DifferentOrderCategoryNotAllowedModal: require("@/components/DifferentOrderCategoryNotAllowedModal").default,

    AdditionalInstructionsOrderModal: require("@/components/order/AdditionalInstructionsModal").default,
    AlertUpsertPartnerModal: require("@/components/AlertUpsertPartnerModal").default,
    DetailsSavedModal: require("@/components/kyc/DetailsSavedModal").default,
  },
  data() {
    return {};
  },
  created() {
    window.addEventListener("online", () => this.showNetworkNotification("Internet connection restored. Please refresh this page.", "online"));
    window.addEventListener("offline", () =>
      this.showNetworkNotification("Internet connection lost. Please refresh this page once your connection is restored.", "offline")
    );
  },

  computed: {
    width() {
      return window.innerWidth < 768 ? "auto" : "600px";
    },
  },
  methods: {
    showNetworkNotification(message, type) {
      this.$shilp.notify({
        title: message,
        duration: 6000,
        type: type == "online" ? "success" : "danger",
      });
    },
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

.notification-container {
  top: 8px !important;
  left: calc(50% - 300px) !important;
  right: auto !important;
}
@media screen and (max-width: 768px) {
  .notification-container {
    top: 8px !important;
    left: 0 !important;
    right: 0 !important;
  }
}
</style>
