<template>
  <div v-if="isKycApproved()" class="bi-bg-white bi-py-2 bi-px-2 md:bi-px-5 bi-rounded bi-min-w-max">
    <div class="bi-flex bi-flex-col md:bi-gap-2 bi-items-center xl:bi-flex-row">
      <p class="bi-text-grey-default">Credit</p>
      <p class="bi-font-bold" :class="availableCredit > 0 ? 'bi-text-success-default' : 'bi-text-danger-default'">
        {{ formatPrice(availableCredit) }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatPrice } from "@/utils/common";
import kycMixin from "@/mixins/kycMixin";
export default {
  mixins: [kycMixin],
  computed: {
    ...mapGetters({
      availableCredit: "user/availableCredit",
    }),
  },
  methods: {
    formatPrice,
  },
};
</script>

<style lang="scss" scoped></style>
