<template>
    <s-field class="filter-search">
      <s-textbox @input="handleSearch" placeholder="Search" :value="value" />
    </s-field>
  </template>
  
  <script>
  import { debounce } from "lodash-es";
  export default {
    data() {
      return {
        value: "",
      };
    },
    methods: {
      handleSearch: debounce(function (value) {
        this.value = value;
        this.$emit("search", value);
      }, 1000),
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  