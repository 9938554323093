import { SET_USER, SET_PROJECT_ID, SET_COMPANY_TYPE } from "../mutation-types";
const state = {
  user: {},
  projectId: null,
  buyerReadyToChangeRoute: false,
};

const getters = {
  getBuyerReadyToChangeRoute: (state) => {
    return state.buyerReadyToChangeRoute;
  },
  getLastLoginDate: (state) => {
    return state.user.lastLoginDate;
  },
  utilizedCredit: (state) => {
    return state.user.companies[0].utilizeCredit;
  },
  availableCredit: (state) => {
    return state.user.companies[0].availableCredit;
  },
  totalCredit: (state) => {
    return state.user.companies[0].credit;
  },
  getProjectId: (state) => {
    return state.projectId;
  },
  get: (state) => {
    return state.user || null;
  },
  isAdminCreatedAccount: (state) => {
    return state.user?.isAdminCreated;
  },
  isAdminVerifiedAccount: (state) => {
    return state.user?.adminCompanyVerified;
  },
  companyId: (state) => {
    return state.user && state.user.companies?.[0].id;
  },
  company: (state) => {
    return state.user && state.user.companies?.[0];
  },
  supportPersonAssigned: (state, getters) => {
    return getters.company.assignTo;
  },
  companyName: (state) => {
    return state.user && state.user.companies[0].name;
  },
  companyType: (state) => {
    return state.user && state.user.companies[0].type;
  },
  kycStatus: (state) => {
    return state.user.companies[0]?.kycStatus;
  },
  status: (state) => {
    return state.user.status;
  },
  id: (state) => {
    return state.user.id;
  },
  team: (state) => {
    return state.user.companies[0].team ? state.user.companies[0].team : null;
  },

  mobileNumber: (state, getters) => {
    return getters.get.mobileNo;
  },
};

const mutations = {
  setBuyerReadyToChangeRoute(state, payload) {
    state.buyerReadyToChangeRoute = payload;
  },
  setCredits(state, payload) {
    state.user.companies[0].availableCredit = payload.availableCredit;
    state.user.companies[0].credit = payload.totalCredit;
    state.user.companies[0].utilizeCredit = payload.utilizeCredit;
  },
  [SET_PROJECT_ID](state, payload) {
    state.projectId = payload;
  },
  [SET_USER](state, payload) {
    state.user = payload;
  },
  [SET_COMPANY_TYPE](state, payload) {
    state.user.companies[0].type = payload;
  },

  resetState(state) {
    state.projectId = null;
    state.user = {};
  },
};

const actions = {
  setUser({ commit }, data) {
    commit(SET_USER, data);
  },
  setProjectId({ commit }, data) {
    commit(SET_PROJECT_ID, data);
  },

  setCompanyType({ commit }, data) {
    commit(SET_COMPANY_TYPE, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
