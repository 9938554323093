<template>
  <div class="bi-p-2 bi-text-xs bi-text-grey-default bi-font-normal bb-lightest bi-bg-grey-lightest-double-ultra">
    <h6 class="bi-text-grey-default bi-font-normal bi-my-2" v-for="productList in productListHeading" :key="productList.id">
      {{ productList }}
    </h6>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productListHeading: ["Grade", "Size/Packing", "Quantity", "Price", "Total"],
    };
  },
};
</script>

<style lang="scss" scoped></style>
