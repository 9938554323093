<template>
  <div v-shilp-loader.overlay="isLoading" v-infinite-scroll="loadMoreProducts" infinite-scroll-distance="10" class="bi-overflow-hidden bi-p-4">
    <portal to="header-actions">
      <div class="bi-flex bi-items-center bi-gap-2">
        <div class="bi-hidden md:bi-block">
          <s-button
            v-if="cartProducts && cartProducts.length"
            label="Clear Truck"
            color="danger"
            icon="IconMdiTrashCanOutline"
            theme="outline"
            @click.native="clearTruckItems"
          />
        </div>
        <div class="bi-block md:bi-hidden">
          <s-button
            v-if="cartProducts && cartProducts.length"
            label=""
            color="danger"
            icon="IconMdiTrashCanOutline"
            theme="outline"
            @click.native="clearTruckItems"
          />
        </div>

        <p class="bi-text-grey-default" v-if="products && products.length">
          Last updated at : <b> {{ $options.formatDate(products[0].updated_at) }} </b>
        </p>
      </div>
    </portal>

    <div
      class="bi-pl-4 bi-pr-4 bi-pt-2 bi-flex bi-justify-center sm:bi-justify-between bi-flex-wrap sm:bi-flex-nowrap sm:bi-items-end"
      v-if="products.length && products[0].brand"
    >
      <div class="bi-flex bi-items-center bi-gap-8">
        <img :src="products[0].brand.logo.url" class="bi-w-28 bi-h-28 bi-object-contain" />
      </div>
      <div class="bi-w-full sm:bi-w-auto bi-mt-3 sm:bi-mt-0">
        <v-popover trigger="hover" v-if="brochures && brochures.length" class="bi-justify-end">
          <s-button icon="IconMdiFileMultipleOutline" theme="link" size="lg" color="primary" label="See Brochures" />
          <template #popover>
            <div>
              <p v-for="brochure in brochures" :key="`brochure-${brochure.id}`" class="bi-text-grey-default my-2">
                Brochure - {{ brochure.id }}
                <s-button label="Open" @click.native="openUrl(brochure.url)" color="secondary" class="ml-2" />
              </p>
            </div>
          </template>
        </v-popover>

        <div class="bi-mb-2 bi-text-right">
          <s-button v-if="products[0].brand.description" label="Description" color="secondary" @click.native="openBrandDescModal" />
        </div>
        <div class="bi-flex bi-items-center bi-gap-2">
          <p v-if="getMinimumOrderQuantity(categoryId)" class="bi-text-danger-default bi-text-sm bi-text-right sm:bi-text-left">
            <span class="bi-text-danger-default bi-font-bold">*</span>Minimum order quantity:
            <span class="bi-font-bold">{{ getMinimumOrderQuantity(categoryId) }} {{ getCategoryWiseProductUnit(categoryId) }} </span>
          </p>
          <GstInfo class="bi-justify-end bi-text-sm" />
        </div>
      </div>
    </div>

    <sp-list
      v-if="filters.attribute_option_id"
      ref="list"
      :actions="[]"
      :perPage="10"
      sortBy="price"
      sortOrder="asc"
      pagination-mode="infinite"
      endpoint="rest:products"
      :attrs="columns"
      :params="{
        include: 'brand,categories.attribute.attributeOption,attributeOptions.attribute',
      }"
      :filters="filters"
    >
      <!-- <template #filters>
        <filter-sidebar-header @filterBarClose="$refs.list.toggleSidebar('filters')" />
        <filter-footer @resetFilters="resetFilters" />
      </template> -->
      <!-- :data="list" -->
      <template #empty>
        <list-empty :showClearFiltersButton="true" @resetFilters="resetFilters" />
      </template>
      <div class="bi-overflow-x-scroll">
        <sp-list-table class="product-list-by-brand">
          <template #product="{ item, rowIndex }">
            <span v-if="rowIndex == 0">{{ item.name }}</span>
            <span v-else></span>
          </template>
          <template #price="{ item }">
            <span class="bi-flex bi-items-start bi-gap-2">
              <AacBlocksPrice
                v-if="categoryNameFromHomeScreen == 'AAC Blocks'"
                :gstPercentage="getGstPercentage(item.attributeOptions)"
                :price="item.price"
                :unit="item.unit"
                :key="`product-price-${item.id}`"
              />
              <product-price v-else :price="item.price" :unit="item.unit" :key="`product-price-${item.id}`" />
              <v-popover trigger="hover" v-if="showAacBlocksGstAndBasePriceForEachProduct() && item.price">
                <template #popover>
                  <AacBlocksPriceBreakUp
                    :gstPercentage="getGstPercentage(item.attributeOptions)"
                    :gstAmount="getAacBlocksGstForEachProduct(item)"
                    :basePrice="getAacBlocksBasePriceForEachProduct(item)"
                  />
                </template>
                <!-- <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" /> -->
                <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
              </v-popover>
              <v-popover trigger="hover" v-if="showPerBagPriceForLooseCement(item)">
                <template #popover>1 Bag = ₹{{ item.price / 20 }}</template>
                <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" />
              </v-popover>
            </span>
          </template>

          <template #grade="{ rowIndex }">
            <CustomDropDown
              v-if="modifiedGradeOptions.length && rowIndex == 0"
              :options="modifiedGradeOptions"
              v-model="filters.attribute_option_id"
            />

            <!-- <s-select
            :options="gradeOptions"
            v-model="filters.attribute_option_id"
            v-if="gradeOptions.length && rowIndex == 0"
          /> -->
          </template>

          <template #packing="{ item }">
            <span class="bi-flex bi-items-center bi-gap-2"
              >{{ getPacking(item) }}
              <v-popover trigger="hover" v-if="getPackingToolTip(item)" popoverWrapperClass="bi-w-auto">
                <template #popover>
                  <span>{{ getPackingToolTip(item) }}</span>
                </template>
                <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" />
              </v-popover>
            </span>
          </template>

          <template #actions="{ item }">
            <!-- <product-counter
            :key="`product-counter-${item.id}`"
            :id="item.id"
            :price="item.price"
            :quantityOfOrder="getProductQuantity(item.id)"
          /> -->
            <div class="bi-text-center">
              <!-- Show Request for Price Button -->

              <CreditLimitExceeded :product="item" v-if="creditLimitCrossed" />
              <OutOfStock :product="item" v-else-if="item.out_of_stock" />

              <RequestForPrice :product="item" v-else-if="item.price == 0" />
              <div v-else>
                <CementCounter
                  v-if="categoryId == 1"
                  :key="`cement-counter-${item.id}`"
                  :id="item.id"
                  :ref="'cementCounter' + item.id"
                  :price="item.price"
                  :categoryId="item.categories[0].id"
                  :quantityOfOrder="getProductQuantity(item.id)"
                  :unit="item.unit"
                  :brandId="item.brand.id"
                  :brandName="item.brand.name"
                  :categoryName="item.categories[0].name"
                  :item="item"
                  :loading="item.brand.loading"
                  :unloading="item.brand.unloading"
                  :freight="item.brand.freight"
                  :gstPercentage="getGstPercentage(item.attributeOptions)"
                  :isGstIncluded="isGstIncluded(item.attributeOptions)"
                />

                <TmtBarCounter
                  v-if="categoryId == 2"
                  :key="`tmt-counter-${item.id}`"
                  :categoryId="item.categories[0].id"
                  :id="item.id"
                  :ref="'tmtBarCounter' + item.id"
                  :price="item.price"
                  :unit="item.unit"
                  :quantityOfOrder="getProductQuantity(item.id)"
                  :brandId="item.brand.id"
                  :brandName="item.brand.name"
                  :loading="item.brand.loading"
                  :unloading="item.brand.unloading"
                  :freight="item.brand.freight"
                  :item="item"
                  :gstPercentage="getGstPercentage(item.attributeOptions)"
                  :isGstIncluded="isGstIncluded(item.attributeOptions)"
                />

                <AacBlocksCounter
                  v-if="categoryId == 3"
                  :key="`aac-counter-${item.id}`"
                  :categoryId="item.categories[0].id"
                  :ref="'aacBlocksCounter' + item.id"
                  :id="item.id"
                  :unit="item.unit"
                  :quantityOfOrder="getProductQuantity(item.id)"
                  :brandId="item.brand.id"
                  :item="item"
                  :brandName="item.brand.name"
                  :loading="item.brand.loading"
                  :unloading="item.brand.unloading"
                  :freight="item.brand.freight"
                  :gstPercentage="getGstPercentage(item.attributeOptions)"
                  :price="item.price + getAacBlocksGstForEachProduct(item)"
                  :isGstIncluded="isGstIncluded(item.attributeOptions)"
                />
              </div>
            </div>
          </template>
        </sp-list-table>
      </div>
    </sp-list>

    <DescModal />
  </div>
</template>

<script>
// FIXME WHen coming from other page , sometimes we do not get list , instead gets empty list.
// import list from "./brandWiseData.json";
import attributeOptions from "@/data/attributeOptions.js";
import { getAttributeOptionDetail } from "@/api/attribute.js";
import { formatDate } from "@/utils/common.js";
import {
  getAttributeValue,
  isAttributePresent,
  getGradeAttributeObject,
  getAacBlocksGstPrice,
  getAacBlocksBasePrice,
  getGstPercentage,
  isGstIncluded,
} from "@/utils/common";
import cartMixin from "@/mixins/cartMixin";
import { get as getProducts } from "@/api/product.js";
import { getDetail } from "@/api/brands.js";
import { mapActions, mapGetters } from "vuex";
import creditLimitMixin from "@/mixins/creditLimit";
import DescModal from "./DescriptionModal.vue";
export default {
  // list,
  formatDate,
  attributeOptions,
  components: {
    // ProductCounter: require("@/components/ProductCounter").default,
    CustomDropDown: require("@/components/CustomDropDown").default,
    CementCounter: require("@/components/productCounter/CementCounter").default,
    TmtBarCounter: require("@/components/productCounter/TmtBarCounter").default,
    AacBlocksCounter: require("@/components/productCounter/AacBlocksCounter").default,
    ProductPrice: require("@/components/ProductPrice").default,
    // FilterSidebarHeader: require("@/components/tableFilter/FilterSidebarHeader").default,
    // FilterFooter: require("@/components/tableFilter/Footer").default,
    GstInfo: require("@/components/GstInfo").default,
    AacBlocksPriceBreakUp: require("@/components/products/AacBlocksPriceBreakUp").default,
    AacBlocksPrice: require("@/components/AacBlocksPrice").default,
    RequestForPrice: require("@/components/products/RequestForPrice").default,
    OutOfStock: require("@/components/products/OutOfStock").default,
    CreditLimitExceeded: require("@/components/products/CreditLimitExceeded").default,
    DescModal,
  },
  created() {
    this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, this.brandName);

    // Setting below category ID to solve issue of category resetting to 1 when page reloads.
    this.setCategoryId(Number(this.categoryId));
    // below code is written to set value of attribute dropdown with value as whole object.
    // V select component is not rendering label if only value is passed as string. therefore passing entire object
    // this.getDetails();

    this.getAvailableProductsAttributeOptionId();

    this.getBrochureApi(this.id);
    // else this.filters.attribute_option_id = 14;
  },
  watch: {
    // this watch is used to solve issue of not getting category on page reload , it takes time to hydrate from api therefore watching for response then setting
    category() {
      this.getDetails();

      if (this.category && this.category.name) {
        // This code is to solve problem of when customer directly loads this page and vuex has different category state. then setting it to loaded category.
        this.setCategoryName(this.category.name);
      }
    },
  },
  mixins: [cartMixin, creditLimitMixin],
  mounted() {
    this.getDetails();
  },

  props: ["id", "brandName", "attributeId", "categoryId"],
  data() {
    return {
      brochures: null,
      products: [],
      modifiedGradeOptions: [],
      availableProductsAttributeOptionId: [],
      filters: {
        attribute_option_id: null,
        brand_id: this.id,
        category_id: this.categoryId,
      },
      isLoading: true,
    };
  },

  computed: {
    // updating attributes column , toggling visilble property to handle if attribute is present or not.
    // updating value according to row data
    ...mapGetters({
      getCategoryDetail: "getCategoryDetail",
    }),
    category() {
      return this.getCategoryDetail(this.categoryId);
    },

    gradeOptions() {
      return this.category.attribute[0].attributeOption.map((item) => {
        return {
          name: item.name,
          value: item.id,
        };
      });
    },

    params() {
      return {
        filter: {
          ...this.filters,
        },
        include: "brand,categories.attribute.attributeOption,attributeOptions.attribute",
      };
    },
    tableCurrentPage() {
      return this.$refs?.list?.$data?.localPage;
    },
    tableLastPage() {
      return this.$refs?.list?.$data?.response?.pagination?.last_page;
    },
    columns() {
      return [
        { name: "product", label: "Product", fix: false, width: "200px" },
        {
          name: "grade",
          // value: (item) =>
          //   getAttributeValue(item.attributeOptions, "Grade"),
          fix: false,
          width: "200px",
        },

        {
          name: "packing",
          visible: isAttributePresent(this.category, "Packing"),
          width: "200px",
          // value: (item) => getAttributeValue(item.attributeOptions, "Packing"),
          fix: false,
        },
        {
          name: "size",
          visible: isAttributePresent(this.category, "Size"),
          value: (item) => getAttributeValue(item.attributeOptions, "Size"),
          fix: false,
          width: "200px",
        },
        { name: "price", fix: false, sortable: true, width: "200px" },

        { name: "actions", label: "Quantity", fix: true, width: "300px" },
      ];
    },
  },

  methods: {
    ...mapActions(["setCategoryId", "setCategoryName"]),
    getAacBlocksBasePrice,
    getGstPercentage,
    isGstIncluded,
    openBrandDescModal() {
      this.$root.$emit("shilp-modal-open", {
        id: "brand-desc-modal",
        scope: {
          content: this.products[0].brand.description,
        },
      });
    },
    getModifiedGradeOptions() {
      this.isLoading = true;
      let result = [];
      result = this.gradeOptions.filter((item) => {
        return this.availableProductsAttributeOptionId.includes(item.value);
      });
      this.modifiedGradeOptions = result;

      // This if is setting default value of 1st option in dropdown
      if (result && result.length) this.filters.attribute_option_id = result[0].value;

      // Setting selected attribute in dropdown
      if (this.attributeId) this.filters.attribute_option_id = this.attributeId;

      this.isLoading = false;
    },

    getAvailableProductsAttributeOptionId() {
      let result = [];
      let promisesArray = [];
      if (this.gradeOptions && this.gradeOptions.length) {
        this.gradeOptions.forEach((item) => {
          promisesArray.push(getAttributeOptionDetail(item.value, { include: "products", filter: { brand_id: this.id } }));
        });
      }

      this.availableProductsAttributeOptionId = result;
      Promise.all(promisesArray).then((values) => {
        if (values && values.length) {
          values.forEach((res) => {
            if (res.products && res.products.length) result.push(res.id);
          });

          this.availableProductsAttributeOptionId = result;

          this.getModifiedGradeOptions();
        }
      });
    },

    // getPriceOfProduct(product) {
    //   if (this.categoryNameFromHomeScreen == "AAC Blocks") {
    //     return product.price + this.getAacBlocksGstForEachProduct(product);
    //   } else return product.price;
    // },

    getAacBlocksGstPrice,
    getGradeAttributeObject,
    // openBrochure() {
    //   for (let item of this.brochures) {
    //     console.log("inside log", item);
    //     window.open(item.url, `brochure-${item.id}`);
    //   }
    // },

    openUrl(url) {
      window.open(url, "_blank");
    },
    getAacBlocksGstForEachProduct(product) {
      const result = this.getAacBlocksGstPrice(product.price, getGradeAttributeObject(product.attributeOptions).gst_percentage);
      return result;
    },
    getAacBlocksBasePriceForEachProduct(product) {
      // const result = this.getAacBlocksBasePrice(product.price, getGradeAttributeObject(product.attributeOptions).gst_percentage);

      return product.price;
    },
    showAacBlocksGstAndBasePriceForEachProduct() {
      if (this.categoryNameFromHomeScreen == "AAC Blocks") return true;
      else return false;
    },
    showPerBagPriceForLooseCement(item) {
      if (this.categoryNameFromHomeScreen == "Cement" && item.price && item.unit == "MT") return true;
      else return false;
    },
    getPacking(item) {
      const packing = getAttributeValue(item.attributeOptions, "Packing");
      if (packing == "Bags") return "Bags";
      else return packing;
    },
    getPackingToolTip(item) {
      const packing = getAttributeValue(item.attributeOptions, "Packing");
      if (packing == "Bags") return "1 Bag = 50 KG";
      else return false;
    },
    getAttributeValue,
    loadMoreProducts() {
      // below if condition is to solve error of undefined when list is loading.
      // Code need to REFACTOR
      if (this.tableCurrentPage < this.tableLastPage) return this.$refs.list.loadMore();
    },
    getProductItems() {
      // This method is made to solve issue of AAC Blocks birla brand has only products in flyash. not in sand , therefore resetting filters to show available items.
      getProducts(this.params).then((res) => {
        this.products = res.data;
        // if (this.isListEmpty(res.data)) {
        //   this.resetFilters();
        // }
      });
    },

    isListEmpty(items) {
      if (items.length) return false;

      return true;
    },
    resetFilters() {
      this.filters = {
        attribute_option_id: null,
        brand_id: this.id,
        category_id: this.categoryId,
      };
    },
    async getDetails() {
      // below logic is for setting default dropdown value example OPC 53 , so for that below method call
      // if user directly visits  brands section

      // if (!this.attributeId && this.category) {
      //   this.setDefaultAttributeId(this.category.attribute[0].attributeOption);
      // }

      // below call is made to check if no product is present in this brand , then notify user about it and go to home screen
      // To Show brand logo at top
      this.getProductItems();
    },

    getBrochureApi(id) {
      getDetail(id, { include: "brochure" }).then((res) => {
        // this.brochures = res.brochure;
        if (res.brochure && res.brochure.length) {
          // this.brochures = res.brochure.map((item) => item.brochureFiles);
          this.brochures = res.brochure[0].brochureFiles;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.sp-table.product-list-by-brand {
  min-width: 768px;
  td {
    border-top: 0px;
    border-bottom: 0px;
  }
}
.grade-drop-down {
  select {
    border: 1px solid var(--color--grey--lightest) !important;
  }
}

.brochure-section {
  .file-preview-wrapper.grid-box-item.grid-block {
    // smaller width will cause problems in click event
    min-width: 110px;
  }
  .file-preview-new {
    display: none !important;
  }
}
</style>

<style scoped lang="scss">
.v-popover {
  @apply bi-flex;
}
</style>
