<template>
  <!-- Hard coded style is given to solve UI issue of showing component at center of screen instead of sticking to top -->
  <div style="height: 300px" class="bi-text-center bi-flex bi-justify-center bi-items-center bi-flex-col bi-gap-4">
    <table class="sp-table__table">
      <thead>
        <tr>
          <th rowspan="1" colspan="1" class="sp-table__name">
            <div class="sp-table__head"><!----><label class="sp-table__label">Partner Name</label></div>
          </th>
          <th rowspan="1" colspan="1" class="sp-table__mobileNo">
            <div class="sp-table__head"><!----><label class="sp-table__label">Partner MobileNo</label></div>
          </th>
          <th rowspan="1" colspan="1" class="sp-table__isDefault sp-table__fix">
            <div class="sp-table__head"><!----><label class="sp-table__label">Main Partners</label></div>
          </th>
          <th rowspan="1" colspan="1" class="sp-table__actions sp-table__fix">
            <div class="sp-table__head"><!----><label class="sp-table__label">Actions</label></div>
          </th>
        </tr>
      </thead>
    </table>

    <div class="error-page__icon-3">
      <div class="error-page__icon-2">
        <div class="error-page__icon-1">
          <div class="error-page__icon">
            <icon name="IconMdiFileMultipleOutline" :size="6" color="light" class />
          </div>
        </div>
      </div>
    </div>

    <div class="bi-block bi-text-lg bi-mt-3 bi-font-semibold bi-text-grey-default">No partners found. Please add partner.</div>
  </div>
</template>

<script>
export default {
  // REFACTOR Need to pass dynamic Columns to render title
  components: {
    Icon: require("@/components/Icon").default,
  },
  props: {},
};
</script>

<style lang="scss">
.error-page__icon-3 {
  background-color: var(--color--vepaar-primary--lightest);
}

.error-page__icon-2 {
  background-color: var(--color--vepaar-primary--lighter);
}

.error-page__icon-1 {
  background-color: var(--color--vepaar-primary--light);
}

.error-page__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--color--vepaar-primary);
  padding: 20px;
}

.error-page__icon-1,
.error-page__icon-2,
.error-page__icon-3 {
  padding: var(--space--2);
  border-radius: 50%;
  display: inline-block;
}
</style>
