<template>
  <modal size="sm" title="Request Invoice" id="invoice-request-modal" @close="resetModal">
    <template #default="{ data }">
      <s-field>
        <s-textarea rows="4" placeholder="Write here.." v-model="message" />
      </s-field>
      <div class="bi-flex bi-justify-center bi-mt-4">
        <s-button @click.native="requestInvoice(data.orderId)" label="Request" color="secondary" />
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  methods: {
    resetModal() {
      this.message = "";
    },
    requestInvoice(orderId) {
      this.$emit("invoice-request", { message: this.message, orderId: orderId });
      this.resetModal();
      this.$root.$emit("shilp-modal-close", "invoice-request-modal");
    },
  },
};
</script>

<style lang="scss" scoped></style>
