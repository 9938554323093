import kycStatusOptions from "@/data/kyc";
import { mapGetters, mapActions } from "vuex";
import get from "@/graph/kyc/get.gql";
import getCompany from "@/graph/company/get.gql";
import hydrateQuery from "@/graph/hydrate.gql";

export default {
  kycStatusOptions,
  computed: {
    ...mapGetters({
      kycStatus: "user/kycStatus",
    }),
  },

  methods: {
    ...mapActions(["hydrate"]),

    isKycApproved() {
      if (this.kycStatus == this.$options.kycStatusOptions.approve) return true;
      else return false;
    },
    isKycInComplete() {
      if (this.kycStatus == this.$options.kycStatusOptions.incomplete) return true;
      else return false;
    },
    isKycPending() {
      if (this.kycStatus == this.$options.kycStatusOptions.pending) return true;
      else return false;
    },
    isKycRejected() {
      if (this.kycStatus == this.$options.kycStatusOptions.reject) return true;
      else return false;
    },
    isKycInReview() {
      if (this.kycStatus == this.$options.kycStatusOptions.inreview) return true;
      else return false;
    },
    kycInReviewNotification() {
      if (this.kycStatus == this.$options.kycStatusOptions.inreview)
        this.$shilp.notify({
          title: "Your KYC details are in Review.",
          type: "info",
        });
    },
    kycInPendingNotification() {
      if (this.kycStatus == this.$options.kycStatusOptions.pending)
        this.$shilp.notify({
          title: "Your KYC is Pending. Please submit your KYC details.",
          type: "warning",
        });
    },
    kycApprovedNotification() {
      if (this.kycStatus == this.$options.kycStatusOptions.approve)
        this.$shilp.notify({
          title: "Your KYC is Approved. You can start ordering now.",
          type: "accent",
        });
    },
    kycRejectedNotification() {
      if (this.kycStatus == this.$options.kycStatusOptions.reject)
        this.$shilp.notify({
          title: "Your KYC is Rejected. Please update your KYC details.",
          type: "danger",
        });
    },
    kycInCompleteNotification() {
      if (this.kycStatus == this.$options.kycStatusOptions.incomplete)
        this.$shilp.notify({
          title: "Your KYC is Incomplete. Please finish all the kyc steps.",
          type: "info",
        });
    },

    async getCompanyTypeFromApi() {
      return this.$graph(get, {
        id: this.id,
      }).then((res) => {
        // console.log("response", res);
        return res.company.type;
      });
    },

    getCompanyType(gstNo, companyId) {
      return this.$graph(getCompany, {
        gstNo: gstNo,
        id: String(companyId),
      }).then((res) => {
        return res.company.type ? res.company.type : null;
      });
    },

    async hydrateUserWithCompanyType() {
      let user = await this.$graph(hydrateQuery, {}).then((res) => {
        return res.me;
      });

      // REFACTOR Use kycMixin for companytype  method here instead and remove below code.
      if (user.companies.length) {
        const companyType = await this.getCompanyType(user.companies[0].gstNo, user.companies[0].id);
        user.companies[0].type = companyType;
      }

      await this.hydrate(user);

      return Promise.resolve(user);
    },

    showKycStatusNotification() {
      switch (this.kycStatus) {
        case this.$options.kycStatusOptions.approve:
          this.kycApprovedNotification();
          break;
        case this.$options.kycStatusOptions.inreview:
          this.kycInReviewNotification();
          break;
        case this.$options.kycStatusOptions.reject:
          this.kycRejectedNotification();
          break;
        case this.$options.kycStatusOptions.incomplete:
          this.kycInCompleteNotification();
          break;
        default:
          this.kycInPendingNotification();
      }
    },
  },
};
