<template>
  <s-button icon="IconMdiInformationOutline" theme="link" :color="color" />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary",
    },
  },
};
</script>

<style lang="scss" scoped></style>
