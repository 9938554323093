export default [
  {
    path: "invoice",
    component: require("@/views/invoice/Index").default,
    children: [
      {
        path: "",
        name: "invoice",
        component: require("@/views/invoice/List").default,
        meta: {
          label: "Invoice",
          breadcrumbs: ["home", "invoice"],
          icon: "IconMdiBasketCheckOutline",
        },
      },
    ],
  },
];
