import {
  ADD_TO_CART,
  UPDATE_CART,
  SET_TOTAL_PRICE,
  SET_TOTAL_QUANTITY,
  RESET_CART,
  SET_CATEGORY_ID_IN_CART,
  SET_TOTAL_NO_OF_PRODUCTS_IN_CART,
  SET_CATEGORY_NAME_IN_CART,
  SET_BRAND_ID_IN_CART,
  SET_BRAND_NAME_IN_CART,
  SET_UNIT,
  SET_LOADING_IN_CART,
  SET_UNLOADING_IN_CART,
  SET_FREIGHT_IN_CART,
  SET_GST_PERCENTAGE,
  SET_GST_INCLUDED,
  SET_EXTRA_TIME_CHARGES,
  SET_TOTAL_CART_AMOUNT,
} from "../mutation-types";

const state = {
  cart: {
    products: [],
    totalQuantity: 0,
    totalNoOfProducts: 0,
    categoryId: null,
    categoryName: "",
    brandId: null,
    brandName: "",
    loading: null,
    unloading: null,
    freight: null,
    unit: null,
    gstPercentage: null,
    totalCartAmount: 0,
    gstIncluded: null,
    extraTimeCharges: 0,
  },
};

const getters = {
  getTotalCartAmount(state) {
    return state.cart.totalCartAmount;
  },
  getExtraTimeCharges(state) {
    return state.cart.extraTimeCharges;
  },
  getIsGstIncluded(state) {
    return state.cart.gstIncluded;
  },
  getGstPercentage(state) {
    return state.cart.gstPercentage;
  },
  getLoadingOption(state) {
    return state.cart.loading;
  },
  getUnLoadingOption(state) {
    return state.cart.unloading;
  },
  getFreightOption(state) {
    return state.cart.freight;
  },
  getUnit(state) {
    return state.cart.unit;
  },
  getCart(state) {
    return state.cart.products;
  },
  getTotalQuantity(state) {
    return state.cart.totalQuantity;
  },
  brandIdFromCart(state) {
    return state.cart.brandId;
  },
  brandNameFromCart(state) {
    return state.cart.brandName;
  },
  getCategoryId(state) {
    return state.cart.categoryId;
  },
  totalNoOfProductsInCart(state) {
    return state.cart.totalNoOfProducts;
  },
  categoryNameInCart(state) {
    return state.cart.categoryName;
  },
};

// REFACTOR No need to have cart module name inside cart module. It should be directly state.property;

const mutations = {
  [SET_TOTAL_CART_AMOUNT](state, payload) {
    state.cart.totalCartAmount = payload;
  },
  [SET_EXTRA_TIME_CHARGES](state, payload) {
    state.cart.extraTimeCharges = payload;
  },
  [SET_GST_INCLUDED](state, payload) {
    state.cart.gstIncluded = payload;
  },
  [SET_GST_PERCENTAGE](state, payload) {
    state.cart.gstPercentage = payload;
  },
  [SET_LOADING_IN_CART](state, payload) {
    state.cart.loading = payload;
  },
  [SET_UNLOADING_IN_CART](state, payload) {
    state.cart.unloading = payload;
  },
  [SET_FREIGHT_IN_CART](state, payload) {
    state.cart.freight = payload;
  },
  [SET_BRAND_NAME_IN_CART](state, payload) {
    state.cart.brandName = payload;
  },
  [SET_UNIT](state, payload) {
    state.cart.unit = payload;
  },
  [SET_BRAND_ID_IN_CART](state, payload) {
    state.cart.brandId = payload;
  },
  [SET_CATEGORY_ID_IN_CART](state, payload) {
    state.cart.categoryId = payload;
  },
  [SET_CATEGORY_NAME_IN_CART](state, payload) {
    state.cart.categoryName = payload;
  },
  [ADD_TO_CART](state, payload) {
    state.cart.products.push(payload);
  },
  [UPDATE_CART](state, payload) {
    state.cart.products = payload;
  },
  [SET_TOTAL_PRICE](state, payload) {
    state.cart.total = payload;
  },
  [SET_TOTAL_QUANTITY](state, payload) {
    state.cart.totalQuantity = payload;
  },
  [SET_TOTAL_NO_OF_PRODUCTS_IN_CART](state, payload) {
    state.cart.totalNoOfProducts = payload;
  },
  [RESET_CART](state) {
    state.cart.products = [];
    state.cart.totalQuantity = 0;
    state.cart.totalNoOfProducts = 0;
    state.cart.categoryId = null;
    state.cart.categoryName = "";
    state.cart.brandId = null;
    state.cart.brandName = "";
    state.cart.unit = null;
    state.cart.loading = null;
    state.cart.unloading = null;
    state.cart.freight = null;
    state.cart.gstPercentage = null;
    state.cart.gstIncluded = null;
    state.cart.extraTimeCharges = 0;
    state.cart.totalCartAmount = 0;
  },
};

const actions = {
  setTotalCartAmount({ commit }, data) {
    commit(SET_TOTAL_CART_AMOUNT, data);
  },
  setExtraTimeCharges({ commit }, data) {
    commit(SET_EXTRA_TIME_CHARGES, data);
  },
  setGstIncluded({ commit }, data) {
    commit(SET_GST_INCLUDED, data);
  },
  setGstPercentage({ commit }, data) {
    commit(SET_GST_PERCENTAGE, data);
  },
  setBrandNameInCart({ commit }, data) {
    commit(SET_BRAND_NAME_IN_CART, data);
  },
  setUnitInCart({ commit }, data) {
    commit(SET_UNIT, data);
  },
  setBrandIdInCart({ commit }, data) {
    commit(SET_BRAND_ID_IN_CART, data);
  },
  setCategoryIdInCart({ commit }, data) {
    commit(SET_CATEGORY_ID_IN_CART, data);
  },
  addToCart({ commit }, data) {
    commit(ADD_TO_CART, data);
  },
  updateCart({ commit }, data) {
    commit(UPDATE_CART, data);
  },
  setTotalPrice({ commit }, data) {
    commit(SET_TOTAL_PRICE, data);
  },
  setTotalQuantity({ commit }, data) {
    commit(SET_TOTAL_QUANTITY, data);
  },
  setTotalNoOfProductsInCart({ commit }, data) {
    commit(SET_TOTAL_NO_OF_PRODUCTS_IN_CART, data);
  },
  setCategoryNameInCart({ commit }, data) {
    commit(SET_CATEGORY_NAME_IN_CART, data);
  },
  resetCart({ commit }) {
    commit(RESET_CART);
  },

  setLoadingOption({ commit }, data) {
    commit(SET_LOADING_IN_CART, data);
  },

  setUnloadingOption({ commit }, data) {
    commit(SET_UNLOADING_IN_CART, data);
  },

  setFreightOption({ commit }, data) {
    commit(SET_FREIGHT_IN_CART, data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
