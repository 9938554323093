export const creditLimit = -1500000;
export const inquiryType = {
  general: 0,
  requestForPrice: 1,
  outOfStock: 2,
  creditLimitCrossed: 3,
};
export default {
  companyStatus: [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "InActive",
      value: "inactive",
    },
  ],
  kycStatus: [
    {
      label: "Approved",
      value: "approve",
    },
    {
      label: "Rejected",
      value: "reject",
    },
  ],
  city: [
    {
      name: "Ahmedabad",
      label: "Ahmedabad",
      value: "ahmedabad",
    },
    {
      name: "Gandhinagar",
      label: "Gandhinagar",
      value: "gandhinagar",
    },
  ],
  invoiceStatus: [
    {
      label: "Pending",
      value: 1,
    },
    {
      label: "Uploaded",
      value: 0,
    },
  ],
};
