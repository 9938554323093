<template>
  <item-form
    :item="false"
    class="account-detail"
    :save="change"
    :breadcrumb="false"
    title="Change Password"
    desc="To set a new password, you need to enter your current password below."
    :notify="false"
    :fields="fields"
    :redirect="false"
    width="100"
    :gap="2"
    ref="item"
  >
    <template #default="{ form }">
      <s-field-validate v-if="showCurrentPassword" name="Current Password" :password-toggle="true">
        <s-textbox v-model="form.currentPassword" type="password" placeholder="Current Password" />
      </s-field-validate>
      <s-field-validate vid="newPassword" rules="required|min:8" name="New Password" :password-toggle="true">
        <s-textbox type="password" v-model="form.newPassword" placeholder="New Password" />
      </s-field-validate>
      <s-field-validate rules="required|password:@newPassword" name="Confirm Password" :password-toggle="true">
        <s-textbox type="password" v-model="form.passwordConfirmation" placeholder="Confirm Password" />
      </s-field-validate>
    </template>

    <template #footer="{ saveItem, saving }">
      <div>
        <s-button label="Submit" color="success" @click.native="saveItem" :loader="saving"></s-button>
      </div>
    </template>
  </item-form>
</template>

<script>
import changePassword from "@/graph/changePassword.gql";
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      fields: [
        {
          name: "currentPassword",
          value: "",
        },
        "newPassword",
        "passwordConfirmation",
      ],
    };
  },
  computed: {
    ...mapGetters({
      isAdminCreatedAccount: "user/isAdminCreatedAccount",
      isAdminVerifiedAccount: "user/isAdminVerifiedAccount",
    }),
    showCurrentPassword() {
      if (this.isAdminCreatedAccount && !this.isAdminVerifiedAccount) return false;
      else return true;
    },
  },
  methods: {
    change(id, data) {
      return this.$graph(changePassword, {
        input: data,
      }).then(() => {
        this.$shilp.notify({
          title: "Password Changed",
          type: "accent",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
