<template>
  <div class="" v-shilp-loader.overlay="isLoading">
    <header class="content-header">
      <h3 class="content-title-blue">Brands</h3>
    </header>
    <!-- <div>
      <main>
        <div class="brands-wrap">
          <div
            class="brands"
            v-for="(item, index) in brands"
            :key="'brand' + index"
            @click="viewProductsByBrand(item.id, item.name)"
          >
            <img
              v-if="item.logo && item.logo.url"
              :src="item.logo.url"
              alt="Brand"
            />
            <h4 class="brands__text bi-capitalize" v-if="item && item.name">
              {{ item.name }}
            </h4>
          </div>
        </div>
      </main>
    </div> -->
    <ul class="brands-wrap">
      <li class="brands" v-for="(item, index) in brands" :key="index" @click="viewProductsByBrand(item.id, item.name)">
        <div class="brands-container">
          <div class="bi-pb-2 bi-text-center bi-h-8">
            <Tag v-if="item.tag" :label="item.tag" />
          </div>
          <div class="logo-card">
            <img v-if="item.logo && item.logo.url" :src="item.logo.url" alt="Brand" />
          </div>
        </div>

        <h4 class="bi-mt-3 bi-block bi-text-center bi-capitalize bi-font-bold bi-w-full" v-if="item && item.name">
          {{ item.name }}
        </h4>
      </li>

      <li v-if="totalCount > brands.length" class="brands">
        <div class="bi-justify-center">
          <s-button label="Show More" theme="outline" color="secondary" rounded size="lg" @click.native="loadMoreBrands" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { get } from "@/api/brands";
import kycMixin from "@/mixins/kycMixin";
export default {
  mixins: [kycMixin],
  components: {
    Tag: require("@/components/Tag").default,
  },
  data() {
    return {
      brands: [],
      isLoading: false,
      totalCount: 0,
      limit: 6,
      page: 1,
    };
  },
  props: {
    categoryId: {
      type: [String, Number],
      default: null,
    },
  },
  created() {
    this.getBrands();
  },
  watch: {
    // this watch is used here because $root event was instantly getting called with old value of CategoryId instead of newValue.
    categoryId() {
      this.resetState();
      this.getBrands();
    },
  },
  methods: {
    resetState() {
      this.brands = [];
      this.totalCount = 0;
      this.page = 1;
    },
    loadMoreBrands() {
      if (this.brands.length < this.totalCount) {
        this.page++;
        this.getBrands(true);
      }
    },
    viewProductsByBrand(brandId, brandName) {
      if (this.isKycApproved()) {
        // this.$router.push({
        //   name: "product-index",
        //   params: {
        //     brandId: brandId,
        //     categoryId: this.categoryId,
        //     brandName: brandName,
        //   },
        // });

        this.$router.push({
          name: "product-list-by-brand",
          params: {
            id: brandId,
            categoryId: this.categoryId,
            brandName: brandName,
          },
        });
      } else {
        this.showKycStatusNotification();
      }
    },
    async getBrands(loadMore = false) {
      this.isLoading = true;
      try {
        const res = await get({
          limit: this.limit,
          page: this.page,
          filter: { category_id: this.categoryId },
          sort: {
            by: "sort_order",
            order: "asc",
          },
        });

        if (!loadMore) this.brands = [];
        this.brands.push(...res.data);
        this.totalCount = res.meta.total;
      } catch {
        this.$shilp.notify({
          title: "Something went wrong while getting Brands.",
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
