<template>
  <div class="bi-animate-pulse bi-mt-12">
    <div class="bi-p-4 bi-divide-y bi-divide-grey-lightest-double-ultra">
      <div
        v-for="i in 20"
        :key="`shimmer-${i}`"
        class="bi-flex bi-space-x-2 bi-py-2"
      >
        <p class="bi-w-24 bi-bg-grey-lightest bi-h-8 bi-rounded"></p>
        <p class="bi-w-96 bi-bg-grey-lightest bi-h-8 bi-rounded"></p>
        <p class="bi-w-48 bi-bg-grey-lightest bi-h-8 bi-rounded"></p>
        <p class="bi-w-36 bi-bg-grey-lightest bi-h-8 bi-rounded"></p>
        <p class="bi-flex-grow bi-bg-grey-lightest bi-h-8 bi-rounded"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
