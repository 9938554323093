<template>
  <!-- Please note do not change below css and style of div as it handles responsive design to show products list. When height or width is changed it gets scrollbar -->
  <div class="bi-p-4">
    <template v-if="isOrderLoading">
      <shimmer />
    </template>
    <template v-else>
      <portal to="header-actions">
        <!-- Cancel order option before order is confirmed from admin -->
        <div>
          <s-button
            label="Download PO"
            @click.native="download"
            color="secondary"
            :loader="isDownloading"
            class="bi-mr-2"
            v-if="order.po_download && order.orderStatus.id == 4"
          />
          <s-button
            label="Cancel Booking"
            color="secondary"
            @click.native="cancelOrder(order)"
            v-if="order.orderStatus.id == 1 && showCancelBtn && !order.is_order_confirmation_pending"
          />
        </div>
      </portal>

      <div class="bi-overflow-x-auto bi-p-4 bi-bg-grey-lightest-double-ultra">
        <OrderDetailsHeader :status="order.orderStatus" />
        <div>
          <OrderDetail :order="order" />
        </div>
      </div>
      <div
        class="bi-bg-white bi-w-full bi-py-2 bi-px-4 bi-shadow-2xl"
        :class="{ 'bi-sticky bi-bottom-11 bi-left-0 bi-right-36 bi-z-30': order.is_order_confirmation_pending }"
      >
        <div class="md:bi-flex bi-justify-between bi-p-3 bi-items-center" v-if="order.is_order_confirmation_pending">
          <div>
            <p class="md:bi-text-lg bi-text-sm bi-mb-2 md:bi-mb-0">Do you agree with this final price?</p>
            <p class="md:bi-text-lg bi-mb-2 md:bi-mb-0">
              <span class="bi-text-secondary-default bi-italic">Note:</span> Your order will be automatically confirmed on
              {{ calculateTimeAndHour(order.vendor_order_assigned_date, order.default_order_confirm_time) }}.
            </p>
            <label class="bi-flex">
              <input class="custom-size-input bi-mt-1" type="checkbox" name="Terms & Conditions" v-model="termsChecked" />
              <span class="bi-ml-2 md:bi-text-lg bi-font-medium font-display">
                I agree to the
                <b class="highlight-text bi-text-secondary-default">
                  <a href="https://builditindia.com/buyer-terms-of-use.html" target="_blank">Terms & Conditions.</a></b
                >
              </span>
            </label>
          </div>
          <div class="md:bi-mr-4 bi-flex">
            <s-button label="Cancel" @click.native="cancelOrder(order)" class="bi-w-56 mr-2" theme="outline" color="primary" />
            <s-button label="Confirm" @click.native="confirmOrder" :loader="isLoading" color="primary" class="bi-w-56" />
          </div>
        </div>

        <footer
          class="bi-border-t bi-border-grey-lightest-ultra bi-flex bi-flex-wrap bi-items-center bi-justify-between bi-pt-3 bi-px-3 bi-mb-9"
          :class="{ 'bi-mt-8': !order.is_order_confirmation_pending }"
        >
          <s-button label="Terms & Conditions" @click.native="openTerms()" theme="link" color="secondary" />

          <span class="bi-text-grey-default bi-mr-4">* Actual invoice amount may vary depending upon final price & shipped quantity.</span>
        </footer>
      </div>
    </template>
  </div>
</template>

<script>
import { getDetails, cancel, downloadPo, confirm } from "@/api/order.js";
import kycMixin from "@/mixins/kycMixin";
import orderStatusData from "@/data/orderStatus.js";
import moment from "moment";

export default {
  // order status id 1 => pending | 5 => on hold
  orderStatusData,
  cancelBookingRange: [1, 5],
  mixins: [kycMixin],
  components: {
    // OrderDetailFooter: require("@/components/order/OrderDetailFooter").default,
    Shimmer: require("@/components/order/Shimmer").default,
    // ProductCard: require("@/components/ProductCard").default,
    // CustomProductListHeading: require("@/components/order/CustomProductListHeading").default,
    OrderDetailsHeader: require("@/components/order/OrderDetailsHeader").default,
    OrderDetail: require("@/components/order/OrderDetail").default,
    // AdditionalChargesInfo: require("@/components/order/AdditionalChargesInfo").default,
  },
  props: ["id"],
  created() {
    this.getData();
  },
  computed: {
    showCancelBtn() {
      if (this.$options.cancelBookingRange.includes(this.order.orderStatus.id)) return true;
      else return false;
    },
  },
  data() {
    return {
      order: {},
      isOrderLoading: true,
      isDownloading: false,
      termsChecked: false,
      isLoading: false,
      isCancelLoading: false,
      params: {
        include:
          "invoice,products,products.brand,products.categories,products.attributeOptions.attribute,paymentTerms,project.deliveryIncharge, project,orderStatus,paymentStatus,orderProducts.orderProductStatus,orderProducts.orderProductPaymentStatus,orderProducts.product.attributeOptions.attribute",
      },
    };
  },
  methods: {
    openTerms() {
      window.open("https://builditindia.com/buyer-terms-of-use.html", "_blank");
    },
    download() {
      this.isDownloading = true;
      downloadPo(this.order.id)
        .then((res) => {
          window.open(res.url, "_blank");
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    cancelOrder(order) {
      this.isCancelLoading = true;
      this.$shilp.confirm({
        title: "Cancel Booking",
        message: "Are you sure you want to cancel this booking?",
        okLabel: "Yes",
        ok: ({ close }) => {
          cancel(order.id).then(() => {
            close();
            this.$router.push({ name: "orders" });
            this.hydrateUserWithCompanyType();
            this.isCancelLoading = false;
          });
        },
      });
    },
    getData() {
      getDetails(this.id, this.params)
        .then((res) => {
          this.order = res;
        })
        .catch((err) => {
          this.$shilp.notify({
            title: err.message || "Something went wrong. Try again",
            type: "danger",
          });
        })
        .finally(() => {
          this.isOrderLoading = false;
        });
    },
    calculateTimeAndHour(timestamp, defaultHour) {
      const momentA = moment.unix(timestamp);
      const result = momentA.add(defaultHour, "hours");
      // const resultTimestamp = result.unix();
      return result.format("MMM D YYYY, h:mm A");
    },
    confirmOrder() {
      if (!this.termsChecked) {
        this.$shilp.notify({
          title: "Please confirm Accept Terms & Conditions.",
          type: "danger",
        });
        return;
      }
      this.isLoading = true;
      const payload = {
        order_status_id: this.$options.orderStatusData.confirmed,
      };
      confirm(this.id, payload)
        .then(() => {
          this.$shilp.notify({
            title: "Order confirmed successfully",
            type: "success",
          });
          this.isLoading = false;
          this.getData();
        })
        .catch((err) => {
          this.isLoading = false;
          this.$shilp.notify({
            title: err.message || "Something went wrong. Try again",
            type: "danger",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
