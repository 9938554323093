export default {
  cement: [
    {
      id: 13,
      sort_order: 2,
      name: "OPC 43",
      label: "OPC 43",
      value: 13,
      created_by: 17,
      updated_by: null,
    },
    {
      id: 14,
      sort_order: 1,
      name: "OPC 53",
      label: "OPC 53",
      value: 14,
      created_by: 17,
      updated_by: null,
    },
    {
      id: 15,
      sort_order: 3,
      name: "PPC",
      label: "PPC",
      value: 15,
      created_by: 17,
      updated_by: null,
    },
  ],

  aac: [],

  tmt: [],
};
