import Vue from "vue";
import VueRouter from "vue-router";
import privateRoutes from "./private";

import store from "../store";
import { alertRouteChangeToBuyer } from "@/utils/common";
import { setToken } from "@/plugins/axios";
import { getToken, setToken as setTokenInLocalStorage } from "@/utils/common.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "index",
    component: require("@/views/public/Private").default,
    redirect: { name: "home" },
    meta: {
      public: false,
    },
    children: privateRoutes,
  },
  {
    path: "/login",
    name: "login",
    component: require("@/views/public/Login").default,
    meta: {
      public: true,
      label: "Login",
    },
    beforeEnter: (to, from, next) => {
      if (to.query && to.query.by_admin_id) {
        const token = to.query.token;
        sessionStorage.setItem("bi-token", token);
        setToken(token);
        sessionStorage.setItem("by_admin_id", to.query.by_admin_id);
        sessionStorage.setItem("company_id", to.query.company_id);

        next({ name: "home" });
      } else next();
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: require("@/views/public/Signup").default,
    meta: {
      public: true,
      label: "Sign Up",
    },
  },
  // TODO Update name of forget to forgot
  {
    path: "/forget-password",
    name: "forget-password",
    component: require("@/views/public/ForgetPassword").default,
    meta: {
      public: true,
      label: "Forgot Password",
    },
  },
];

const router = new VueRouter({
  routes,
});

// router before each method intercepts request before navigating user to url
// to is the destination url
// from is the source url
// next is the method for custom redirection
router.beforeEach(async (to, from, next) => {
  document.title = to.meta && to.meta.label ? to.meta.label + " | BuilditIndia" : "BuilditIndia";
  if (to.meta.public === true) {
    next();
  } else {
    // Check for admin login
    if (to.query.access_type == "whatsapp" && to.query.token) {
      // This code block is for supporting whatsapp ordering system
      const token = decodeURI(to.query.token);
      setTokenInLocalStorage(token);
      setToken(token);

      if (to.query.project_id) store.dispatch("user/setProjectId", Number(to.query.project_id));
    }
    const token = getToken();

    if (token) {
      if ((from.name == "product-list-by-brand" || from.name == "truck") && to.name !== "truck" && store.getters.totalNoOfProductsInCart) {
        // alertRouteChangeToBuyer(to, from, next);

        if (alertRouteChangeToBuyer()) {
          store.commit("user/setBuyerReadyToChangeRoute", true);
          next();
        } else store.commit("user/setBuyerReadyToChangeRoute", false);
      } else next();
    } else {
      next({ name: "login" });
    }
  }
});

router.afterEach((to, from) => {
  if (!["login", "signup", "help", "verify-account", "forget-password"].includes(to.name)) {
    localStorage.setItem("bi-last-path", to.path);
  }
});

// function alertRouteChangeToBuyer(routeTo, routeFrom, next) {
//   const isBuyerReadyToChangeRoute = confirm("You are about to move from this Page. Please confirm.");

//   if (isBuyerReadyToChangeRoute) {
//     store.commit("user/setBuyerReadyToChangeRoute", true);
//     next();
//   } else {
//     store.commit("user/setBuyerReadyToChangeRoute", false);
//   }
// }

export default router;
