<template>
  <div>
    <div class="layout__wrap">
      <sp-list
        ref="list"
        class="clickable"
        :perPage="10"
        :attrs="columns"
        :filters="filters"
        v-infinite-scroll="loadMoreProducts"
        infinite-scroll-distance="10"
        pagination-mode="infinite"
        endpoint="rest:products"
        sortBy="price"
        sortOrder="asc"
        :params="{
          sort_attribute_option_visible: true,
          include: 'brand,categories.attribute.attributeOption,attributeOptions.attribute',
        }"
        :actions="[]"
      >
        <template #empty>
          <list-empty />
        </template>
        <template #filters>
          <div class="bi-h-full">
            <div class="bi-flex bi-justify-between bi-items-center bb-lightest bi-h-13 bi-pl-4">
              <span class="bi-text-base bi-text-grey-default bi-block">Filters</span>

              <s-button icon="IconIcRoundClose" size="lg" color="gray" theme="link" @click.native="$refs.list.toggleSidebar('filters')" />
            </div>

            <div class="bi-p-4">
              <h5 class="bi-text-grey-default bi-mt-4 bi-mb-2">Brands</h5>

              <div class="bi-flex bi-flex-wrap bi-gap-2">
                <s-button
                  v-for="(brand, index) in brands"
                  :key="'brand' + index"
                  class="bi-py-3 filter bi-font-semibold"
                  :active="brandFilters.includes(brand.id)"
                  :label="brand.name"
                  theme="muted"
                  @click.native="applyBrandFilter(brand.id)"
                  color="grey"
                />
              </div>

              <!-- Attributes  -->
              <template v-if="category">
                <div v-for="(item, index) in category.attributes" :key="'attributeHeading' + index">
                  <h5 class="bi-text-grey-default bi-mt-4 bi-mb-2 bi-capitalize">
                    {{ item.name }}
                  </h5>

                  <div class="bi-flex bi-gap-2 filter-tags">
                    <!-- Below conditional class is applied to handle Design requirement , this is not a scalable solution -->
                    <!-- problem is size tags are not rendering according to design therefore applying inline design -->
                    <!-- Need more efficient solution for handling elements design -->
                    <s-button
                      v-for="(option, index) in item.attributeOption"
                      :key="'attributeOption' + index"
                      class="bi-py-4 filter bi-font-semibold"
                      :class="{
                        'aac-blocks-size-tag': item.name == 'Size' && categoryId == 3,
                      }"
                      :label="option.name"
                      theme="muted"
                      :active="attributeFilterId.includes(option.id)"
                      @click.native="applyAttributeFilter(option.id)"
                      color="grey"
                    />
                  </div>
                </div>
              </template>

              <s-button
                class="bi-mt-6 bi-font-semibold"
                size="lg"
                color="danger"
                theme="outline"
                label="Clear"
                icon="IconIcRoundClose"
                @click.native="resetFilters"
              />
            </div>
          </div>
        </template>
        <sp-list-table @rowClick="(item) => showProductsByBrand(item)">
          <template #brand="{ item }">
            <BrandLogo :brand="item.brand" v-if="item.brand && item.brand.logo" />
          </template>

          <template #product="{ item }">
            {{ item.name }}
          </template>

          <template #price="{ item }">
            <span class="bi-flex bi-items-start bi-gap-2">
              <AacBlocksPrice
                v-if="categoryNameFromHomeScreen == 'AAC Blocks'"
                :gstPercentage="getGstPercentage(item.attributeOptions)"
                :price="item.price"
                :unit="item.unit"
                :key="`product-price-${item.id}`"
              />
              <product-price v-else :price="item.price" :unit="item.unit" />
              <v-popover trigger="hover" v-if="categoryNameFromHomeScreen == 'AAC Blocks' && item.price">
                <template #popover>
                  <AacBlocksPriceBreakUp
                    :gstPercentage="getGstPercentage(item.attributeOptions)"
                    :gstAmount="getAacBlocksGstForEachProduct(item)"
                    :basePrice="item.price"
                  />
                </template>
                <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" class="flex" />
              </v-popover>
            </span>
          </template>

          <template #actions="{ item }">
            <div class="bi-text-center">
              <s-button size="md" color="success" theme="primary" label="Select" @click.native="showProductsByBrand(item)" />
            </div>
          </template>

          <!-- <template #actions="{ item }">
          <product-counter
            :key="item.id"
            :id="item.id"
            :price="item.price"
            :quantityOfOrder="getProductQuantity(item.id)"
          />
        </template> -->
        </sp-list-table>
      </sp-list>
    </div>
  </div>
</template>

<script>
// import { get } from "@/api/brands";
import { getAttributeValue, isAttributePresent, getGstPercentage, getGradeAttributeObject, getAacBlocksGstPrice } from "@/utils/common";
import { getBrands } from "@/utils/common";
import cartMixin from "@/mixins/cartMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [cartMixin],
  components: {
    // ProductCounter: require("@/components/ProductCounter").default,
    ProductPrice: require("@/components/ProductPrice").default,
    BrandLogo: require("@/components/brandLogo/Component.vue").default,
    AacBlocksPrice: require("@/components/AacBlocksPrice").default,
    AacBlocksPriceBreakUp: require("@/components/products/AacBlocksPriceBreakUp").default,
  },
  props: ["categoryId", "brandId", "attributeId", "brandName", "attributeName"],
  created() {
    if (this.brandId) {
      this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, this.brandName);
      this.brandFilters.push(this.brandId);
      this.filters.brand_id = this.brandId;
    }

    if (this.attributeId) {
      this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, this.attributeName);
      this.attributeFilterId.push(this.attributeId);
      // this.filters.attribute_option_id.push(this.attributeId);
      this.filters.attribute_option_id = this.attributeId;
    }

    this.setCategoryId(Number(this.categoryId));

    this.getAllFilterOptions();
  },
  data() {
    return {
      count: 0,
      products: {},
      brands: [],
      brandFilters: [],
      attributeFilterId: [],
      attributes: [],
      filters: {
        category_id: this.categoryId,
        brand_id: null,
        // attribute_option_id:[],
        attribute_option_id: null,
      },
    };
  },
  computed: {
    // Below columns is placed inside computed property so that it gets updated when we get data from api.
    // updating attributes column , toggling visilble property to handle if attribute is present or not.
    // updating value according to row data
    ...mapGetters({
      getCategoryDetail: "getCategoryDetail",
    }),
    category() {
      return this.getCategoryDetail(this.categoryId);
    },
    tableCurrentPage() {
      return this.$refs.list.$data.localPage;
    },
    tableLastPage() {
      return this.$refs.list.$data?.response?.pagination?.last_page;
    },
    columns() {
      return [
        { name: "brand", label: "Brand", fix: false, width: "100px" },
        { name: "product", label: "Product", fix: false, width: "200px" },
        {
          name: "grade",
          visible: isAttributePresent(this.category, "Grade"),
          value: (item) => getAttributeValue(item.attributeOptions, "Grade"),
          fix: false,
          width: "100px",
        },
        {
          name: "size",
          visible: isAttributePresent(this.category, "Size"),
          value: (item) => getAttributeValue(item.attributeOptions, "Size"),
          fix: false,
          width: "100px",
        },
        {
          name: "packing",
          visible: isAttributePresent(this.category, "Packing"),
          value: (item) => getAttributeValue(item.attributeOptions, "Packing"),
          fix: false,
          width: "100px",
        },
        { name: "price", sortable: true, fix: false, width: "150px" },
        {
          name: "actions",
          label: "Action",
          rowClick: false,
          fix: false,
          width: "200px",
        },
      ];
    },
  },
  watch: {
    // this watch is used to solve issue of not getting category on page reload , it takes time to hydrate from api therefore watching for response then setting
    category() {
      if (this.category && this.category.name) {
        // This code is to solve problem of when customer directly loads this page and vuex has different category state. then setting it to loaded category.
        this.setCategoryName(this.category.name);
      }
    },
  },
  methods: {
    ...mapActions(["setCategoryId", "setCategoryName"]),
    getGstPercentage,
    getAacBlocksGstPrice,
    getGradeAttributeObject,
    async getAllFilterOptions() {
      this.brands = await getBrands(this.categoryId);
    },
    getAacBlocksGstForEachProduct(product) {
      const result = this.getAacBlocksGstPrice(product.price, getGradeAttributeObject(product.attributeOptions).gst_percentage);
      return result;
    },
    getAacBlocksBasePriceForEachProduct(product) {
      // const result = this.getAacBlocksBasePrice(product.price, getGradeAttributeObject(product.attributeOptions).gst_percentage);

      return product.price;
    },

    loadMoreProducts() {
      // Code need to REFACTOR
      if (this.tableCurrentPage < this.tableLastPage) return this.$refs.list.loadMore();
    },
    resetFilters() {
      this.brandFilters = [];
      this.attributeFilterId = [];
      this.filters = {
        category_id: this.categoryId,
        brand_id: null,
        attribute_option_id: [],
      };
    },
    applyBrandFilter(id) {
      if (this.brandFilters.includes(id)) {
        this.brandFilters = this.brandFilters.filter((item) => {
          return id !== item;
        });
      } else {
        this.brandFilters.push(id);
      }

      this.filters.brand_id = this.brandFilters;
    },
    applyAttributeFilter(id) {
      if (this.attributeFilterId.includes(id)) {
        this.attributeFilterId = this.attributeFilterId.filter((item) => {
          return id !== item;
        });
      } else {
        this.attributeFilterId.push(id);
      }

      this.filters.attribute_option_id = this.attributeFilterId;
    },

    showProductsByBrand(item) {
      this.$router.push({
        name: "product-list-by-brand",
        params: {
          id: item.brand_id,
          categoryId: this.categoryId,
          brandName: item.brand.name,
          attributeId: this.attributeId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.filter-tags .button.aac-blocks-size-tag {
  flex: 0 0 48%;
  max-width: 48%;
}
</style>
