import $axios from "@/plugins/axios";

export const getAllAttributeOptions = (params = null) => {
  return $axios.get(`attributeOptions`, { params }).then((res) => {
    return res.data;
  });
};

export const getAttributeOptionDetail = (id, params = null) => {
  return $axios.get(`attributeOptions/${id}`, { params }).then((res) => res.data);
};
