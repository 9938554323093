import { graph } from "@/plugins/graphql";
import collection from "@/graph/collection";
import $axios from "@/plugins/axios";
import qs from "qs";
export default {
  requestHandler(data) {
    const { endpoint, filters, params, search, pagination, sort } = data;

    const endpointCheck = endpoint.split(":");

    const vars = {
      ...params,
      filter: filters,
      search,
      perPage: pagination.perPage,
      page: pagination.page,
    };

    if (sort && sort.by) {
      vars.sort = sort;
    }

    if (endpointCheck[0] == "rest") {
      return $axios
        .get(endpointCheck[1], {
          params: {
            ...vars,
          },
          paramsSerializer: (params) => qs.stringify(params),
        })
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          return {
            items: res.data,
            count: res.meta?.total,
            pagination: res.meta,
          };
        })
        .catch((error) => {
          console.log("eror ", error);
          // alert(error.message ? error.message : "Something went wrong.");
        });
    } else {
      return graph(collection[endpoint])(vars)
        .then((res) => {
          const keys = Object.keys(res);
          const queryRes = res[keys[0]];
          let items;
          items = queryRes.data;

          return {
            items,
            count: queryRes?.pagination?.total,
            //Original Response, usefull if we want to display additional data send via API
            //GraphQL can request additional data so can make use of them.
            res: res,
          };
        })
        .catch((error) => {
          // alert(error[0] && error[0].message);
        });
    }
  },
};
