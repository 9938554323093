<template>
  <div v-if="attributes">
    <template v-for="(attribute, index) in attributes">
      <div :key="'attributeHeading' + index" v-if="attribute.name">
        <header class="content-header">
          <h3 class="content-title-blue">{{ attribute.name }}</h3>
        </header>
        <main>
          <div class="brands-sizes-wrap">
            <s-button
              v-for="(option, index) in attribute.attributeOption"
              :key="'attributeOption' + index"
              color="primary"
              :label="option.name"
              class="hover:bi-text-white"
              @click.native="viewProductsByAttributes(option.id, option.name)"
              theme="outline"
            />
          </div>
        </main>
      </div>
    </template>
  </div>
</template>

<script>
import kycMixin from "@/mixins/kycMixin";
import { mapGetters } from "vuex";
export default {
  props: {
    categoryId: {
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      categories: "categories",
      getCategoryDetail: "getCategoryDetail",
    }),

    attributes() {
      const result = this.getCategoryDetail(this.categoryId);
      return result ? result.attribute : null;
    },
  },
  mixins: [kycMixin],

  methods: {
    viewProductsByAttributes(attributeId, attributeName) {
      if (this.isKycApproved()) {
        this.$router.push({
          name: "product-index",
          params: {
            attributeId: attributeId,
            categoryId: this.categoryId,
            attributeName,
          },
        });
      } else {
        this.showKycStatusNotification();
      }
    },
  },
};
</script>
