import Vue from "vue";
import { graphPublic, graph, apolloUpload, gql } from "@/plugins/graphql";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
Vue.component("DatePicker", DatePicker);

Vue.component("ItemForm", require("@/components/ItemForm").default);
Vue.component("Tag", require("@/components/Tag").default);
Vue.component("Error", require("@/components/Error").default);
Vue.component("Modal", require("@/components/Modal").default);
Vue.component("FileUpload", require("@/components/FileUpload").default);
Vue.component("ListEmpty", require("@/components/ListEmpty").default);
Vue.component("SubLayout", require("@/components/SubLayout").default);
Vue.component("Separator", require("@/components/Separator").default);

/**
 * Auto Register Icons
 */
const icons = require.context("./icons", true, /\.vue$/i);
icons.keys().map((key) => {
  const path = key.split("/");
  const name = path[path.length - 1].split(".")[0];
  return Vue.component("Icon" + name, icons(key).default);
});

/**
 * Prototypes
 */
Vue.prototype.$graphPublic = (query, variables) => graphPublic(query)(variables);

Vue.prototype.$graph = (query, variables) => graph(query)(variables);

Vue.prototype.$apolloUpload = () => apolloUpload;

Vue.prototype.$gql = (query) =>
  gql`
    ${query}
  `;
