var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('sp-list',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreHistory),expression:"loadMoreHistory"}],ref:"list",attrs:{"endpoint":"rest:signInHistories","pagination-mode":"infinite","attrs":_vm.columns,"params":_vm.params,"actions":[''],"infinite-scroll-distance":"10"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('list-empty')]},proxy:true}])},[_c('div',{staticClass:"bi-overflow-x-scroll"},[_c('sp-list-table',{staticStyle:{"min-width":"700px"},scopedSlots:_vm._u([{key:"user_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"bi-text-md"},[_vm._v(_vm._s(item.user_id))])]}},{key:"ip_address",fn:function(ref){
var item = ref.item;
return [(item.ip_address)?_c('span',{staticClass:"bi-text-md"},[_vm._v(_vm._s(item.ip_address))]):_c('data-not-found')]}},{key:"browser",fn:function(ref){
var item = ref.item;
return [(item.browser)?_c('span',{staticClass:"bi-text-md"},[_vm._v(_vm._s(item.browser))]):_c('data-not-found')]}},{key:"in_time",fn:function(ref){
var item = ref.item;
return [(item.in_time)?_c('span',{staticClass:"bi-text-md"},[_vm._v(_vm._s(_vm.formatDate(item.in_time)))]):_c('data-not-found')]}},{key:"out_time",fn:function(ref){
var item = ref.item;
return [(item.out_time)?_c('span',{staticClass:"bi-text-md"},[_vm._v(_vm._s(_vm.formatDate(item.out_time)))]):_c('data-not-found')]}}])})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bi-ml-4"},[_c('h3',{staticClass:"bi-text-primary-default"},[_vm._v("Sign In History")]),_c('span',{staticClass:"bi-text-grey-default bi-mb-2"},[_vm._v("You can see your history here.")])])}]

export { render, staticRenderFns }