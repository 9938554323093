import $axios from "@/plugins/axios";

export const get = (params = null) => {
  return $axios.get("products", { params }).then((res) => {
    return res.data;
  });
};
// ?include=brand,categories,attributeOptions,attributeOptions.attribute"

export const getDetails = (id, params = null) => {
  return $axios.get(`products/${id}`, { params }).then((res) => {
    return res.data;
  });
};
