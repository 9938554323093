<template>
  <div :class="`sm:bi-w-${width}`" class="bi-bg-info-lightest bi-p-4 bi-rounded bi-w-full">
    <!-- Content -->
    <h3>{{ title }}</h3>

    <p class="bi-text-grey-default bi-text-md">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "100",
    },
    title: {
      type: String,
      default: "Please Note",
    },
    message: {
      type: String,
      default: "Message",
    },
  },
};
</script>

<style lang="scss" scoped></style>
