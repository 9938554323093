<template>
  <div class="" v-if="order && order.products.length">
    <div>
      <h4 class="bi-text-primary-default bi-text-base bi-font-normal">{{ order.order_no || "#" + order.id }}</h4>
    </div>

    <div>
      <span class="bi-text-grey-darkest-ultra bi-text-sm bi-font-medium">
        {{ formatDate(order.created_at) }}
      </span>
    </div>

    <!-- Brand  -->
    <h4 class="bi-text-grey-darkest-ultra bi-flex bi-items-center bi-gap-2">
      <BrandLogo :brand="order.products.length && order.products[0].brand" />
    </h4>

    <!-- Category  -->
    <h4 class="bi-text-grey-darkest-ultra bi-text-base bi-font-normal">
      {{ order.products[0].categories[0].name }}
    </h4>

    <!-- Order status  -->
    <div v-if="showOrderStatus" class="bi-flex bi-items-center">
      <order-status-tag :label="order.orderStatus.display_name" :name="order.orderStatus.name" /><span
        v-if="order.is_auto_confirm"
        class="bi-text-grey-default bi-ml-1"
        >(Auto)</span
      >
    </div>

    <!-- Payment Status -->
    <div>
      <payment-status-tag :label="order.paymentStatus.display_name" :name="order.paymentStatus.name" />
    </div>

    <!-- total Quantity of Order  -->
    <div>
      <!-- <span class="bi-text-grey-darkest-ultra bi-text-sm bi-font-medium"
        >{{ getTotalQuantity(order.products) }} {{ getUnit(order.products[0]) }}</span
      > -->

      <span class="bi-text-grey-darkest-ultra bi-text-sm bi-font-medium"> {{ order.total_quantity }}</span>
    </div>

    <!-- <div class="bi-flex bi-justify-between bi-gap-2 bi-items-center"> -->
    <!-- Total Price of Order -->
    <div class="bi-flex bi-gap-2" style="width: 120px">
      <TotalPrice :price="order.total" classList="bi-text-grey-darkest-ultra" />
      <v-popover trigger="hover" popoverWrapperClass="bi-w-100" placement="left" popoverBaseClass="tooltip popover price-breakup-popover">
        <template #popover>
          <PriceBreakUp :gstAmount="order.gst_total_amount" :basePrice="order.total - order.gst_total_amount" />
        </template>
        <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
      </v-popover>
    </div>

    <div v-if="showAction">
      <s-button
        label="Details"
        color="secondary"
        v-tooltip="`See Order Details`"
        rounded
        @click.native="
          $router.push({
            name: 'order-details',
            params: { id: order.id },
          })
        "
      />
    </div>
    <div v-else>
      <div v-if="order.invoices.length" class="bi-flex bi-items-center">
        <s-button icon="IconMdiAttachment" shape="circle" theme="muted" size="lg" :color="order.invoices.length ? 'success' : 'grey'" />
      </div>
      <div v-else-if="$options.orderStatusData.delivered === order.order_status_id" class="bi-flex bi-items-center">
        <s-button
          size="lg"
          theme="muted"
          shape="circle"
          color="grey"
          icon="IconMdiRequest"
          v-tooltip="'Request For Invoice'"
          @click.native="
            $root.$emit('shilp-modal-open', {
              id: 'invoice-request-modal',
              scope: {
                orderId: order.id,
              },
            })
          "
        />
      </div>
    </div>
    <InvoiceRequestModal @invoice-request="requestInvoice" />
  </div>
</template>

<script>
import orderStatusData from "@/data/orderStatus";
import cartMixin from "@/mixins/cartMixin";
import { formatDate } from "@/utils/common.js";
import { inquiry } from "@/api/inquiry";
export default {
  orderStatusData,
  mixins: [cartMixin],
  components: {
    BrandLogo: require("@/components/brandLogo/Component.vue").default,
    TotalPrice: require("@/components/TotalPrice").default,
    PaymentStatusTag: require("@/components/order/PaymentStatusTag").default,
    OrderStatusTag: require("@/components/order/OrderStatusTag").default,
    // AacBlocksPrice: require("@/components/AacBlocksPrice").default,
    PriceBreakUp: require("@/components/products/PriceBreakUp").default,
    InvoiceRequestModal: require("@/components/invoice/InvoiceRequestModal").default,
  },

  props: {
    order: Object,
    showOrderStatus: {
      type: Boolean,
      default: true,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
  },
  created() {},
  computed: {},
  methods: {
    formatDate,
    requestInvoice(data) {
      inquiry(data.orderId, { additional_requirement: data.text ? data.text : "description", inquiry_type: 4 })
        .then((res) => {
          this.$shilp.notify({
            type: "success",
            title: "Invoice Inquiry sent.",
          });
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss">
.tooltip.price-breakup-popover {
  max-width: max-content;
}
</style>
