<template>
  <div class="bi-flex bi-items-center bi-gap-2">
    <dropdown
      :key="dropdownKey"
      class="my-dropdown-toggle bi-flex-grow"
      :placeholder="placeholder"
      :options="options"
      :selected="selectedOption"
      @updateOption="setValue"
    >
    </dropdown>
    <s-button v-if="clearable" shape="circle" icon="IconIcRoundClose" color="danger" theme="outline" size="sm" @click.native="emit" />
  </div>
</template>

<script>
import dropdown from "vue-dropdowns";

export default {
  props: ["options", "value", "placeholder", "clearable"],
  components: {
    Dropdown: dropdown,
  },
  data() {
    return {
      selectedOption: {},
      dropdownKey: 1,
      filteredGradeArray: [],
    };
  },

  created() {
    if (this.options.length && this.value) {
      // FIXME Pre load value not working in Project Step 2 select designation
      this.setDefaultOption(this.value);
    }
  },
  methods: {
    emit() {
      this.dropdownKey++;
      this.$emit("clear-selection");
    },
    setValue(selectedOptionObject) {
      this.$emit("input", selectedOptionObject.value);
    },
    setDefaultOption(value) {
      this.filteredArray = this.options.filter((item) => {
        return item.value == value;
      });

      if (this.filteredArray.length) this.selectedOption = this.filteredArray[0];
    },
  },
};
</script>

<style lang="scss">
.my-dropdown-toggle {
  border-radius: 5px;

  ::v-deep .dropdown-toggle {
    color: tomato;
    font-size: 25px;
    font-weight: 800;
  }

  ::v-deep .dropdown-toggle-placeholder {
    color: #c4c4c4;
  }
}
</style>
