export default [
  {
    path: "kyc",
    name: "complete-kyc",
    component: require("@/views/kyc/Index").default,
    meta: {
      label: "Complete Your KYC",
      breadcrumbs: ["home", "complete-kyc"],
    },
    redirect: { name: "upsert-company" },
    children: [
      {
        path: "company/:id",
        name: "upsert-company",
        props: true,
        component: require("@/views/kyc/UpsertCompany").default,
        meta: {
          icon: "IconMdiHomeOutline",
          label: "Company",
          breadcrumbs: ["home", "upsert-company"],
        },
      },
      {
        path: "document/:id",
        name: "upsert-document",
        props: true,
        component: require("@/views/kyc/UpsertDocument").default,
        meta: {
          icon: "IconIcSharpUploadFile",
          label: "Documents",
          breadcrumbs: ["upsert-company", "upsert-document"],
        },
      },
      {
        path: "partner/:id",
        name: "upsert-partner",
        props: true,
        component: require("@/views/kyc/UpsertPartner").default,
        meta: {
          icon: "IconMdiHumanMaleMale",
          label: "Partners",
          breadcrumbs: ["upsert-document", "upsert-partner"],
        },
      },
    ],
  },
];
