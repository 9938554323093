<template>
  <!-- Below left classlist sticky class is used to solve issue of border getting scolled when content is more. -->
  <s-layout
    ref="subLayout"
    class="bi-w-full bi-h-full custom-style-layout"
    :left-size="leftBarVisible ? '250px' : '0'"
    :left-classlist="['br-lightest', 'bi-hidden', 'md:bi-block', 'left-classlist', 'bi-sticky', 'bi-top-0']"
    :top-size="windowWidth < 768 && showMobileBar ? '50px' : '0'"
    :top-classlist="['bi-bg-grey-lightest-double-ultra bi-overflow-hidden bi-px-2 bi-mb-4', 'md:bi-hidden']"
    :right-classlist="['bi-hidden', 'md:bi-block']"
    :bottom-classlist="['sub-layout-bottom']"
    :content-classlist="[contentClasslist, 'bi-overflow-auto']"
  >
    <template #top>
      <slot name="mobile-menu"></slot>
    </template>

    <template v-if="sidebar" #left>
      <slot name="menu" />
    </template>

    <template #default>
      <slot>
        <router-view></router-view>
      </slot>
    </template>
    <template #bottom>
      <slot name="bottom"></slot>
    </template>

    <template #right>
      <slot name="right-side"></slot>
    </template>
  </s-layout>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  props: {
    sidebar: {
      type: Boolean,
      default: true,
    },
    showMobileBar: {
      type: Boolean,
      default: true,
    },
    contentClasslist: Array,
    // compact: {
    //   type: Boolean,
    //   default: false,
    // },
    leftBarVisible: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },

  components: {},
};
</script>

<style lang="scss">
.custom-style-layout {
  display: block !important;
}
.sub-layout-bottom {
  z-index: 9999;
  position: fixed;
  left: $sub-layout-left-bar-width;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .custom-style-layout {
    display: grid !important;
  }
}

@media screen and (max-width: 768px) {
  .sub-layout-bottom {
    left: 0;
  }
}

.sub-layout-sidebar ul {
  @apply bi-sticky bi-top-0 bi-bottom-0 bi-left-0 bi-right-0 bi-mt-0;
}

.left-classlist {
  height: calc(100vh - 136px);
}
</style>
