<template>
  <div class="bi-sticky bi-inset-0">
    <s-nav class="bi-my-4" :fluid="!compact" stack align="left" :shape="compact ? 'square' : null">
      <s-nav-item class="account-sidebar-item" label="Company Details" :to="{ name: 'company-detail' }" />

      <s-nav-item label="Account & Security" class="account-sidebar-item" :to="{ name: 'my-profile' }" v-if="!getAdminId()" />
      <!-- <s-nav-item label="Change Password" :to="{ name: 'change-password' }" class="account-sidebar-item" /> -->
      <s-nav-item label="Sign In History" :to="{ name: 'sign-in-history' }" class="account-sidebar-item" />

      <s-nav-item
        label="Inquiry"
        :to="{ name: 'inquiry-section' }"
        class="account-sidebar-item"
        :class="{ 'nav__item--active nav__item--exact-active': $route.meta.parent == 'inquiry' }"
      />
      <!-- v-if="isKycApproved()" -->
      <!-- Below class binding of active class is used since we are using click event instead of to prop -->
      <s-nav-item
        label="Credits"
        class="account-sidebar-item"
        @click.native="goToCredits"
        :class="{ 'nav__item--active nav__item--exact-active': $route.name == 'credit-section' }"
      />
      <s-nav-item
        class="account-sidebar-item"
        v-if="status == 'active'"
        label="Dectivate Account"
        @click.native="$shilp.modal.open('deactivate-account-modal')"
      />
      <!-- Privacy Policy -->
      <s-nav-item label="Privacy Policy" class="account-sidebar-item" :to="{ name: 'privacy-policy' }" />
      <!-- Terms and Conditions  -->
      <s-nav-item label="Terms & Conditions" class="account-sidebar-item" :to="{ name: 'terms-conditions' }" />

      <s-nav-item class="account-sidebar-item" label="Logout" @click.native="$shilp.modal.open('log-out-account-modal')" />
    </s-nav>
  </div>
</template>

<script>
// REFACTOR Make wrapper component for this child and pass menu items to it/.
// I Tried Refactoring , passed menu array from parent to child but Problem i faced was related to click events and conditionally showing elements.
// Therefore No Refactoring can be done here.

import { mapGetters } from "vuex";
import kycMixin from "@/mixins/kycMixin";
import { getAdminId } from "@/utils/adminOrder";
export default {
  mixins: [kycMixin],
  data() {
    return {
      compact: false,
    };
  },
  computed: {
    ...mapGetters({
      status: "user/status",
    }),
  },
  methods: {
    getAdminId,
    goToCredits() {
      if (this.isKycApproved()) this.$router.push({ name: "credit-section" });
      else this.showKycStatusNotification();
    },
  },
};
</script>

<style lang="scss">
.account-sidebar-item {
  @apply hover:bi-bg-secondary-lightest-double-ultra bi-my-2;
  &.nav__item--exact-active {
    @apply bi-bg-secondary-lightest-double-ultra bi-border-r bi-border-secondary-default;
    .button {
      @apply bi-text-base-lg bi-font-semibold;
    }
  }
  .button {
    @apply bi-pl-6 bi-text-base-lg bi-capitalize bi-text-grey-darkest-ultra  bi-font-normal;
  }
}
</style>
