<template>
  <div class="bi-flex bi-justify-center bi-py-6 lg:bi-mx-auto">
    <ul class="progress bi-flex bi-grow bi-text-md bi-pb-4 bi-justify-around bi-relative">
      <li :class="{ active: activeRoutes.includes('upsert-company') }">
        <a>
          <span class="icon-box">
            <IconMdiHomeOutline />
          </span>
          <span style="flex: 0 0 100%; font-size: 16px">Company</span>
        </a>
      </li>
      <li :class="{ active: activeRoutes.includes('upsert-document') }">
        <a>
          <span class="icon-box">
            <IconIcSharpUploadFile />
          </span>
          <span style="flex: 0 0 100%; font-size: 16px">Documents</span>
        </a>
      </li>
      <li :class="{ active: activeRoutes.includes('upsert-partner') }">
        <a>
          <span class="icon-box">
            <IconMdiHumanMaleMale />
          </span>
          <span style="flex: 0 0 100%; font-size: 16px">{{ partnerOrOwnerLabel }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      routeSequence: ["upsert-company", "upsert-document", "upsert-partner"],
    };
  },
  computed: {
    ...mapGetters({
      companyType: "user/companyType",
    }),
    companyTypeLabel() {
      return this.companyType.displayName;
    },
    partnerOrOwnerLabel() {
      if (this.companyTypeLabel == "Proprietorship") return "Owner";
      else return "Partner";
    },
    activeRoutes() {
      let routes = [];

      for (let route of this.routeSequence) {
        if (route == this.$route.name) {
          routes.push(route);
          break;
        } else routes.push(route);
      }

      return routes;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-box {
  content: "";
  border-radius: 50%;
  background: #b0bec5;
  width: 64px;
  height: 64px;
  display: block;
  margin: 6px auto;
  bottom: -30px;
  left: 0;
  right: 0;
}

.icon-box {
  svg {
    @apply bi-mx-4 bi-my-2.5 bi-h-10 bi-w-8 bi-text-white;
  }
}

.progress li.active {
  .icon-box {
    @apply bi-bg-success-default;
  }

  .icon-box.highlight-danger {
    @apply bi-bg-danger-default;
  }
}

.progress li.active::before {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  color: #fff;
  @apply bi-bg-success-default;
  position: absolute;
  left: -150px;
  right: 0;
  top: 40px;
  bottom: auto;
  margin: 0 auto;
  z-index: 9;
}

@media (min-width: 1440px) {
  .progress li.active::before {
    left: -280px;
  }
}

.progress li.active.highlight-danger::before {
  @apply bi-bg-danger-default;
}

.progress {
  @apply bi-w-10/12 lg:bi-max-w-5xl;
  height: 95px;
}

.progress::after {
  content: "";
  display: block;
  width: 65%;
  height: 6px;
  color: #fff;
  background: #b0bec5;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: auto;
  margin: 0 auto;
}

.progress li.active:first-child::before {
  background: none;
  width: 0;
}

.progress li {
  flex: 1;
  position: relative;
  padding: 0px 10px;
  text-align: center;
}

.progress li a {
  display: flex;
  height: 100%;
  padding-bottom: 30px;
  margin-bottom: -35px;
  position: relative;
  z-index: 30;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0px;
  @apply sm:bi-text-xl;
}
</style>
