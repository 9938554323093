<template>
  <div>
    <s-field-validate name="Signature OTP" class="otp-field" :class="classList">
      <s-row :gap="2">
        <s-column :size="6">
          <s-button
            fluid
            :label="label"
            :loader="loading"
            size="lg"
            :disabled="otpDisable"
            color="success"
            @click.native="getOtp"
            class="bi-text-white"
          />
        </s-column>
        <s-column :size="6">
          <!-- <s-field-validate name="OTP" rules="required" v-slot="{ invalid }"> -->
          <!-- <input class="field-block" :value="value" placeholder="OTP" @input="input" type="number" /> -->
          <ValidationProvider v-slot="{ invalid }" rules="required" name="code" ref="codeValidator">
            <OtpInput :digits="4" @on-complete="onCompleteHandler" @on-changed="onChangeHandler" :isValid="!invalid" name="OTP" :key="otpInputKey">
            </OtpInput>
          </ValidationProvider>
          <!-- </s-field-validate> -->
        </s-column>
        <s-column :size="12" v-if="interval && timeDuration">
          <timer
            dayText=""
            hourText=""
            minutesText=""
            @endTimer="setTimer"
            endlabel="Resend OTP in"
            :startTime="interval * 1000"
            :endTime="timeDuration * 1000"
          />
        </s-column>
      </s-row>
    </s-field-validate>

    <s-field label="Send OTP to Main Partner" inline v-if="showSendToPartnerField" class="bi-mt-4 bi-text-lg bi-font-medium">
      <s-switch
        id="send-to-partner-switch"
        :on-value="true"
        size="sm"
        :off-value="false"
        shape="pill"
        color="primary"
        v-model="sendToDefaultPartner"
      />
    </s-field>
  </div>
</template>
<script>
import { add } from "date-fns";
import OtpInput from "otp-input-vue2";
export default {
  props: {
    showSendToPartnerField: {
      type: Boolean,
      default: false,
    },
    value: [String, Number],
    get: Function,
    reset: Boolean,
    classList: {
      type: Array,
      default: () => ["bi-mt-6"],
    },
  },
  components: {
    Timer: require("@/components/Timer").default,
    OtpInput,
  },
  data() {
    return {
      loading: false,
      otpDisable: false,
      timeDuration: 0,
      label: "Send OTP",
      interval: 0,
      code: "",
      sendToDefaultPartner: false,
      otpInputKey: 0,
    };
  },
  mounted() {
    this.$emit("mounted");
  },
  watch: {
    reset(newValue) {
      this.resetOtp();
      this.otpInputKey++;
    },
  },
  methods: {
    setTimer() {
      this.interval = 0;
      this.timeDuration = 0;
      this.otpDisable = false;
    },
    toTimestamp(strDate) {
      let datum = Date.parse(strDate);
      return datum / 1000;
    },
    resetOtp() {
      this.otpDisable = false;
      this.timeDuration = 0;
      this.label = "Send OTP";
      this.interval = 0;
    },
    async getOtp() {
      this.loading = true;

      let params = null;
      if (this.showSendToPartnerField)
        params = {
          defaultPartnerOtpSend: this.sendToDefaultPartner,
        };
      try {
        let res = await this.get(params);
        // Temporary disabling auto emiting code from api response
        // this.$emit("input", code);
        this.otpDisable = true;
        this.label = "Resend OTP";
        this.timeDuration = this.toTimestamp(
          add(new Date(), {
            minutes: 1,
          })
        );
        this.interval = this.toTimestamp(new Date());
        this.$shilp.notify({
          title: res.message ? res.message : "OTP Sent. Please enter the code.",
          type: "accent",
        });
      } catch (error) {
        if (error && error.length && error[0].message) {
          this.$shilp.notify({
            title: error[0].message,
            type: "danger",
          });
        }
        this.otpDisable = false;
      } finally {
        this.loading = false;
      }
    },
    onCompleteHandler(code) {
      this.$refs.codeValidator.validate(code);
      this.code = code;
      this.$emit("input", code);
    },

    onChangeHandler() {
      this.code = null;
      this.$emit("input", this.code);
    },
    // input(e) {
    //   this.$emit("input", e.target.value);
    // },
  },
};
</script>
