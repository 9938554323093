<template>
  <div class="v-list h-100 order-list v-list--sidebar">
    <div
      class="v-list__sidebar"
      :style="visible ? 'display : block;' : 'display: none'"
    >
      <filter-sidebar-header @filterBarClose="$emit('filter-bar-close')" />
      <div class="bi-h-full bi-p-4">
        <slot></slot>
        <filter-footer @resetFilters="$emit('reset-filters')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    FilterSidebarHeader: require("@/components/tableFilter/FilterSidebarHeader")
      .default,
    FilterFooter: require("@/components/tableFilter/Footer").default,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
