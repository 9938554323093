<template>
  <!-- Container -->
  <div class="bi-border-t bi-rounded-2xl bi-border-grey-lightest-ultra bi-bg-grey-lightest-double-ultra bi-fixed bi-bottom-12 bi-left-0 bi-right-0">
    <div class="bi-flex bi-bg-red-100 bi-p-2 bi-rounded-2xl">
      <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5 bi-mr-2" name="IconMdiInformationOutline" />
      The prices shown here are reference prices and you will be able to confirm the final order price.
    </div>
    <!-- REFACTOR Make component for this below html -->
    <div :class="{ 'bi-h-0 bi-invisible': !showPaymentTermsBox, 'xl:bi-h-48': showPaymentTermsBox }" class="bi-transition-all bi-overflow-auto">
      <!-- Payment Terms Box Collapsible  -->

      <header class="bi-flex bi-justify-end bi-items-center bi-h-14 bi-border-b bi-border-grey-lighter bi-px-4">
        <!-- <span class="bi-text-xl bi-text-grey-default">Payment Terms</span> -->

        <!-- Close btn -->
        <s-button
          icon="IconIcRoundClose"
          size="sm"
          color="grey"
          shape="circle"
          theme="outline"
          @click.native="showPaymentTermsBox = !showPaymentTermsBox"
        />
      </header>
      <main class="bi-flex bi-flex-wrap xl:bi-flex-nowrap sm:bi-gap-4 lg:bi-gap-8 xl:bi-gap-16 bi-items-start bi-p-4 bi-justify-end">
        <div class="bi-px-4 bi-py-2 md:bi-border-r md:bi-border-grey-light bi-flex bi-gap-4">
          <!-- Delivery Days and expected delivery data -->

          <p class="bi-text-grey-default bi-text-md sm:bi-text-lg">
            Expected delivery by: <span class="bi-font-bold bi-text-grey-darkest-ultra">{{ expectedDeliveryDate }}</span>
          </p>
        </div>

        <div class="bi-px-4 bi-flex bi-flex-wrap bi-justify-end xl:bi-flex-nowrap bi-gap-2 md:bi-border-r md:bi-border-grey-light">
          <!-- Payment Options  -->
          <header class="bi-text-grey-default bi-text-md sm:bi-text-lg bi-pt-2">Select Payment Options:</header>

          <main>
            <AacBlocksPaymentOptions
              v-if="categoryNameInCart == 'AAC Blocks'"
              :options="paymentOptions"
              @selected="setPaymentOption"
              :unit="unitFromCart"
            />
            <TmtBarPaymentOptions
              v-if="categoryNameInCart == 'TMT Bar'"
              :options="paymentOptions"
              @selected="setPaymentOption"
              :unit="unitFromCart"
            />
            <CementPaymentOptions v-if="categoryNameInCart == 'Cement'" :options="paymentOptions" @selected="setPaymentOption" />
          </main>
        </div>

        <div class="bi-py-2 bi-flex bi-flex-wrap bi-gap-2 lg:bi-flex-nowrap bi-items-center bi-justify-end">
          <LoadingOption :loading="brandLoadingOption" class="bi-border-r bi-border-grey-light bi-px-2 md:bi-px-4" />

          <UnloadingOption :unloading="brandUnloadingOption" class="bi-border-r bi-border-grey-light bi-px-2 md:bi-px-4" />

          <FreightOption :freight="brandFreightOption" class="bi-px-2 md:bi-px-4" />
        </div>
      </main>
      <p class="bi-text-grey-default bi-text-right bi-px-4 bi-font-semibold">*Credit is subject to approval as per Payment Terms</p>
    </div>
    <div class="bi-p-4 order-section bi-mt-0 xl:bi-flex bi-justify-between bi-items-center">
      <!-- Left section -->
      <div class="bi-mb-3 xl:bi-mb-0">
        <div v-if="!showPaymentTermsBox">
          <label class="bi-flex">
            <input class="custom-size-input bi-mt-1" type="checkbox" v-model="isAddressConfirmed" />
            <span class="bi-ml-2 bi-text-grey-default bi-text-sm sm:bi-text-lg md:bi-text-xl bi-font-medium">
              I confirm that added items are reviewed and selected under the <b class="highlight-text">correct project</b> and its
              <b class="highlight-text">address.</b>
            </span>
          </label>
          <label class="bi-flex bi-mt-2">
            <input class="custom-size-input bi-mt-1" type="checkbox" name="Terms & Conditions" v-model="termsChecked" />
            <span class="bi-ml-2 bi-text-grey-default bi-text-sm sm:bi-text-lg md:bi-text-xl bi-font-medium font-display">
              I agree to the
              <b class="highlight-text"> <a href="https://builditindia.com/buyer-terms-of-use.html" target="_blank">Terms & Conditions.</a></b>
            </span>
          </label>
        </div>

        <div v-if="showPaymentTermsBox">
          <label class="bi-flex">
            <input class="custom-size-input bi-mt-1" type="radio" checked="checked" />
            <div class="bi-flex">
              <p class="bi-ml-2 bi-text-black bi-text-sm sm:bi-text-lg md:bi-text-xl bi-font-medium bi-mr-5">Pay Via BII Credit</p>
              <p
                class="bi-font-medium bi-text-sm sm:bi-text-lg md:bi-text-xl"
                :class="availableCredit > 0 ? 'bi-text-success-default' : 'bi-text-danger-default'"
              >
                {{ formatPrice(availableCredit) }}
              </p>
            </div>
          </label>
          <label class="bi-flex mt-2">
            <input class="custom-size-input bi-mt-1" type="radio" checked="checked" disabled />
            <p class="bi-ml-2 bi-text-grey-lighter bi-text-sm sm:bi-text-lg md:bi-text-xl bi-font-medium">Pay Via Lender(Coming Soon)</p>
          </label>
        </div>
      </div>

      <!-- Right Section -->
      <div
        class="bi-flex bi-flex-wrap md:bi-flex-nowrap xl:bi-justify-between bi-items-center bi-gap-1 sm:bi-gap-4 md:bi-gap-8 lg:bi-gap-16 bi-mt-4 md:bi-mt-0"
      >
        <div
          class="custom-div-gap bi-flex bi-flex-wrap md:bi-flex-nowrap sm:bi-justify-between bi-gap-8 md:bi-gap-16 lg:bi-gap-32 xl:bi-gap-20 bi-items-center bi-mb-2 bi-w-full sm:bi-w-auto custom-div-align"
        >
          <!-- Total Quantity -->
          <div class="bi-text-right bi-border-r bi-border-grey-lighter bi-pr-4">
            <span class="bi-block bi-pl-2.5 md:bi-text-2xl bi-text-md bi-text-grey-darkest-ultra bi-font-display bi-font-bold bi-min-w-max">
              {{ totalQuantity }} {{ unitFromCart }}
            </span>
            <span class="bi-block bi-min-w-max bi-text-xs md:bi-text-sm bi-text-grey-darkest-ultra bi-font-display bi-font-normal">
              Total Quantity
            </span>
          </div>

          <!-- Category  -->
          <div class="bi-text-right bi-border-r bi-border-grey-lighter bi-pr-4">
            <span class="bi-block bi-min-w-max bi-text-md md:bi-text-2xl bi-text-grey-darkest-ultra bi-font-display bi-font-bold">
              {{ categoryNameInCart }}
            </span>
            <span class="bi-block bi-text-xs md:bi-text-sm bi-text-grey-darkest-ultra bi-font-display bi-font-normal"> {{ brandNameFromCart }}</span>
          </div>
          <!-- <div class="bi-flex bi-justify-between bi-flex-nowrap  g-7 bi-items-center"> -->

          <!-- Total Price -->
          <CartTotalPrice />
        </div>

        <div class="bi-w-full sm:bi-w-auto">
          <!-- Show Meta Data button -->
          <s-button
            label="Booking Request"
            style="min-width: 160px"
            v-tooltip="'Show Order Details'"
            color="primary"
            fluid
            size="lg"
            @click.native="showMetaData"
            v-if="!showPaymentTermsBox"
          />
          <!-- Request order button  -->
          <s-button
            label="Get me a best quote"
            color="primary"
            fluid
            size="lg"
            @click.native="requestOrder"
            :loader="isLoading"
            v-if="showPaymentTermsBox"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cartMixin from "@/mixins/cartMixin";
import { create } from "@/api/order";
import { mapGetters, mapMutations } from "vuex";
import { get } from "@/api/product";
import { getDetail as getBrandDetail } from "@/api/brands";
import moment from "moment";
import { formatDateForBackEnd, formatPrice } from "@/utils/common.js";
import hydrateQuery from "@/graph/hydrate.gql";
import { getCompanyId, getAdminId } from "@/utils/adminOrder";
export default {
  props: ["filters", "params"],
  components: {
    CartTotalPrice: require("@/components/cart/CartTotalPrice").default,
    AacBlocksPaymentOptions: require("@/components/paymentOptions/AacBlocks").default,
    TmtBarPaymentOptions: require("@/components/paymentOptions/TmtBar").default,
    CementPaymentOptions: require("@/components/paymentOptions/Cement").default,
    LoadingOption: require("@/components/order/options/Loading").default,
    UnloadingOption: require("@/components/order/options/Unloading").default,
    FreightOption: require("@/components/order/options/Freight").default,
  },
  mixins: [cartMixin],
  data() {
    return {
      isAddressConfirmed: false,
      termsChecked: false,
      isLoading: false,
      truckItemsFromApi: [],
      expectedDeliveryDays: null,
      showPaymentTermsBox: false,
      paymentTermId: null,
      paymentTermAmount: null,
    };
  },
  created() {
    this.getDetails();

    this.$root.$once("place-order", (instruction) => {
      this.placeOrder({ additional_instruction: instruction });
    });
  },
  beforeDestroy() {
    this.$root.$off("place-order");
  },
  computed: {
    ...mapGetters({
      projectId: "user/getProjectId",
      getPaymentOptionsDetail: "getPaymentOptionsDetail",
      availableCredit: "user/availableCredit",
    }),

    totalCartAmount() {
      // return this.total + this.paymentTermAmount;
      return this.totalCartPrice;
    },
    paymentOptions() {
      const result = this.getPaymentOptionsDetail(this.categoryIdFromCart);
      // if (result && result.length) this.setPaymentOption(result[0]);
      return result;
    },
    expectedDeliveryDate() {
      return moment().add(this.expectedDeliveryDays, "days").format("MMM D YYYY");
    },
  },

  methods: {
    ...mapMutations({
      setCredits: "user/setCredits",
    }),
    formatPrice,
    setPaymentOption(option) {
      this.setPaymentTermId(option.value);
      this.setPaymentTermAmount(option.amount);
    },

    setPaymentTermId(id) {
      this.paymentTermId = id;
    },

    setPaymentTermAmount(amount) {
      // Adding extra payment term amount per quantity basis.
      // let result = amount * this.totalQuantity;
      // let result = 0;

      // if (this.getIsGstIncludedFromCart) {
      //   // result = amount * this.totalQuantity;
      //   result = amount;
      // } else {
      //   // result = amount * (this.getGstPercentageFromCart / 100) * this.totalQuantity;
      //   // result = amount + amount * (this.getGstPercentageFromCart / 100);

      //   result = amount;
      // }

      // result = amount;

      // let result = amount;

      // if (this.getIsGstIncludedFromCart) {
      //   this.paymentTermAmount = result;
      //   console.log("result", result);
      // } else {
      //   // TODO Update logic to support sand and Flyash order in one . Currently one one type of gst percentage is considered in calculation. No Two different gst percentage
      //   const gstPercentage = this.getGstPercentageFromCart;
      //   this.paymentTermAmount = result + this.addGstWithPaymentOptionsAmount(result, gstPercentage);
      // }

      this.paymentTermAmount = amount;

      this.setExtraTimeCharges(this.paymentTermAmount);
    },

    // addGstWithPaymentOptionsAmount(amount, gstPercentage) {
    //   const result = amount * (gstPercentage / 100);
    //   return result;
    // },
    getDetails() {
      getBrandDetail(this.brandIdFromCart).then((res) => {
        this.expectedDeliveryDays = res.expected_delivery_days;
      });
    },
    showMetaData() {
      if (this.isBuyerAgreedToTerms()) this.showPaymentTermsBox = true;
    },
    isBuyerAgreedToTerms() {
      if (!(this.termsChecked && this.isAddressConfirmed)) {
        this.$shilp.notify({
          title: "Please confirm Project Address & Accept Terms & Conditions.",
          type: "danger",
        });

        return false;
      } else return true;
    },

    requestOrder() {
      if (this.isBuyerAgreedToTerms()) this.checkOrderQuantity();
    },

    checkOrderQuantity() {
      if (this.categoryNameInCart == "TMT Bar" || this.categoryNameInCart == "AAC Blocks") {
        const isGreater = this.isTotalQuantityGreaterThanMinimumOrderQuantity(this.categoryIdFromCart, this.totalQuantity);

        if (isGreater) {
          // REFACTOR Below code for validation of minimum order quantity and multiple quantity check is repeating in 2 files.
          if (this.categoryNameInCart == "AAC Blocks") {
            if (this.isAacBlocksOrderQuantityInFixedSlot(this.totalQuantity)) {
              this.getLatestProductData();
            } else {
              this.notifyAacBlocksOrderInMultipleSlotOnly();
              return;
            }
          } else this.getLatestProductData();
        } else {
          if (this.categoryNameInCart == "TMT Bar") {
            this.notifyTmtBarMinimumOrderQuantity();
            return;
          }

          if (this.categoryNameInCart == "AAC Blocks") {
            this.notifyAacBlocksMinimumOrderQuantity();
            return;
          }
        }
      } else {
        this.getLatestProductData();
      }
    },

    getLatestProductData() {
      this.isLoading = true;
      get({ filter: this.filters, ...this.params })
        .then((res) => {
          this.truckItemsFromApi = res.data;
          this.checkIfPriceChanged();
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async checkIfPriceChanged() {
      if (this.truckItemsFromApi.length && this.comparePrice(this.cartProducts, this.truckItemsFromApi)) {
        const isBuyerReadyToOrder = await this.showPriceChangeAlertModal();
        this.$emit("price-updated-from-api");
        if (isBuyerReadyToOrder) this.placeOrder();
      } else this.placeOrder();
    },
    async placeOrder(additionalData = null) {
      this.isLoading = true;

      const adminId = getAdminId();
      const companyId = getCompanyId();
      try {
        let formBody = {
          project_id: this.projectId,
          products: this.cartProducts,
          brand_id: this.brandIdFromCart,
          payment_term_id: this.paymentTermId,
          expected_delivery_date: formatDateForBackEnd(this.expectedDeliveryDate),
          ...additionalData,
          by_admin_id: adminId,
          company_id: companyId,
        };
        await create(formBody).then((res) => {
          this.$shilp.notify({
            title: "Your Booking Request placed successfully.",
            message: `"Your booking ID is ${res.id}. We will process and confirm as soon as possible. Thank you."`,
            type: "success",
          });

          this.$graph(hydrateQuery, {}).then((res) => {
            // Manually Updating Credits after order
            this.setCredits({
              availableCredit: res.me.companies[0].availableCredit,
              totalCredit: res.me.companies[0].credit,
              utilizeCredit: res.me.companies[0].utilizeCredit,
            });
          });
          this.emptyCart();
          this.$router.push({ name: "order-details", params: { id: res.id } });
        });
      } catch ({ data }) {
        this.$shilp.notify({
          title: data.message,
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },

    //TODO: Need to remove this modal and it's component as of new flow.

    // showAdditionalInstructionsModal() {
    //   this.$root.$emit("shilp-modal-open", "additional-instructions-modal");
    // },

    showPriceChangeAlertModal() {
      let buyerPromiseToOrder = new Promise((resolve) => {
        this.$shilp.confirm({
          title: "Product Price has been Updated.",
          message: "Are you sure you want to continue booking?",
          okLabel: "Yes",
          cancelLabel: "No",
          ok: ({ close }) => {
            close();

            resolve(true);
          },

          cancel: () => {
            this.$root.$emit("shilp-modal-close", "shilp-confirm");
            resolve(false);
          },
        });
      });

      return buyerPromiseToOrder;
    },
  },
};
</script>

<style scoped>
.custom-size-input {
  min-width: 20px;
  height: 20px;
}
.highlight-text {
  @apply bi-text-secondary-default;
}

@media screen and (max-width: 640px) {
  .custom-size-input {
    min-width: 14px;
    height: 14px;
  }
}
@media screen and (max-width: 435px) {
  .custom-div-gap {
    gap: 0.25rem;
  }
}
@media screen and (max-width: 360px) {
  .custom-div-align {
    justify-content: flex-end;
  }
}
</style>
