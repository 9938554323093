<template>
  <router-view> </router-view>
</template>

<script>
import kycMixin from "@/mixins/kycMixin";
export default {
  mixins: [kycMixin],
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
