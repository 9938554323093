export default [
  {
    path: "home",
    name: "home",
    component: require("@/views/home/Index").default,
    redirect: { name: "home-content" },
    children: [
      {
        path: "",
        name: "home-content",
        props: true,
        component: require("@/views/home/MainContent").default,
        meta: {
          // below label is set to empty string , because no need to show breadcrumbs in home screen but if set to false, errors occur
          breadcrumbs: ["home-content"],
        },
      },
      {
        path: "static-content/:categoryName",
        name: "static-content",
        props: true,
        component: require("@/views/home/StaticContent.vue").default,
        meta: {
          // below label is set to empty string , because no need to show breadcrumbs in home screen but if set to false, errors occur
          breadcrumbs: ["static-content"],
        },
      },
    ],
  },
];
