<template>
  <div class="h-full">
    <div
      class="bi-flex bi-justify-between bi-items-center bb-lightest bi-h-13 bi-pl-4"
    >
      <span class="bi-text-base bi-text-grey-default bi-block">Filters</span>
      <s-button
        icon="IconIcRoundClose"
        size="lg"
        color="gray"
        theme="link"
        @click.native="$emit('filterBarClose')"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
