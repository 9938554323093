<template>
  <modal size="md" title="Replace Truck Items?" id="order-category-modal">
    <template #message>
      Your truck contains items of
      <b class="bi-text-secondary-default">{{ brandNameFromCart }}</b> Brand in
      <b class="bi-text-secondary-default">{{ categoryNameInCart }}</b>
      Category. Do you want to discard the items and add new selection into
      truck?
    </template>
    <template #default="{ data }">
      <div class="bi-mt-8 bi-flex bi-flex-nowrap bi-gap-2">
        <s-button
          label="No"
          color="danger"
          theme="outline"
          size="lg"
          class="bi-ml-2 bi-flex-auto bi-font-bold"
          @click.native="cancel"
        />
        <s-button
          label="Replace"
          color="primary"
          class="bi-flex-auto"
          @click.native="replace(data.id)"
          size="lg"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import cartMixin from "@/mixins/cartMixin";
export default {
  mixins: [cartMixin],
  methods: {
    replace(id) {
      this.setCategoryIdInCart(this.categoryIdFromHomeScreen);
      this.setCategoryNameInCart(this.categoryNameFromHomeScreen);
      this.emptyCart();
      this.$root.$emit("truck-got-empty-and-items-replaced", { id: id });

      // below if condition is to solve issue of emitting event when not required which is causing other issues.
      if (!id) this.$root.$emit("items-replaced-from-order-history");
      this.$root.$emit("shilp-modal-close", "order-category-modal");
    },
    cancel() {
      this.$root.$emit("shilp-modal-close", "order-category-modal");
      this.$router.push({ name: "truck" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
