<template>
  <div class="bi-text-left bi-text-xs bb-lightest">
    <h6 class="bi-text-grey-default bi-font-normal bi-my-2" v-for="orderList in orderListHeading" :key="orderList.id">{{ orderList }}</h6>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderListHeading: ["Order ID", "Date & Time", "Brands", "Categories", "Payment Status", "Total Quantity", "Total Price", "Attachment"],
    };
  },
};
</script>

<style lang="scss" scoped></style>
