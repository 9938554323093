<template>
  <div class="layout__wrap bi-flex md:bi-justify-left bi-items-center">
    <!-- Name , email,  product category ,  Brands preffered , additional requirements , attahcment options -->
    <div class="md:bi-w-full">
      <!-- Full name -->
      <item-form
        :item="false"
        :redirect="false"
        :form-state.sync="formState"
        :notify="false"
        :save="save"
        ref="inquiryForm"
        :fields="fields"
        title="Inquiry"
        desc="Let us know your choices and requirements."
      >
        <template #default="{ form }">
          <div class="bi-flex bi-flex-wrap bi-gap-8 bi-items-center bi-p-4 bi-shadow-sm bi-rounded-md bi-bg-grey-lightest-double-ultra">
            <div>
              <!-- <label for="" class="bi-block">Name</label> -->
              <s-field label="Name">
                {{ form.name }}
              </s-field>
            </div>

            <div>
              <!-- <label for="" class="bi-block">Email</label> -->
              <s-field label="Email">
                {{ form.email }}
              </s-field>
            </div>

            <div>
              <!-- <label for="" class="bi-block">Mobile Number</label> -->
              <s-field label="Mobile Number">
                {{ mobileNumber }}
              </s-field>
              <!-- <span>{{ mobileNumber }}</span> -->
            </div>
          </div>
          <div>
            <label class="field__label">Select Project</label>
            <ProjectDropDown />
          </div>

          <div class="bi-grid xl:bi-grid-cols-2 bi-gap-6">
            <!-- TODO Name , Email should be autofilled from vuex store -->

            <!-- <s-field-validate rules="required" name="Full name">
                <s-textbox size="lg" v-model="form.name" placeholder="Full name" disabled class="bi-cursor-not-allowed" />
              </s-field-validate> -->

            <!-- Email -->
            <!-- <s-field-validate rules="required|email" name="Email">
                <s-textbox size="lg" v-model="form.email" type="email" placeholder="Email" disabled />
              </s-field-validate> -->

            <!-- <s-field-validate rules="required|mobileNo" name="Mobile Number"> -->
            <CustomMobileInput v-model="form.mobile_no" placeholder="Enter Mobile Number" />
            <!-- </s-field-validate> -->

            <!-- Product Category -->
            <s-field-validate rules="required" name="Product Category">
              <s-textbox size="lg" rules="required" placeholder="Product Category" :disabled="isDisabled" v-model="form.category" />
            </s-field-validate>

            <!-- Brands preferred -->
            <s-field name="Brands Preferred">
              <s-textbox size="lg" placeholder="Brands Preferred" :disabled="isDisabled" v-model="form.brand" />
            </s-field>

            <!-- Grade -->

            <s-field name="Grade">
              <s-textbox size="lg" placeholder="Grade" :disabled="isDisabled" v-model="form.grade" />
            </s-field>

            <!-- Size / Packing -->
            <s-field name="Size/Packing">
              <s-textbox size="lg" placeholder="Size/Packing" :disabled="isDisabled" v-model="form.size_packing" />
            </s-field>

            <s-field-validate name="Quantity" rules="positiveNonZeroFloat">
              <s-textbox size="lg" placeholder="Quantity" v-model="form.qty" type="number" />

              <template #after v-if="unit">{{ unit }}</template>
            </s-field-validate>

            <!-- <div></div> -->

            <!-- Additional Requirements -->
            <s-field name="Additional Requirements">
              <s-textarea
                size="lg"
                rows="7"
                v-model="form.additional_requirement"
                placeholder="Additional Requirements - Mention additional details about inquiry"
              />
            </s-field>

            <!-- Attachment options  -->
            <s-field>
              <!-- <file-upload :readonly="false" accept=".pdf,.jpg" v-model="form.attachment" width="100px" ratio="1x1" /> -->
              <div>
                <!-- :fileAgentKey="fileAgentKey" -->
                <VueFileAgentWrapper
                  endpoint="medias"
                  :params="{ used_for: 'inquiry' }"
                  :multiple="true"
                  :linkable="true"
                  v-model="fileRecords"
                  @uploading="isUploading = $event"
                />
              </div>
            </s-field>
          </div>
        </template>

        <template #footer="{ saving }">
          <div class="bi-text-center">
            <s-button label="Submit" color="success" size="lg" type="submit" :loader="saving" :disabled="isUploading" />
          </div>
        </template>
      </item-form>
    </div>
  </div>
</template>

<script>
import { sendInquiry } from "@/api/inquiry.js";
import { sendMedia } from "@/api/media.js";
import { alertApiError } from "@/utils/common";
import { mapGetters } from "vuex";
import { getGradeAttributeObject, getAttributeValue } from "@/utils/common.js";
import fileUploadMixin from "@/mixins/fileUpload";
import { getAdminId, getCompanyId } from "@/utils/adminOrder";
export default {
  sendMedia,
  components: {
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
    ProjectDropDown: require("@/components/projectDropDown/DropDown").default,
    CustomMobileInput: require("@/components/CustomMobileInput").default,
  },
  mixins: [fileUploadMixin],
  data() {
    return {
      fileRecords: [],
      // fileAgentKey: 1,
      formState: {},
      isUploading: false,
    };
  },
  props: ["routeParams"],
  computed: {
    ...mapGetters({
      user: "user/get",
      projectId: "user/getProjectId",
      mobileNumber: "user/mobileNumber",
    }),

    inquiryType() {
      if (this.routeParams?.inquiryType) return this.routeParams?.inquiryType;
      else return 0;
    },

    isDisabled() {
      if (this.routeParams?.fieldsDisabled) return true;
      else return false;
    },

    product() {
      return this.routeParams?.product;
    },

    productId() {
      return this.routeParams?.product?.id;
    },

    unit() {
      return this.routeParams?.product?.unit;
    },

    category() {
      return this.product?.categories[0].name || this.routeParams?.category;
    },

    brand() {
      return this.product?.brand.name || this.routeParams?.brand;
    },
    grade() {
      if (this.product && this.product.attributeOptions) return getGradeAttributeObject(this.product?.attributeOptions).name;
      else return null;
    },

    extraAttribute() {
      if (this.product && this.product.attributeOptions) {
        let attribute = null;
        attribute = getAttributeValue(this.product?.attributeOptions, "Size");

        if (attribute) return attribute;
        else {
          attribute = getAttributeValue(this.product?.attributeOptions, "Packing");
          return attribute;
        }
      } else return null;
    },

    fields() {
      return [
        {
          name: "name",
          value: this.user.name,
        },
        {
          name: "email",
          value: this.user.email,
        },
        {
          name: "mobile_no",
          value: this.mobileNumber,
        },
        // "category",
        {
          name: "category",
          value: this.category,
        },
        {
          name: "brand",
          value: this.brand,
        },
        {
          name: "grade",
          value: this.grade,
        },
        {
          name: "size_packing",
          value: this.extraAttribute,
        },
        {
          name: "qty",
          value: null,
        },
        {
          name: "product_id",
          value: this.productId,
        },
        "additional_requirement",
      ];
    },
  },
  methods: {
    save(id, data) {
      let isValid = true;
      if (this.fileRecords.length) {
        isValid = this.isMediaValid(this.fileRecords);
        const mediaIdArray = this.checkIfAttachmentIsUploadedAndGetId();
        if (mediaIdArray) data.media_id = mediaIdArray;
      }

      if (isValid) {
        let form = { inquiry_type: this.inquiryType, ...data, project_id: this.projectId };

        const adminId = getAdminId();

        const companyId = getCompanyId();

        if (adminId) {
          form["by_admin_id"] = adminId;
          form["company_id"] = companyId;
        }

        return sendInquiry(form)
          .then((res) => {
            this.$shilp.notify({
              title: "Your Inquiry submitted successfully. We will contact you as soon as possible. Thank you. ",
              type: "accent",
            });

            this.$router.push({ name: "inquiry-list" });

            return res;
          })
          .catch((err) => {
            // REFACTOR Use mixin instead of utility as it needs to use this instance .
            alertApiError(err, this);
          });
      }
    },

    checkIfAttachmentIsUploadedAndGetId() {
      if (this.fileRecords.length) {
        let imageIds = [];
        this.fileRecords.forEach((item) => {
          // return item.upload.data[0].id;

          if (item.upload && item.upload.data && item.upload.data[0].id) {
            imageIds.push(item.upload.data[0].id);
          } else if (item.raw && item.raw.id) {
            imageIds.push(item.raw.id);
          } else imageIds.push(item.id);
        });

        return imageIds;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
