<template>
  <div>
    <div class="bi-flex bi-flex-wrap bi-gap-8">
      <item-form
        class="bi-p-6 bi-pb-0"
        :class="{ 'lg:bi-border-r bi-border-grey-lightest-ultra': partnerList.length }"
        :item="false"
        :form-state.sync="formState"
        :notify="false"
        :save="addPartner"
        :fields="fields"
        :gap="2"
        :breadcrumb="false"
        :redirect="false"
        style="width: 500px"
        ref="item"
      >
        <template #default="{ form }">
          <!-- Partner Name  -->
          <s-field-validate name="Partner/Director Name" rules="required">
            <TextBoxCapitalize placeholder="Partner/Director Name" v-model="form.name" />
          </s-field-validate>
          <!-- Mobile No  -->

          <CustomMobileInput v-model="form.mobileNo" />

          <!-- Receive Notification -->
          <s-field-validate name="Receive Notification">
            <div class="bi-flex bi-items-start">
              <s-checkbox class="notification-checkbox" v-model="form.enableNotification" label="Receive Notification" id="enableNotification" />
              <v-popover trigger="hover" popoverWrapperClass="bi-w-auto">
                <template #popover>
                  <p>All order related notifications will be sent to this partner.</p>
                </template>
                <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5 bi-ml-2" name="IconMdiInformationOutline" />
              </v-popover>
            </div>
          </s-field-validate>
          <p class="bi-text-grey-darkest-ultra"></p>

          <!-- Email  -->
          <s-field-validate name="Email" rules="email">
            <s-textbox v-model="form.email" type="text" placeholder="Email-optional" />
          </s-field-validate>

          <!--  OTP -->
          <ValidationProvider rules="required">
            <otp-handler v-model="form.code" :get="getOtp" :reset="resetOtp" />
          </ValidationProvider>

          <!--- Aadhar Card --->
          <!-- <div class="bi-flex bi-gap-2">
            <s-field-validate
              style="flex: 0 0 0%"
              v-model="form.documents.aadhaarCardFront"
              name="Aadhaar Card Front Side"
              :desc="`Aadhar Card Front Side ${
                allowedDocumentsToShow(companyType ? companyType.name : null).includes('aadharCard') ? '' : '(optional)'
              }`"
              :optional="allowedDocumentsToShow(companyType ? companyType.name : null).includes('aadharCard') ? false : true"
              :rules="allowedDocumentsToShow(companyType ? companyType.name : null).includes('aadharCard') ? 'required' : ''"
            >
              <div>
                <file-upload
                  :readonly="false"
                  accept=".pdf"
                  v-model="form.documents.aadhaarCardFront"
                  @input="addFile($event, 'aadhaarCardFront')"
                  width="100px"
                  ratio="1x1"
                />
              </div>
            </s-field-validate>
            <s-field-validate
              style="flex: 0 0 0%"
              v-model="form.documents.aadhaarCardBack"
              name="Aadhaar Card Back Side"
              :desc="`Aadhar Card Back Side ${
                allowedDocumentsToShow(companyType ? companyType.name : null).includes('aadharCard') ? '' : '(optional)'
              }`"
              :optional="allowedDocumentsToShow(companyType ? companyType.name : null).includes('aadharCard') ? false : true"
              :rules="allowedDocumentsToShow(companyType ? companyType.name : null).includes('aadharCard') ? 'required' : ''"
            >
              <div>
                <file-upload
                  :readonly="false"
                  accept=".pdf"
                  v-model="form.documents.aadhaarCardBack"
                  @input="addFile($event, 'aadhaarCardBack')"
                  width="100px"
                  ratio="1x1"
                />
              </div>
            </s-field-validate>
          </div> -->

          <!---PanCard--->
          <!-- <div>
            <s-field-validate class="file-container-field" v-model="panCard" name="PanCard" rules="required" label="Pan Card">
              <div>
                <file-upload
                  :readonly="false"
                  accept=".pdf*"
                  v-model="form.documents.panCard"
                  @input="addFile($event, 'panCard')"
                  width="100px"
                  ratio="1x1"
                />
              </div>
            </s-field-validate>
          </div> -->
        </template>
        <template #footer="{ saving }">
          <div>
            <s-button v-if="id == '+' || !isPartnerEditing" label="ADD" color="success" :loader="saving" type="submit"></s-button>
            <s-button v-else label="EDIT" color="success" @click.native="edit" :loader="isEditing"></s-button>
            <span class="bi-text-grey-darkest-ultra bi-block bi-mt-4">*Submit all details of at least one partner</span>
          </div>
        </template>
      </item-form>
      <!-- Display Data -->
      <div>
        <div v-if="!isPartnerListLoading" style="max-height: 500px; overflow: auto">
          <div class="bi-text-primary-default bi-text-lg bi-font-semibold bi-pl-4 bi-mt-4">Partner Details</div>
          <sp-list :data="partnerList" :attrs="columns" ref="partnersList" :actions="['']" class="clickable bi-p-4">
            <!-- v-if="partnerList && partnerList.length" -->
            <template #empty>
              <NoPartnerFound />
            </template>
            <sp-list-table @rowClick="setPartnerDetails">
              <template #name="{ item }">
                <span class="bi-text-md bi-text-primary-default bi-font-bold">{{ item.name }}</span>
              </template>
              <template #mobileNo="{ item }">
                <span class="bi-text-md">{{ item.mobileNo }}</span>
              </template>

              <template #isDefault="{ item }">
                <s-switch
                  :key="`primary-partner-switch-${item.id}`"
                  :id="`primary-partner-switch-${item.id}`"
                  color="primary"
                  size="xs"
                  :on-value="true"
                  v-tooltip="'Select as Main Partner'"
                  :off-value="false"
                  @click.native="toggleMainPartners(item)"
                  :value="item.isDefault"
                  shape="pill"
                />
              </template>

              <template #actions="{ item }">
                <div class="bi-text-center bi-flex bi-items-center bi-gap-2">
                  <s-button
                    icon="IconMdiTrashCanOutline"
                    shape="circle"
                    v-tooltip="'Remove Partner'"
                    color="danger"
                    theme="muted"
                    @click.native="removePartner(item)"
                  />
                  <s-button
                    icon="IconIcSharpEdit"
                    shape="circle"
                    v-tooltip="'Edit Partner'"
                    color="secondary"
                    theme="muted"
                    @click.native="setPartnerDetails(item)"
                  />
                </div>
              </template>

              <!-- <template #aadhaarCardBack="{ item }">
              <a :href="item.documents.aadhaarCardBack.url" target="_blank">
                <s-icon name="IconIcRoundPictureAsPdf" class="bi-w-8 bi-h-8 bi-text-grey-default" />
              </a>
            </template> -->
            </sp-list-table>
          </sp-list>
        </div>
        <FormShimmer v-else class="bi-w-100" />

        <div class="bi-flex bi-items-center bi-mt-4 bi-pl-4">
          <s-button size="lg" theme="outline" label="Previous" @click.native="goToUpsertDocumentPage" />
          <s-button label="Submit KYC" color="secondary" size="lg" align="center" :loader="isSubmitting" class="bi-ml-6" @click.native="onSubmit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import upsert from "@/graph/kyc/partner/upsert.gql";
import getpartners from "@/graph/kyc/partner/collection.gql";
// import get from "@/graph/kyc/get.gql";
import completeKyc from "@/graph/kyc/complete.gql";
import { cloneDeep } from "lodash-es";
import { mapGetters, mapActions } from "vuex";
import otp from "@/graph/kyc/partner/otp.gql";
import deletePartner from "@/graph/kyc/partner/delete.gql";
import kycMixin from "@/mixins/kycMixin";
import { alertRouteChangeToBuyer } from "@/utils/common";

export default {
  props: ["id"],
  mixins: [kycMixin],
  data() {
    return {
      // aadhaarCardBack: null,
      // aadhaarCardFront: null,
      isSubmitting: false,
      isEditing: false,
      isPartnerListLoading: false,
      isPartnerEditing: false,
      partnerId: null,
      // panCard: null,
      newPartnerAddedLoader: false,
      formState: {},
      resetOtp: false,
      iframeWidth: "300px",
      iframeHeight: "300px",
      partnerList: [],
      fields: [
        "name",
        "code",
        "mobileNo",
        "email",
        { name: "enableNotification", value: false },
        // {
        //   name: "documents",
        //   value: {
        //     aadhaarCardFront: null,
        //     aadhaarCardBack: null,
        //     panCard: null,
        //   },
        // },
      ],
      columns: [
        {
          name: "name",
          label: "Partner Name",
        },
        { name: "mobileNo", label: "Partner MobileNo" },
        {
          name: "isDefault",
          label: "Main Partners",
          fix: true,
          rowClick: false,
        },
        {
          name: "actions",
          label: "Actions",
          fix: true,
          rowClick: false,
        },
      ],
    };
  },
  created() {
    if (this.id != "+") {
      this.getAllPartners();
      // this.$root.$emit(
      //   "shilp-breadcrumbs-label",
      //   this.$route.name,
      //   "Update Partner"
      // );
    }
  },
  computed: {
    ...mapGetters({
      user: "user/get",
      companyId: "user/companyId",
      companyType: "user/companyType",
    }),
  },
  components: {
    OtpHandler: require("@/components/OtpHandler").default,
    TextBoxCapitalize: require("@/components/TextBoxCapitalize").default,
    FormShimmer: require("@/components/FormShimmer").default,
    NoPartnerFound: require("@/components/kyc/NoPartnerFound").default,
    CustomMobileInput: require("@/components/CustomMobileInput").default,
  },
  methods: {
    alertRouteChangeToBuyer,
    goToUpsertDocumentPage() {
      if (this.alertRouteChangeToBuyer()) this.$router.push({ name: "upsert-document", id: this.companyId.toString() });
    },
    toggleMainPartners(partner) {
      let data = {};
      const id = partner.id;
      data = cloneDeep(partner);
      data.isDefault = !partner.isDefault;
      data.companyId = this.companyId;
      delete data.id;
      // data.documents.panCard = null;
      // data.documents.aadhaarCardFront = null;
      // data.documents.aadhaarCardBack = null;

      this.$apolloUpload()
        .mutate({
          mutation: this.$gql(upsert),
          variables: {
            id: id,
            input: data,
          },
        })
        .then(() => {
          this.getAllPartners();
        });
    },
    removePartner(partner) {
      this.$shilp.confirm({
        title: "Remove Partner from Company",
        message: `Are you sure to remove partner ${partner.name} ? `,
        okLabel: "Yes",
        ok: ({ close }) => {
          this.$graph(deletePartner, { id: partner.id }).then(() => {
            this.$shilp.notify({
              title: "Partner removed Successfully",
              type: "accent",
            });

            close();
            this.getAllPartners();
          });
        },
      });
    },
    setPartnerDetails(partner) {
      if (this.id == "+") return;
      this.$refs.item.reset();
      this.isPartnerEditing = true;
      this.partnerId = partner.id;
      this.formState.name = partner.name;
      this.formState.email = partner.email;
      this.formState.mobileNo = partner.mobileNo;
      // this.formState.documents.aadhaarCardFront = partner.documents.aadhaarCardFront;
      // this.formState.documents.aadhaarCardBack = partner.documents.aadhaarCardBack;
      // this.formState.documents.panCard = partner.documents.panCard;
      this.$refs.item.$el.scrollIntoView({ behavior: "smooth" });
    },

    resetValidation() {
      this.$refs.item.reset();
    },

    async addPartner() {
      const isValid = await this.$refs.item.validateForm();
      if (!isValid) return;

      this.newPartnerAddedLoader = true;
      let form = cloneDeep(this.formState);
      form["companyId"] = this.user.companies[0].id;

      return this.uploadParnterData(form)
        .then((res) => {
          this.resetOtp = !this.resetOtp;
          return res;
        })
        .finally(() => {
          this.newPartnerAddedLoader = false;
        });
    },

    async onSubmit() {
      if (this.formState.name && this.formState.name.length) {
        this.$root.$emit("shilp-modal-open", "alert-upsert-partner-modal");

        this.$root.$once("continue-submitting-kyc", async () => {
          this.submitKycApplication();
        });
      } else this.submitKycApplication();
    },

    async submitKycApplication() {
      this.isSubmitting = true;
      try {
        await this.$graph(completeKyc);

        this.$root.$emit("shilp-modal-open", "kyc-details-saved-modal");
        this.updateUser();
      } catch {
        this.$shilp.notify({
          title: "Please add atleast one Partner before Submitting KYC Application",
          type: "danger",
        });
      } finally {
        this.isSubmitting = false;
      }
    },

    async updateUser() {
      try {
        await this.hydrateUserWithCompanyType();

        this.$router.push({ name: "home" });
      } catch (error) {
        console.log(error);
      }
    },

    uploadParnterData(data, id = null) {
      //REFACTOR Ask backend about this
      data["documents"] = null;
      return new Promise((resolve, reject) => {
        this.$graph(upsert, {
          id,
          input: data,
        })
          .then(() => {
            this.formState.code = "";
            this.formState.enableNotification = false;
            this.resetOtp = !this.resetOtp;
            this.getAllPartners();
            this.resetValidation();
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.resetOtp = !this.resetOtp;
          });
      });
    },

    // async uploadParnterData(formData, id = null) {
    //   return new Promise((resolve, reject) => {
    //     this.$apolloUpload()
    //       .mutate({
    //         mutation: this.$gql(upsert),
    //         variables: {
    //           id,
    //           input: formData,
    //         },
    //       })
    //       .then(() => {
    //         // this.formState = null;
    //         this.aadhaarCardBack = null;
    //         this.aadhaarCardFront = null;
    //         this.panCard = null;
    //         this.formState.documents.aadhaarCardFront = null;
    //         this.formState.documents.aadhaarCardBack = null;
    //         this.formState.documents.panCard = null;
    //         this.formState.code = "";
    //         this.formState.enableNotification = false;
    //         this.resetOtp = !this.resetOtp;
    //         this.getAllPartners();

    //         this.resetValidation();
    //         resolve(true);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       })
    //       .finally(() => {
    //         this.resetOtp = !this.resetOtp;
    //       });
    //   });
    // },
    async getAllPartners() {
      this.isPartnerListLoading = true;
      try {
        let id = null;
        id = this.user.companies[0].id;
        if (id) {
          let res = await this.$graph(getpartners, {
            companyId: id,
          });

          this.partnerList = res.partners.data;
        } else {
          this.partnerList = [];
        }
      } catch {
        this.partnerList = [];
      } finally {
        this.isPartnerListLoading = false;
      }
    },
    getOtp() {
      if (this.formState.mobileNo == null || this.formState.mobileNo.length <= 9) {
        this.$shilp.notify({
          title: "Please Enter Valid Mobile Number",
          type: "danger",
        });

        return new Promise((resolve, reject) => {
          reject(false);
        });
      }
      return this.$graph(otp, {
        input: {
          mobileNo: this.formState.mobileNo,
          codeFor: "add_partner",
        },
      }).then((res) => {
        return res.sendCodeForAuthMutation.code;
      });
    },
    async addFile(file, use_for) {
      // const file = event.target.files[0];
      this.formState.documents[use_for] = file;
      // this.url[use_for] = URL.createObjectURL(file);
    },
    async edit() {
      this.isEditing = true;
      let form = cloneDeep(this.formState);
      form["companyId"] = this.user.companies[0].id;

      form["documents"] = null;

      if (this.id != "+") {
        if (!this.partnerId) {
          this.$shilp.notify({
            title: "Please select partner before submitting.",
            type: "danger",
          });

          return;
        }
        // if (form.documents.aadhaarCardFront && form.documents.aadhaarCardFront.url) {
        //   form.documents.aadhaarCardFront = null;
        // }
        // if (form.documents.aadhaarCardBack && form.documents.aadhaarCardBack.url) {
        //   form.documents.aadhaarCardBack = null;
        // }
        // if (form.documents.panCard && form.documents.panCard.url) {
        //   form.documents.panCard = null;
        // }
      }

      await this.uploadParnterData(form, parseInt(this.partnerId));

      this.isPartnerEditing = false;
      this.isEditing = false;
    },
  },
};
</script>

<style lang="scss">
.notification-checkbox {
  @apply bi-flex bi-items-center;
}
.field__content .notification-checkbox > input {
  @apply bi-h-auto;
}
// REFACTOR Below style is repeating in multiple files. make in in single file
// .file-container-field {
//   @apply bi-font-medium;
//   .field__content {
//     @apply bi-w-25;
//     flex: 0 0 0%;
//   }
// }
.notification-checkbox label.choice__label:not(.button) {
  @apply bi-text-grey-default bi-font-medium bi-ml-2;
}
</style>
