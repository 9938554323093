<template>
  <div class="layout__wrap">
    <item-form
      :item="id"
      :redirect="false"
      :form-state.sync="formState"
      :notify="false"
      :get="getItem"
      :footer="false"
      :save="() => {}"
      ref="inquiryForm"
      :fields="fields"
      title="Inquiry"
    >
      <template #default="{ form }">
        <div class="bi-flex bi-flex-wrap bi-gap-6 sm:bi-gap-8 md:bi-gap-16 lg:bi-gap-32 bi-items-center bi-p-4 bi-shadow-sm bi-rounded-md bi-bg-grey-lightest-double-ultra">
          <s-field label="Ticket Number"> #{{ form.id }} </s-field>

          <s-field label="Date & Time">
            {{ formatDate(form.created_at) }}
          </s-field>

          <s-field label="Status">
            <Tag :label="form.inquiryStatus.name" />
          </s-field>
        </div>
        <div class="bi-flex bi-flex-wrap bi-gap-6 sm:bi-gap-8 md:bi-gap-16 lg:bi-gap-32 bi-items-center bi-p-4 bi-shadow-sm bi-rounded-md bi-bg-grey-lightest-double-ultra">
          <!-- <label for="" class="bi-block">Name</label> -->

          <s-field label="Name">
            {{ form.name }}
          </s-field>

          <div>
            <!-- <label for="" class="bi-block">Email</label> -->
            <s-field label="Email">
              {{ form.email }}
            </s-field>
          </div>

          <div>
            <!-- <label for="" class="bi-block">Mobile Number</label> -->
            <s-field label="Mobile Number">
              {{ form.mobile_no }}
            </s-field>
            <!-- <span>{{ mobileNumber }}</span> -->
          </div>
        </div>
        <div class="bi-flex bi-flex-wrap bi-gap-6 sm:bi-gap-8 md:bi-gap-16 lg:bi-gap-32 bi-items-center bi-p-4 bi-shadow-sm bi-rounded-md bi-bg-grey-lightest-double-ultra">
          <div>
            <!-- <label for="" class="bi-block">Name</label> -->
            <s-field label="Category">
              {{ form.category }}
            </s-field>
          </div>

          <!-- <label for="" class="bi-block">Email</label> -->
          <s-field label="Brand" v-if="form.brand">
            {{ form.brand }}
          </s-field>

          <s-field label="Grade" v-if="form.grade">
            {{ form.grade }}
          </s-field>

          <div>
            <!-- <label for="" class="bi-block">Mobile Number</label> -->
            <s-field label="Size / Packing" v-if="form.size_packing">
              {{ form.size_packing }}
            </s-field>
            <!-- <span>{{ mobileNumber }}</span> -->
          </div>

          <s-field label="Quantity" v-if="form.qty">
            {{ form.qty }}
          </s-field>
        </div>

        <s-field
          label="Additional Requirements"
          class="bi-p-4 bi-shadow-sm bi-rounded-md bi-bg-grey-lightest-double-ultra"
          v-if="form.additional_requirement"
        >
          {{ form.additional_requirement }}
        </s-field>

        <s-field label="Attachment(s)" class="bi-p-4 bi-shadow-sm bi-rounded-md bi-bg-grey-lightest-double-ultra" v-if="form.attachment">
          <VueFileAgentWrapper :linkable="true" :readonly="true" :value="form.attachment" :isDeletable="false" />
        </s-field>
      </template>
    </item-form>
  </div>
</template>

<script>
import { getDetail } from "@/api/inquiry.js";
import { formatDate } from "@/utils/common";
export default {
  props: ["routeParams"],
  computed: {
    id() {
      return this.routeParams.id;
    },
  },

  components: {
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
  },
  data() {
    return {
      formState: null,
      fields: [
        "id",
        "name",
        "email",
        "mobile_no",
        "category",
        "brand",
        "grade",
        "size_packing",
        "qty",
        "inquiryStatus",
        "additional_requirement",
        "attachment",
        "created_at",
      ],
    };
  },

  methods: {
    formatDate,
    getItem() {
      return getDetail(this.id, {
        include: "inquiryStatus",
      }).then((res) => {
        return res;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
