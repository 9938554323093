<template>
  <div class="bi-rounded-lg bi-shadow-lg">
    <label class="bi-text-grey-default bi-text-base bi-font-normal">Additional Instructions</label>

    <div class="bi-text-grey-darkest-ultra bi-font-medium">
      {{ instructions }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["instructions"],
};
</script>

<style lang="scss" scoped></style>
