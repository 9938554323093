<template>
  <div
    :style="`height: ${height}`"
    class="bi-bg-primary-default bi-flex bi-justify-between bi-items-center bi-flex-nowrap bi-px-2 md:bi-px-6 bi-gap-4 md:bi-gap-10"
  >
    <slot name="mobile-menu" />
    <div class="bi-flex bi-items-center md:bi-gap-10 bi-gap-4">
      <slot name="logo"> </slot>
      <div class="bi-hidden md:bi-block">
        <slot />
      </div>
    </div>
    <slot name="right-side" />
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "80px",
    },
  },
  data() {
    return {};
  },
  components: {},
};
</script>

<style></style>
