import $axios from "@/plugins/axios";

export const sendInquiry = (data, params = null) => {
  return $axios.post("inquiries", data, { params: params }).then((res) => {
    return res.data;
  });
};

export const inquiryList = (params = null) => {
  return $axios.get("inquiries", { params: params }).then((res) => {
    return res.data;
  });
};

export const getDetail = (id, params = null) => {
  return $axios.get(`inquiries/${id}`, { params: params }).then((res) => {
    return res.data;
  });
};

export const inquiry = (id, params = null) => {
  return $axios.get(`orders/${id}/inquiry/invoice`, { params: params }).then((res) => {
    return res.data;
  });
};
