<template>
  <div class="bi-p-4 bi-flex bi-flex-col bi-justify-center bi-items-center bi-w-full bi-h-full bi-flex-nowrap">
    <s-icon name="IconMdiTruckOutline" class="bi-w-24 bi-h-24 bi-text-primary-default" />
    <span class="bi-block bi-text-lg bi-font-bold bi-my-4">Truck is Empty!</span>
    <div>
      <s-button label="Order Now" color="secondary" theme="outline" size="lg" :to="{ name: 'home' }" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
