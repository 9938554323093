<template>
  <div class="bi-flex bi-items-center bi-border-b bi-border-grey-lightest-ultra">
    <s-button size="md" color="grey" class="project-category-btn" theme="tab" label="Active" :to="{ name: 'active-project-list' }" />
    <s-button size="md" color="grey" class="project-category-btn" theme="tab" :to="{ name: 'archive-project-list' }" label="Archived" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
