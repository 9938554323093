<template>
  <div class="bi-flex bi-gap-4 bi-items-center lg:bi-flex-nowrap">
    <section class="bi-flex lg:bi-flex-nowrap bi-gap-1 sm:bi-gap-4">
      <div v-for="(option, index) in options" :key="`button--${index}`">
        <s-button
          :label="option.label"
          :color="checked == option.value ? 'success' : 'primary'"
          :theme="checked == option.value ? 'solid' : 'outline'"
          :for="option.id"
          :active="checked == option.value"
          @click.native="onSelected(option.value)"
        />
        <p v-if="option.amount" class="bi-mt-2 bi-font-bold bi-text-danger-default">+ {{ formatPriceWithoutDecimal(option.amount) }}/{{ unit }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import { formatPriceWithoutDecimal } from "@/utils/common";
export default {
  props: {
    options: {
      type: Array,
    },
    unit: {
      type: String,
    },
  },
  created() {
    if (!this.checked) {
      // Setting initial selected option on load.
      this.checked = this.options[0].value;
      this.$emit("selected", { value: this.checked, amount: this.extraAmount });
    }
  },
  computed: {
    extraAmount() {
      const result = this.options.find((option) => {
        return option.id == this.checked;
      });

      return result ? result.amount : 0;
    },
  },
  data() {
    return {
      checked: null,
    };
  },
  watch: {
    // REFACTOR Ask harsh bhai about better solution.
  },
  methods: {
    formatPriceWithoutDecimal,
    emitSelectedEvent(id) {
      this.$emit("selected", { value: id, amount: this.extraAmount });
    },
    onSelected(value) {
      this.checked = value;
      this.emitSelectedEvent(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
