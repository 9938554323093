<template>
  <div class="product-counter-container">
    <s-button v-if="quantity == 0" size="sm" color="accent" theme="muted" rounded class="add-item" label="Add" @click.native="addInitialQuantity" />
    <template v-else>
      <div class="bi-flex bi-items-center bi-gap-2 bi-flex-nowrap expanded-counter bi-justify-center">
        <s-button size="sm" color="success" class="" rounded icon="IconIcRoundMinus" @click.native="decrease" />
        <div class="bi-border bi-rounded bi-border-grey-lightest-double-ultra bi-px-6 bi-py-1 bi-h-8 bi-w-32">{{ quantity }} {{ unit }}</div>

        <s-button size="sm" rounded color="success" class="" icon="IconIcRoundPlus" @click.native="increase" />

        <s-button size="sm" theme="muted" color="danger" label="Remove" rounded v-if="quantity > 0" @click.native="removeProduct" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cartMixin from "@/mixins/cartMixin";

export default {
  props: [
    "id",
    "price",
    "quantityOfOrder",
    "unit",
    "categoryId",
    "brandId",
    "brandName",
    "loading",
    "unloading",
    "freight",
    "gstPercentage",
    "isGstIncluded",
    "categoryName",
    "item",
  ],
  mixins: [cartMixin],
  data() {
    return {
      quantity: 0,
      interval: 1000,
    };
  },
  created() {
    this.setInterval();
    // below event registration is to solve issue of counter state not updating after truck is cleared from button. therfore manually resetting.
    this.$root.$on("truck-got-empty", () => {
      this.quantity = 0;
    });

    this.$root.$on("truck-got-empty-and-items-replaced", this.replaceProduct);
  },

  beforeDestroy() {
    this.$root.$off("truck-got-empty-and-items-replaced", this.replaceProduct);
  },

  computed: {
    // gstAmount() {
    //   return Number(((this.price / (100 + this.gstPercentage)) * this.gstPercentage).toFixed(2));
    // },
    // basePrice() {
    //   return Number((this.price - this.gstAmount).toFixed(2));
    // },
    // totalGstAmount() {
    //   return this.gstAmount * this.quantity;
    // },
    // totalBasePrice() {
    //   return this.basePrice * this.quantity;
    // },

    isUnitInBag() {
      if (this.unit.toLowerCase() == "bag") return true;
      else return false;
    },

    gstAmount() {
      // GST EXcluded , therefore adding up
      // REFACTOR below conversion of 2 decimal places and casting to Number.
      // return Number((this.totalAmount * (this.gstPercentage / 100)).toFixed(2));

      return (this.totalAmount / (100 + this.gstPercentage)) * this.gstPercentage;
    },
    basePrice() {
      // return Number((this.price - this.gstAmount).toFixed(2));
      return this.totalAmount - this.gstAmount;
    },
    totalAmount() {
      if (this.getExtraTimeCharges) {
        return (this.price + this.getExtraTimeCharges) * this.quantity;
      } else return this.price * this.quantity;
    },
  },

  mounted() {
    if (this.quantityOfOrder) {
      this.quantity = this.quantityOfOrder;
    }
  },
  methods: {
    ...mapActions(["addToCart", "updateCart"]),

    removeProduct() {
      this.removeCementProductFromCart();
      if (this.cartProducts && !this.cartProducts.length) {
        this.emptyCart();
      }
    },

    removeCementProductFromCart() {
      let product = this.findProductInCart(this.id);
      if (!product) return;
      let oldQuantity = product.quantity;

      this.removeProductCountFromTotalQuantityOfCart();

      let arr = this.cartProducts.filter((item) => {
        return item.product_id != product.product_id;
      });

      this.updateCart(arr);

      this.quantity = 0;

      if (this.isUnitInBag) {
        this.setTotalQuantity(this.totalQuantity - this.convertBagsToMT(oldQuantity));
      } else {
        this.setTotalQuantity(this.totalQuantity - oldQuantity);
      }
    },

    replaceProduct({ id }) {
      if (this.categoryId == 1 && this.id == id) {
        this.addInitialQuantity();
      }
    },

    // TODO Ask backend to provide Dynamic interval based on unit instead of hard coding.

    setInterval() {
      if (this.isUnitInBag) this.interval = 1000;
      else this.interval = 42;
    },

    decrease() {
      this.quantity = this.quantity - this.interval;

      if (this.quantity == 0) this.removeProductCountFromTotalQuantityOfCart();

      const isProductPresent = this.checkIfProductIsPresentInCart();
      if (isProductPresent) {
        let product = this.findProductInCart(this.id);
        product.quantity = product.quantity - this.interval;

        if (product.quantity == 0) {
          if (this.isUnitInBag) {
            this.setTotalQuantity(this.totalQuantity - this.convertBagsToMT(this.interval));
          } else this.setTotalQuantity(this.totalQuantity - this.interval);
          let arr = this.cartProducts.filter((item) => {
            return item.product_id != product.product_id;
          });

          this.updateCart(arr);

          if (this.cartProducts && !this.cartProducts.length) {
            this.emptyCart();
          }
        } else {
          let total = product.quantity * this.price;
          product.total = total;
          product.basePrice = this.basePrice;
          product.gstAmount = this.gstAmount;
          // product.totalGstAmount = this.totalGstAmount;
          // product.totalBasePrice = this.totalBasePrice;
          this.updateCart(this.cartProducts);

          if (this.isUnitInBag) {
            this.setTotalQuantity(this.totalQuantity - this.convertBagsToMT(this.interval));
          } else this.setTotalQuantity(this.totalQuantity - this.interval);
        }
      }
    },

    removeProductCountFromTotalQuantityOfCart() {
      this.setTotalNoOfProductsInCart(this.totalNoOfProductsInCart - 1);
    },

    addProductCountInTotalQuantityOfCart() {
      this.setTotalNoOfProductsInCart(this.totalNoOfProductsInCart + 1);
    },
    addInitialQuantity() {
      let isBrandSameInCart;
      const isBrandPresentInCart = this.checkIfBrandIsPresentInCart();

      if (isBrandPresentInCart) {
        isBrandSameInCart = this.checkIfBrandIsSameInCart(this.brandId);
      } else {
        this.setBrandIdInCart(this.brandId);
        this.setBrandNameInCart(this.brandName);
        this.setUnitInCart("MT");
        isBrandSameInCart = true;
      }

      const isCategorySameInCart = this.checkIfCategoryIsSameInCart(this.categoryId);

      if (isCategorySameInCart && isBrandSameInCart) {
        this.quantity = this.interval;
        this.setLoadingOption(this.loading);
        this.setUnloadingOption(this.unloading);
        this.setFreightOption(this.freight);
        this.addProductCountInTotalQuantityOfCart();

        this.addToCart({
          // totalGstAmount: this.totalGstAmount,
          // totalBasePrice: this.totalBasePrice,
          price: this.price,
          product_id: this.id,
          quantity: this.quantity,
          gstPercentage: this.gstPercentage,
          // total: this.price * this.interval,
          total: this.totalAmount,
          basePrice: this.basePrice,
          gstAmount: this.gstAmount,
        });

        this.setGstPercentage(this.gstPercentage);
        this.setGstIncluded(this.isGstIncluded);

        if (this.isUnitInBag) {
          this.setTotalQuantity(this.totalQuantity + this.convertBagsToMT(this.interval));
        } else {
          this.setTotalQuantity(this.totalQuantity + this.interval);
        }
      } else this.alertDifferentOrderCategoryNotAllowed(this.id);
    },

    increase() {
      const isCategorySameInCart = this.checkIfCategoryIsSameInCart(this.categoryId);

      if (isCategorySameInCart) {
        this.quantity = this.quantity + this.interval;
        this.setCategoryNameInCart(this.categoryNameFromHomeScreen);

        const isProductPresent = this.checkIfProductIsPresentInCart();
        if (isProductPresent) {
          let product = this.findProductInCart(this.id);

          product.quantity = product.quantity + this.interval;
          let total = product.quantity * this.price;
          // product.total = total;
          product.gstPercentage = this.gstPercentage;

          product.total = this.totalAmount;
          product.basePrice = this.basePrice;
          product.gstAmount = this.gstAmount;

          // product.totalGstAmount = this.totalGstAmount;
          // product.totalBasePrice = this.totalBasePrice;
          this.updateCart(this.cartProducts);
        } else {
          this.addToCart({
            // totalGst: this.totalGstAmount,
            // totalBasePrice: this.totalBasePrice,
            price: this.price,
            product_id: this.id,
            quantity: this.quantity,
            // total: this.price * this.interval,
            total: this.totalAmount,
            basePrice: this.basePrice,
            gstAmount: this.gstAmount,
          });
        }

        // this.setTotalQuantity(this.totalQuantity + this.interval);
        if (this.isUnitInBag) {
          this.setTotalQuantity(this.totalQuantity + this.convertBagsToMT(this.interval));
        } else {
          this.setTotalQuantity(this.totalQuantity + this.interval);
        }
      } else {
        this.alertDifferentOrderCategoryNotAllowed(this.id);
      }
    },
  },
};
</script>

<style></style>
