<template>
  <footer
    class="md:bi-flex bi-gap-2 bi-justify-between bi-items-center bi-py-6 bi-flex-nowrap md:bi-px-2 bi-text-center"
    :class="{
      'bi-bg-danger-lightest': isKycRejected() || isKycPending(),
    }"
  >
    <kyc-status :status="kycStatus" />
    <s-button
      v-if="isKycPending() || isKycInComplete()"
      color="secondary"
      class="bi-flex-grow-0"
      label="Complete Your KYC"
      :to="{ name: 'upsert-company', params: { id: companyId.toString() } }"
    ></s-button>
    <s-button
      v-else-if="isKycRejected()"
      color="secondary"
      class="bi-flex-grow-0"
      label="Update Your KYC"
      :to="{ name: 'complete-kyc', params: { id: companyId.toString() } }"
    ></s-button>
  </footer>
</template>

<script>
import kycMixin from "@/mixins/kycMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [kycMixin],
  computed: {
    ...mapGetters({
      companyId: "user/companyId",
    }),
  },
  components: {
    KycStatus: require("@/components/KycStatus").default,
  },
};
</script>

<style>
</style>