<template>
  <div>
    <step-1 :id="id" />
  </div>
</template>

<script>
export default {
  components: {
    Step1: require("./Step1.vue").default,
  },
  props: ["id"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
