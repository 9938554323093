export const HYDRATING = "⏳ Hydrating";
export const HYDRATED = "⌛️ Hydrated";
export const HYDRATION_FAILED = "🔴 Hydration Failed";
export const SET_USER = "😎 Set User";
export const ADD_TO_CART = "Add to Cart";
export const UPDATE_CART = "Update Cart";
export const SET_TOTAL_PRICE = "Set Total Price of Cart";
export const SET_PROJECT_ID = "Set Project ID";
export const SET_TOTAL_QUANTITY = "Set Total Quantity";
export const RESET_CART = "Reset Cart";
export const SET_CATEGORY_ID = "Set Category ID";
export const SET_CATEGORY_ID_IN_CART = "Set Category ID in Cart";
export const SET_TOTAL_NO_OF_PRODUCTS_IN_CART = "Set Total No Of Products in Cart";
export const SET_CATEGORY_NAME = "Set Category Name";
export const SET_CATEGORY_NAME_IN_CART = "Set Category Name in cart";
export const SET_CATEGORIES = "Set Categories";
export const SET_BRAND_ID_IN_CART = "Set Brand ID in Cart";
export const SET_BRAND_NAME_IN_CART = "Set Brand name in cart";
export const SET_UNIT = "Set Unit";
export const SET_LOADING_IN_CART = "Set brand loading option";
export const SET_UNLOADING_IN_CART = "Set brand Unloading option";
export const SET_FREIGHT_IN_CART = "Set Freight Option in cart";
export const SET_GST_PERCENTAGE = "Set Gst Percentage";
export const SET_GST_INCLUDED = "Set Is Gst Included";
export const RESET_STATE = "Reset State";
export const SET_EXTRA_TIME_CHARGES = "Set Extra time charges";
export const SET_COMPANY_TYPE = "Set company type";
export const SET_TOTAL_CART_AMOUNT = "Set Total cart amount";
