<template>
  <span>
    {{ formatPrice(price) }}<span v-if="unit">/ {{ unit }} </span></span
  >
</template>

<script>
import { formatPrice } from "@/utils/common.js";
export default {
  props: {
    price: {
      type: [String, Number],
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>

<style></style>
