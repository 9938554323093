<template>
  <img :src="brand.logo.url" class="bi-rounded-md bi-object-contain" :class="`bi-w-${width} bi-h-${width}`" v-tooltip="brand.name" />
</template>

<script>
export default {
  data() {
    return {
      sizeMap: {
        sm: 14,
        md: 16,
        lg: 20,
      },
    };
  },
  computed: {
    width() {
      return this.sizeMap[this.size];
    },
    height() {
      return this.sizeMap[this.size];
    },
  },
  props: {
    size: {
      type: String,
      default: "md",
    },
    // width: {
    //   type: String,
    //   default: "10",
    // },
    brand: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
