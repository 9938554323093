<template>
  <s-button label="Credit limit Exceeded" color="danger" @click.native="goToInquiry" theme="muted" size="sm" />
</template>

<script>
import { inquiryType } from "@/data/index";
import messages from "@/data/message";
export default {
  props: ["product"],
  methods: {
    goToInquiry() {
      this.$shilp.notify({
        title: messages.creditLimitExceeded,
        type: "info",
        duration: 6000,
      });

      this.$router.push({
        name: "inquiry-create",
        params: { product: this.product, fieldsDisabled: true, inquiryType: inquiryType.creditLimitCrossed },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
