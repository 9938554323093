<template>
  <div class="bi-rounded-md bi-grid bi-text-grey-default bi-items-center bi-gap-2" style="grid-template-columns: auto 30px auto">
    <!-- Base Price  -->
    <h4 class="bi-text-grey-default bi-text-sm sm:bi-text-lg bi-font-light sm:bi-font-normal">Base Price</h4>
    <span>:</span>
    <!-- <h4 class="bi-font-bold">{{ formatPrice(getTotalBasePriceFromCart()) }}</h4> -->
    <h4 class="bi-font-light sm:bi-font-bold bi-text-sm sm:bi-text-lg">{{ formatPrice(basePrice) }}</h4>

    <!-- GST Section -->
    <h4 class="bi-text-grey-default bi-text-sm sm:bi-text-lg bi-font-light sm:bi-font-normal">GST</h4>
    <span>:</span>
    <!-- <h4 class="bi-font-bold">{{ formatPrice(getTotalGstAmountFromCart()) }}</h4> -->
    <h4 class="bi-font-light sm:bi-font-bold bi-text-sm sm:bi-text-lg">{{ formatPrice(gstPrice) }}</h4>

    <template v-if="subtotal">
      <h4 class="bi-text-grey-default bi-text-sm sm:bi-text-lg bi-font-light sm:bi-font-normal">Subtotal</h4>
      <span>:</span>
      <h4 class="bi-font-light sm:bi-font-bold bi-text-sm sm:bi-text-lg">{{ formatPrice(subtotal) }}</h4>
    </template>

    <!-- Extra Charges for later payment -->
    <!-- <h4 class="bi-text-grey-default">Payment Option</h4> -->
    <!-- <h4 class="bi-text-grey-default bi-font-normal">20 Days</h4>
    <span>:</span>
    <h4 class="bi-font-bold">+ {{ formatPrice(300) }}</h4> -->

    <!-- Additional Service option -->
    <template v-if="loading">
      <h4 class="bi-font-light sm:bi-font-normal bi-text-sm sm:bi-text-lg bi-text-grey-default">Loading</h4>
      <span>:</span>
      <!-- <h4 class="bi-font-bold">Included</h4> -->
      <Tag :label="loadingLabel" :color="loadingColor" width="50px" />
    </template>

    <template v-if="unloading">
      <h4 class="bi-font-light sm:bi-font-normal bi-text-sm sm:bi-text-lg bi-text-grey-default">UnLoading</h4>
      <span>:</span>
      <!-- <h4 class="bi-font-bold">Extra</h4> -->
      <Tag :label="unloadingLabel" :color="unloadingColor" width="50px" />
    </template>

    <template v-if="freight">
      <h4 class="bi-font-light sm:bi-font-normal bi-text-sm sm:bi-text-lg bi-text-grey-default">Freight</h4>
      <span>:</span>
      <!-- <h4 class="bi-font-bold">Included</h4> -->
      <Tag :label="freightLabel" :color="freightColor" width="50px" />
    </template>

    <!-- Grand Total -->
    <template v-if="grandTotal">
      <h4 class="bi-font-light sm:bi-font-normal bi-text-sm sm:bi-text-lg bi-text-grey-default">Grand Total</h4>
      <span>:</span>
      <h3 class="bi-font-light bi-text-sm sm:bi-text-lg sm:bi-font-bold">{{ formatPrice(grandTotal) }}</h3>
    </template>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/common.js";
// import cartMixin from "@/mixins/cartMixin";
export default {
  // mixins: [cartMixin],
  // REFACTOR Use Loading option Component and other component. Reusability
  components: {
    Tag: require("@/components/Tag").default,
  },
  computed: {
    loadingLabel() {
      if (this.loading) return "Included";
      else return "Extra";
    },
    loadingColor() {
      if (this.loading) return "green";
      else return "red";
    },
    unloadingLabel() {
      if (this.unloading) return "Included";
      else return "Extra";
    },
    unloadingColor() {
      if (this.unloading) return "green";
      else return "red";
    },
    freightLabel() {
      if (this.freight) return "Included";
      else return "Extra";
    },
    freightColor() {
      if (this.freight) return "green";
      else return "red";
    },
  },
  props: {
    basePrice: Number,
    gstPrice: Number,
    loading: Number,
    unloading: Number,
    freight: Number,
    subtotal: {
      type: Number,
    },
    grandTotal: {
      type: Number,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>

<style lang="scss" scoped></style>
