<template>
  <div class="bi-flex bi-items-center bi-gap-2">
    <s-icon name="IconMdiInformationOutline" class="bi-text-lg bi-inline" />
    <span class="bi-text-grey-default bi-font-bold"> GST (Included) </span>
  </div>
</template>

<script>
import cartMixin from "@/mixins/cartMixin";
export default {
  mixins: [cartMixin],
};
</script>

<style lang="scss" scoped></style>
