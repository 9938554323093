<template>
  <div>
    <s-field label="Invoice(s)">
      <div>
        <VueFileAgentWrapper
          theme="list"
          :linkable="true"
          baseUrl="https://api-bii.preview.im/api/v1"
          endpoint="medias"
          :readonly="true"
          :value="invoiceItem.invoice_media"
          :isDeletable="false"
          v-for="invoiceItem in value"
          :key="`invoice-display-${invoiceItem.id}`"
        />
      </div>
      <!-- :thumbnailSize="150" -->
    </s-field>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
  },
};
</script>

<style lang="scss">
.invoice-display {
  .file-preview-new {
    display: none !important;
  }
}
</style>
