export default [
  {
    path: "truck",
    component: require("@/views/truck/Index").default,
    props: true,
    children: [
      {
        path: "",
        name: "truck",
        component: require("@/views/truck/List").default,
        meta: {
          label: "Truck",
          breadcrumbs: ["home", "truck"],
          icon: "IconMdiTruckOutline",
        },
      },
    ],
  },
];
