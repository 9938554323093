<template>
  <div
    class="bi-p-6 bi-flex bi-flex-col bi-justify-center bi-items-center bi-w-full bi-h-full bi-flex-nowrap"
  >
    <s-icon
      name="IconMdiCartOutline"
      class="bi-w-24 bi-h-24 bi-text-primary-default"
    />
    <span class="block bi-text-lg bi-font-bold bi-my-6">No Orders Found! </span>
    <s-button
      label="Order Now"
      color="secondary"
      theme="outline"
      size="lg"
      :to="{ name: 'home' }"
    />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
