<template>
  <div>
    <modal size="sm" title="Deactivate Account" id="deactivate-account-modal">
      <!-- Title comes here  -->
      <!-- Sub Header Message Section -->
      <template #message>
        <p
          class="bi-text-md bi-text-grey-default bi-font-display bi-font-medium"
        >
          Are you sure, you want to deactivate account?<br />
          You cannot Login after deactivation.
        </p>
      </template>
      <div class="bi-mt-8 bi-flex bi-flex-nowrap">
        <s-button
          label="Cancel"
          color="transparent"
          align="center"
          size="lg"
          class="bi-flex-auto"
          theme="outline"
          @click.native="$shilp.modal.close('deactivate-account-modal')"
        />
        <s-button
          align="center"
          color="danger"
          theme="muted"
          size="lg"
          label="Deactivate"
          class="bi-ml-2 bi-flex-auto bi-font-bold"
          @click.native="onSubmit"
          :loader="saving"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import deactivateAccount from "@/graph/deactivateAccount.gql";
export default {
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    async onSubmit() {
      this.saving = true;
      try {
        await this.$graph(deactivateAccount);
        this.$shilp.modal.close("deactivate-account-modal");
      } catch {
        this.$shilp.notify({
          title: "Try Again. Error Occured",
          type: "danger",
        });
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style></style>
