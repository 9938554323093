export default [
  {
    path: "projects",
    component: require("@/views/project/Index").default,
    props: true,
    name: "projects",
    redirect: { name: "active-project-list" },
    children: [
      {
        path: "active",
        name: "active-project-list",
        component: require("@/views/project/List").default,
        meta: {
          label: "Active Projects",
          breadcrumbs: ["home", "active-project-list"],
          icon: "IconMdiFormatListBulleted",
        },
      },
      {
        path: "archive",
        name: "archive-project-list",
        component: require("@/views/project/archiveList").default,
        meta: {
          label: "Archive Projects",
          breadcrumbs: ["home", "archive-project-list"],
          icon: "IconMdiFormatListBulleted",
        },
      },
      {
        path: "project/:id",
        name: "project-item",
        component: require("@/views/project/Item").default,
        props: true,
        meta: {
          label: "Project",
          breadcrumbs: ["projects", "project-item"],
        },
      },
      {
        path: "project-incharge/:id",
        name: "project-incharge-item",
        component: require("@/views/project/Step2").default,
        props: true,
        meta: {
          label: "Project Incharge",
          breadcrumbs: ["projects", "project-incharge-item"],
        },
      },
    ],
  },
];
