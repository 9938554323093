<template>
  <modal size="sm" title="OTP Verification" id="otp-modal" @close="resetModal">
    <template #message>
      <p>You need to verify ownership before editing details</p>
    </template>
    <template #default="{ data }">
      <ValidationProvider rules="required">
        <otp-handler
          v-model.number="code"
          :get="getOtp"
          :showSendToPartnerField="data.showSendToPartnerField ? data.showSendToPartnerField : false"
        />
      </ValidationProvider>
      <div class="bi-mt-8 bi-flex bi-flex-nowrap">
        <s-button
          :loader="isLoading"
          color="secondary"
          label="Submit"
          @click.native="verifyCode(data.redirect ? data.redirect : null)"
          :disabled="!code"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import getCode from "@/graph/kyc/partner/otp.gql";
import verify from "@/graph/otp/verify.gql";
import { mapGetters } from "vuex";
export default {
  components: {
    OtpHandler: require("@/components/OtpHandler").default,
  },
  computed: {
    ...mapGetters({
      user: "user/get",
      companyId: "user/companyId",
    }),
  },
  data() {
    return {
      isLoading: false,
      code: null,
    };
  },
  created() {},
  methods: {
    resetModal() {
      this.code = null;
      this.isLoading = false;
    },
    verifyCode(redirect) {
      this.isLoading = true;
      this.$graphPublic(verify, {
        username: "",
        password: "",
        code: this.code,
        codeFor: "change_kyc",
      })
        .then(() => {
          if (redirect)
            this.$router.push({
              name: redirect,
              params: { id: String(this.companyId) },
            });
          this.$root.$emit("shilp-modal-close", "otp-modal");
        })
        .catch((error) => {
          this.$shilp.notify({
            title: error[0].message,
            type: "danger",
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.code = null;
        });
    },
    getOtp(params = null) {
      // Params contains { defaultPartnerOtpSend value }

      return this.$graph(getCode, {
        input: {
          mobileNo: this.user.mobileNo,
          codeFor: "change_kyc",
          ...params,
        },
      }).then((res) => {
        return res.sendCodeForAuthMutation.code;
      });
    },
  },
};
</script>
