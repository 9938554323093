export default [
  {
    path: "account",
    name: "account-page",
    redirect: { name: "company-detail" },
    component: require("@/views/account/Index").default,
    children: [
      {
        path: "company-detail",
        name: "company-detail",
        component: require("@/views/account/CompanyDetail").default,
        meta: {
          label: "Company Details",
          icon: "IconMdiAccountCircleOutline",
          breadcrumbs: ["home", "company-detail"],
        },
      },
      {
        path: "profile",
        name: "my-profile",
        component: require("@/views/account/Profile").default,
        meta: {
          label: "My Profile",
          icon: "IconMdiAccountCircleOutline",
          breadcrumbs: ["home", "my-profile"],
        },
      },
      // {
      //   path: "change-password",
      //   name: "change-password",
      //   component: require("@/views/account/ChangePassword").default,
      //   meta: {
      //     label: "Change Password",
      //     icon: "IconMdiLockOpenOutline",
      //     breadcrumbs: ["change-password"],
      //   },
      // },
      {
        path: "sign-in-history",
        name: "sign-in-history",
        component: require("@/views/account/SignInHistory").default,
        meta: {
          label: "Sign In History",
          breadcrumbs: ["home", "sign-in-history"],
          icon: "IconMdiAccountCircleOutline",
        },
      },
      {
        path: "credits",
        name: "credit-section",
        component: require("@/views/account/Credits").default,
        meta: {
          label: "Credits",
          breadcrumbs: ["home", "credit-section"],
          icon: "IconMdiAccountCircleOutline",
        },
      },
      {
        path: "privacy-policy",
        name: "privacy-policy",
        component: require("@/views/account/PrivacyPolicy").default,
        meta: {
          label: "Privacy Policy",
          breadcrumbs: ["home", "privacy-policy"],
          icon: "IconMdiAccountCircleOutline",
        },
      },
      {
        path: "terms&conditions",
        name: "terms-conditions",
        component: require("@/views/account/Terms&Conditions").default,
        meta: {
          label: "Terms & Conditions",
          breadcrumbs: ["home", "terms-conditions"],
          icon: "IconMdiAccountCircleOutline",
        },
      },
      {
        path: "inquiry",
        name: "inquiry-section",
        redirect: { name: "inquiry-list" },
        component: require("@/views/account/inquiry/Index").default,
        children: [
          {
            path: "list",
            name: "inquiry-list",
            component: require("@/views/account/inquiry/List").default,
            meta: {
              label: "Inquiry",
              breadcrumbs: ["home", "inquiry-list"],
              icon: "IconMaterialSymbolsContactPhoneOutline",
              parent: "inquiry",
            },
          },
          {
            path: "create",
            name: "inquiry-create",
            props: (route) => ({ routeParams: route.params }),
            component: require("@/views/account/inquiry/Create").default,
            meta: {
              label: "Create Inquiry",
              breadcrumbs: ["inquiry-list", "inquiry-create"],
              icon: "IconMaterialSymbolsContactPhoneOutline",
              parent: "inquiry",
            },
          },
          {
            path: "view/:id",
            name: "inquiry-view",
            props: (route) => ({ routeParams: { id: Number(route.params.id) } }),
            component: require("@/views/account/inquiry/View").default,
            meta: {
              label: "View Inquiry",
              breadcrumbs: ["inquiry-list", "inquiry-view"],
              icon: "IconMaterialSymbolsContactPhoneOutline",
              parent: "inquiry",
            },
          },
        ],
      },
    ],
  },
];
