<template>
  <div class="layout__wrap bi-w-full bi-h-screen">
    <iframe
      src="https://build-it-india.s3.ap-south-1.amazonaws.com/policy/Privacy_policy_website_builditindia.pdf"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
