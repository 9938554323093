<template>
  <s-modal v-bind="$attrs" overlay="dark" #default="scope" :full-height="fullHeight" @close="$emit('close')" @open="$emit('open')">
    <div class="w-modal bi-bg-white bi-rounded-xl bi-p-6" :class="{ 'w-modal--full-height': fullHeight }">
      <!-- HEADER -->
      <header class="bi-flex bi-items-center bi-justify-between bi-pb-2 bi-text-primary-default">
        <h3>{{ title }}</h3>
        <s-button
          v-if="close"
          class="bi-flex-shrink-0"
          icon="IconIcRoundClose"
          theme="muted"
          color="grey"
          shape="circle"
          size="xs"
          @click.native="$shilp.modal.close($attrs.id)"
        />
      </header>

      <!-- Sub Header  -->
      <h4 class="bi-text-grey-default">
        <slot name="message"></slot>
      </h4>
      <!-- CONTENT -->
      <div class="w-modal__content">
        <slot :id="$attrs.id" :data="scope" />
      </div>

      <!-- FOOTER -->
      <footer v-if="hasFooter" class="bi-flex bi-mt-4 t bi-items-center bi-flex-nowrap">
        <div class="bi-shrink">
          <slot name="footer-actions">
            <s-button v-if="reset && $listeners.reset" color="grey" theme="muted" @click.native="$emit('reset')" :label="resetLabel" />
            <s-button
              v-if="save && $listeners.save"
              class="bi-ml-2"
              color="primary"
              @click.native="$emit('save')"
              :icon="saveIcon"
              :label="saveLabel"
              :loader="saving"
            />
          </slot>
        </div>
      </footer>
    </div>
  </s-modal>
</template>

<script>
export default {
  props: {
    title: String,
    saving: { type: Boolean, default: false },
    fullHeight: { type: Boolean, default: false },
    reset: { type: Boolean, default: true },
    save: { type: Boolean, default: true },
    saveLabel: {
      type: String,
      default: "Save",
    },
    resetLabel: {
      type: String,
      default: "Reset",
    },
    saveIcon: {
      type: String,
      default: null,
    },
    close: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasFooter() {
      return this.$listeners.save || this.$listeners.reset || this.$scopedSlots["footer-note"] || this.$scopedSlots["footer-actions"];
    },
  },
};
</script>

<style lang="scss"></style>
