import $axios from "@/plugins/axios";

export const getAll = (params = null) => {
  return $axios.get("orders", { params }).then((res) => {
    return res.data;
  });
};

export const getDetails = (id, params = null) => {
  return $axios.get(`orders/${id}`, { params }).then((res) => {
    return res.data;
  });
};

export const create = (data, params = null) => {
  return $axios.post("orders", data, { params: params }).then((res) => {
    return res.data;
  });
};

export const cancel = (id, params = null) => {
  return $axios.post(`orders/cancel/${id}`, { params }).then((res) => {
    return res.data;
  });
};

export const downloadHistory = (params = null) => {
  return $axios.get("orders/export/data", { params }).then((res) => res.data);
};

export const downloadPo = (id, params = null) => {
  return $axios.get(`orders/${id}/orderDetailPdf`, { params }).then((res) => res.data);
};

export const getOrderCount = (params = null) => {
  return $axios.get(`orders/ordercount/orderCountAll`, { params }).then((res) => res.data);
};

export const confirm = (id, data, params = null) => {
  return $axios.put(`orders/${id}/confirm`, data, { params: params }).then((res) => {
    return res.data;
  });
};
