<template>
  <item-form
    class="bi-p-6"
    :item="id"
    :save="save"
    :fields="fields"
    :breadcrumb="false"
    :form-state.sync="formState"
    :redirect="false"
    width="100"
    :notify="false"
    :get="get"
    :gap="2"
    ref="item"
  >
    <template #default="{ form }">
      <!-- GST NO  -->
      <div class="bi-py-2 bi-space-y-1" v-if="company">
        <div class="bi-text-primary-default bi-rounded-sm bi-font-display bi-font-normal bi-bg-grey-lightest-double-ultra gstin-no">
          <s-field label="GSTIN Number" name="GSTIN Number" class="bi-py-3">
            <s-textbox type="text" :disabled="true" v-model="company.gstNo" />
          </s-field>
        </div>
        <!-- Pan No -->
        <div class="bi-text-primary-default bi-rounded-sm bi-font-display bi-font-normal bi-bg-grey-lightest-double-ultra gstin-no">
          <s-field-validate label="Pan No" name="Pan No" class="bi-p-1">
            <s-textbox type="text" :disabled="true" :value="company.panNo" />
          </s-field-validate>
        </div>

        <label
          v-if="company.name"
          class="bi-text-black bi-rounded-sm bi-font-normal bi-font-display bi-block bi-text-center bi-py-4 bi-bg-grey-lightest-double-ultra bi-text-base"
        >
          {{ company.name }}</label
        >
      </div>

      <!-- Type of Company -->
      <s-field-validate name="Company Type" rules="required">
        <CustomDropDown
          class="bi-pointer-events-none"
          v-if="companyTypeOptions && companyTypeOptions.length"
          :options="companyTypeOptions"
          v-model="form.type"
          placeholder="Select Company Type"
        />
      </s-field-validate>

      <!-- Mobile NO  -->

      <CustomMobileInput v-model="form.mobileNo" placeholder="Company Number" :is-disabled="true" />
    </template>

    <template #footer="{ saving }">
      <div class="bi-flex bi-items-center">
        <!-- <s-button class="bi-p-2" theme="outline" label="Cancel" :to="{ name: 'home' }" /> -->
        <s-button label="Next" :loader="saving" color="secondary" align="center" type="submit" />
      </div>
    </template>
  </item-form>
</template>

<script>
import upsert from "@/graph/kyc/company/upsert.gql";
import getCompanyTypeList from "@/graph/kyc/company/collection.gql";
import get from "@/graph/kyc/get.gql";
import { mapGetters, mapActions } from "vuex";
import kycMixin from "@/mixins/kycMixin";
export default {
  components: {
    CustomDropDown: require("@/components/CustomDropDown").default,
    CustomMobileInput: require("@/components/CustomMobileInput").default,
  },
  mixins: [kycMixin],
  data() {
    return {
      fields: ["type", "mobileNo"],
      mobileNoFromApi: null,
      formState: {
        mobileNo: null,
        type: 6,
      },
      companyTypeFromApi: null,
      companyTypeOptions: [],
    };
  },
  created() {
    this.getData();
  },
  props: ["id"],
  computed: {
    ...mapGetters({
      company: "user/company",
      companyId: "user/companyId",
    }),
  },
  methods: {
    ...mapActions({ setCompanyType: "user/setCompanyType" }),
    get() {
      return this.$graph(get, {
        id: this.id,
      }).then((res) => {
        res.company.type = res.company.type.id;

        this.mobileNoFromApi = res.company.mobileNo;
        this.companyTypeFromApi = res.company.type;
        return res.company;
      });
    },
    async getData() {
      let res = await this.$graph(getCompanyTypeList);
      this.companyTypeOptions = res.masterCompanyType.data.map((item) => {
        return {
          name: item.displayName,
          value: item.id,
        };
      });
    },
    async save(id, data) {
      if (id) {
        // this if condition is if form is in update mode
        const isDataUpdated = this.checkIfDataHasUpdated();

        if (isDataUpdated) {
          await this.$graph(upsert, {
            input: data,
          });

          const type = await this.getCompanyTypeFromApi();
          this.setCompanyType(type);
        }

        this.$router.push({
          name: "upsert-document",
          params: { id: this.companyId.toString() },
        });
      } else {
        // this else if for form is in create mode
        await this.$graph(upsert, {
          input: data,
        });

        this.$router.push({
          name: "upsert-document",
          params: { id: "+" },
        });
      }
    },

    checkIfDataHasUpdated() {
      if (this.mobileNoFromApi == this.formState.mobileNo && this.companyTypeFromApi == this.formState.type) return false;
      else return true;
    },
  },
};
</script>

<style></style>
