<template>
  <div class="bi-sticky bi-inset-0">
    <p class="bi-text-grey-default bi-font-medium bi-pl-6 bi-pt-6">Categories</p>

    <s-nav style="margin-top: 12px" class="bi-gap-3.5" :fluid="!compact" stack align="left" :shape="compact ? 'square' : null">
      <template v-for="(category, index) in categories">
        <s-nav-item
          class="category-sidebar-item"
          :key="'sidebar' + index"
          :label="category.label"
          :class="{
            'nav__item--exact-active': isActive(category),
          }"
          v-if="category && category.label"
          @click.native="onCategorySelect(category)"
        />
      </template>
    </s-nav>
  </div>
</template>

<script>
export default {
  props: {
    categories: Array,
    selectedCategoryIdFromStore: Number,
  },
  computed: {},
  data() {
    return {
      compact: false,
      selected: null,
    };
  },
  watch: {
    selectedCategoryIdFromStore(newValue) {
      if (newValue) this.selected = newValue;
    },
  },
  created() {
    if (this.selectedCategoryIdFromStore) this.selected = this.selectedCategoryIdFromStore;
  },
  methods: {
    onCategorySelect(category) {
      this.selected = category.id;
      if (category.static) {
        this.$router.push({ name: "static-content", params: { categoryName: category.name } });
      } else {
        this.$emit("category-selected", category.id, category.name);
      }
    },

    isActive(category) {
      return this.selected == category.id;
    },
  },
};
</script>

<style lang="scss">
.category-sidebar-item {
  @apply hover:bi-bg-secondary-lightest-double-ultra bi-h-11;
  &.nav__item--exact-active {
    @apply bi-bg-secondary-lightest-double-ultra bi-border-r-2 bi-border-secondary-default;
    .button {
      @apply bi-text-base-lg bi-font-semibold;
    }
  }

  .button {
    @apply bi-pl-6 bi-text-base-lg bi-capitalize bi-text-grey-darkest-ultra  bi-font-normal bi-h-full;
  }
}
</style>
