import { mapGetters } from "vuex";
import { creditLimit } from "@/data/index";
export default {
  computed: {
    ...mapGetters({
      availableCredit: "user/availableCredit",
    }),
    creditLimitCrossed() {
      if (this.availableCredit > creditLimit) return false;
      else return true;
    },
  },
};
