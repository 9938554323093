<template>
  <sub-layout :leftBarVisible="false" :showMobileBar="false">
    <template #default>
      <KycDetailsHeader />
      <router-view>
        <portal to="header-actions"></portal>
      </router-view>
    </template>

    <template #right-side>
      <contact-us />
    </template>
  </sub-layout>
</template>

<script>
import { mapGetters } from "vuex";
import kycStatusOptions from "@/data/kyc";
export default {
  kycStatusOptions,
  components: {
    SubLayout: require("@/components/SubLayout").default,
    ContactUs: require("@/components/ContactUs").default,
    KycDetailsHeader: require("@/components/kyc/KycDetailsHeader").default,
  },
  computed: {
    ...mapGetters({
      kycStatus: "user/kycStatus",
    }),
  },
  created() {
    // if (this.kycStatus == this.$options.kycStatusOptions.approve) {
    //   this.$router.push({ name: "home" });
    // }
  },
};
</script>

<style></style>
