var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bi-flex bi-flex-wrap bi-gap-8"},[_c('item-form',{ref:"item",staticClass:"bi-p-6 bi-pb-0",class:{ 'lg:bi-border-r bi-border-grey-lightest-ultra': _vm.partnerList.length },staticStyle:{"width":"500px"},attrs:{"item":false,"form-state":_vm.formState,"notify":false,"save":_vm.addPartner,"fields":_vm.fields,"gap":2,"breadcrumb":false,"redirect":false},on:{"update:formState":function($event){_vm.formState=$event},"update:form-state":function($event){_vm.formState=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [_c('s-field-validate',{attrs:{"name":"Partner/Director Name","rules":"required"}},[_c('TextBoxCapitalize',{attrs:{"placeholder":"Partner/Director Name"},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}})],1),_c('CustomMobileInput',{model:{value:(form.mobileNo),callback:function ($$v) {_vm.$set(form, "mobileNo", $$v)},expression:"form.mobileNo"}}),_c('s-field-validate',{attrs:{"name":"Receive Notification"}},[_c('div',{staticClass:"bi-flex bi-items-start"},[_c('s-checkbox',{staticClass:"notification-checkbox",attrs:{"label":"Receive Notification","id":"enableNotification"},model:{value:(form.enableNotification),callback:function ($$v) {_vm.$set(form, "enableNotification", $$v)},expression:"form.enableNotification"}}),_c('v-popover',{attrs:{"trigger":"hover","popoverWrapperClass":"bi-w-auto"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('p',[_vm._v("All order related notifications will be sent to this partner.")])]},proxy:true}],null,true)},[_c('s-icon',{staticClass:"bi-text-secondary-default bi-w-5 bi-h-5 bi-ml-2",attrs:{"name":"IconMdiInformationOutline"}})],1)],1)]),_c('p',{staticClass:"bi-text-grey-darkest-ultra"}),_c('s-field-validate',{attrs:{"name":"Email","rules":"email"}},[_c('s-textbox',{attrs:{"type":"text","placeholder":"Email-optional"},model:{value:(form.email),callback:function ($$v) {_vm.$set(form, "email", $$v)},expression:"form.email"}})],1),_c('ValidationProvider',{attrs:{"rules":"required"}},[_c('otp-handler',{attrs:{"get":_vm.getOtp,"reset":_vm.resetOtp},model:{value:(form.code),callback:function ($$v) {_vm.$set(form, "code", $$v)},expression:"form.code"}})],1)]}},{key:"footer",fn:function(ref){
var saving = ref.saving;
return [_c('div',[(_vm.id == '+' || !_vm.isPartnerEditing)?_c('s-button',{attrs:{"label":"ADD","color":"success","loader":saving,"type":"submit"}}):_c('s-button',{attrs:{"label":"EDIT","color":"success","loader":_vm.isEditing},nativeOn:{"click":function($event){return _vm.edit.apply(null, arguments)}}}),_c('span',{staticClass:"bi-text-grey-darkest-ultra bi-block bi-mt-4"},[_vm._v("*Submit all details of at least one partner")])],1)]}}])}),_c('div',[(!_vm.isPartnerListLoading)?_c('div',{staticStyle:{"max-height":"500px","overflow":"auto"}},[_c('div',{staticClass:"bi-text-primary-default bi-text-lg bi-font-semibold bi-pl-4 bi-mt-4"},[_vm._v("Partner Details")]),_c('sp-list',{ref:"partnersList",staticClass:"clickable bi-p-4",attrs:{"data":_vm.partnerList,"attrs":_vm.columns,"actions":['']},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('NoPartnerFound')]},proxy:true}],null,false,1141617604)},[_c('sp-list-table',{on:{"rowClick":_vm.setPartnerDetails},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"bi-text-md bi-text-primary-default bi-font-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"mobileNo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"bi-text-md"},[_vm._v(_vm._s(item.mobileNo))])]}},{key:"isDefault",fn:function(ref){
var item = ref.item;
return [_c('s-switch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Select as Main Partner'),expression:"'Select as Main Partner'"}],key:("primary-partner-switch-" + (item.id)),attrs:{"id":("primary-partner-switch-" + (item.id)),"color":"primary","size":"xs","on-value":true,"off-value":false,"value":item.isDefault,"shape":"pill"},nativeOn:{"click":function($event){return _vm.toggleMainPartners(item)}}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"bi-text-center bi-flex bi-items-center bi-gap-2"},[_c('s-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove Partner'),expression:"'Remove Partner'"}],attrs:{"icon":"IconMdiTrashCanOutline","shape":"circle","color":"danger","theme":"muted"},nativeOn:{"click":function($event){return _vm.removePartner(item)}}}),_c('s-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Edit Partner'),expression:"'Edit Partner'"}],attrs:{"icon":"IconIcSharpEdit","shape":"circle","color":"secondary","theme":"muted"},nativeOn:{"click":function($event){return _vm.setPartnerDetails(item)}}})],1)]}}],null,false,1137900165)})],1)],1):_c('FormShimmer',{staticClass:"bi-w-100"}),_c('div',{staticClass:"bi-flex bi-items-center bi-mt-4 bi-pl-4"},[_c('s-button',{attrs:{"size":"lg","theme":"outline","label":"Previous"},nativeOn:{"click":function($event){return _vm.goToUpsertDocumentPage.apply(null, arguments)}}}),_c('s-button',{staticClass:"bi-ml-6",attrs:{"label":"Submit KYC","color":"secondary","size":"lg","align":"center","loader":_vm.isSubmitting},nativeOn:{"click":function($event){return _vm.onSubmit.apply(null, arguments)}}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }